import Navbar from "../layout/Navbar";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const intervalID = setTimeout(() =>  {
            navigate('/dashboard');
        }, 3000);
    
        return () => clearInterval(intervalID);
    }, [navigate]);
    return (
        <>
            <div className="application overflow-x-hidden">
                <Navbar color="white"/>

                <div className="grid h-screen place-items-center">
                    <div className="w-80 md:w-auto bg-blue-cst-box-bg bg-opacity-100 rounded-xl">
                        <div className="flex flex-col gap-6 items-center p-24">
                            <img src={process.env.PUBLIC_URL + '/images/components/register/success_verif_cat.svg'} alt="brand" className="logo-desktop w-44"></img>
                            <h3 className="w-72 md:w-auto font-bold text-center">
                            Thank you for verifying your identity!
                            </h3>
                            <h6 className="w-72 md:w-auto text-md text-slate-400 text-center">
                            We are currently reviewing your verification and will provide confirmation shortly. We are committed to ensuring that your verification is carefully reviewed and with guaranteed security.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;