import Navbar from "../../layout/NavbarAuth";
import Sidebar from "../../layout/Sidebar";
import TransferSuccessContainer from "../../layout/container/transfer/TransferSuccessContainer"

const TransferSuccess = () => {
    return (
        <>
            <div className="application overflow-x-hidden">
                <Navbar color="white"/>
                <Sidebar />

                <TransferSuccessContainer />
            </div>
        </>
    );
}

export default TransferSuccess;