import Navbar from "../../layout/NavbarAuth";
import Sidebar from "../../layout/Sidebar";
import TransferConfirmationContainer from "../../layout/container/transfer/TransferConfirmationContainer"

const TransferConfirmation = () => {
    return (
        <>
            <div className="application overflow-x-hidden">
                <Navbar color="white"/>
                <Sidebar />

                <TransferConfirmationContainer />
            </div>
        </>
    );
}

export default TransferConfirmation;