import Navbar from "../../layout/NavbarAuth";
import Sidebar from "../../layout/Sidebar";
import ProfileContainer from "../../layout/container/profile/ProfileContainer"

const Profile = () => {
    return (
        <>
            <div className="application overflow-x-hidden">
                <Navbar color="white"/>
                <Sidebar />

                <ProfileContainer />
            </div>
        </>
    );
}

export default Profile;