import { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Navbar from "../../layout/Navbar";

const Home = () => {
    const [shouldUseImage, setShouldUseImage] = useState(false);

    const dispatch = useDispatch();
    const videoParentRef = useRef();

    dispatch({ type: 'START_PAGE', newisStartPage: false });

    const mainVideo = process.env.PUBLIC_URL + '/images/components/welcome/welcome_cat.mp4';

    const isSafari = () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
    };

    useEffect(() => {
        if (isSafari() && videoParentRef.current) {
            const player = videoParentRef.current.children[0];

            if (player) {
                player.controls = false;
                player.playsinline = true;
                player.muted = true;
                player.setAttribute("muted", "");
                player.autoplay = true;

                setTimeout(() => {
                    const promise = player.play();
                    if (promise.then) {
                        promise
                            .then(() => { })
                            .catch(() => {
                                videoParentRef.current.style.display = "none";
                                setShouldUseImage(true);
                            });
                    }
                }, 0);
            }
        }
    }, []);

    return (
        <>
            <div className="application overflow-x-hidden">
                <Navbar color="white" />

                <div className="grid h-screen place-items-center">
                    <div className="flex flex-col gap-6 items-center">
                        {/* <img src={process.env.PUBLIC_URL + '/images/components/welcome/welcome_cat.gif'} alt="brand" className="logo-desktop max-sm:w-32 sm:w-44 md:w-56"></img> */}
                        {shouldUseImage ? (
                            null
                        ) : (
                            <div
                                className="max-sm:w-32 sm:w-44 md:w-56"
                                ref={videoParentRef}
                                dangerouslySetInnerHTML=
                                {{__html: 
                                    `<video loop muted autoPlay playsInline preload="metadata">
                                        <source src="${mainVideo}" type="video/mp4" />
                                    </video>`,
                                }}
                            />
                        )}
                        <h4 className="font-bold md:text-3xl">
                            Welcome to CST Wallet
                        </h4>
                        <h6 className="max-sm:text-md md:text-lg text-slate-400">
                            Connecting you to CST environment
                        </h6>
                        <a href="/login">
                            <button className="h-12 max-sm:w-60 sm:w-88 md:w-96 max-sm:text-md text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover">
                                Getting Started
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;