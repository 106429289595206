import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux';

import Router from './router/Router';

document.title = 'CST Wallet';

const globalState = {
	stepDeposit: 1,
	isStartPage: false
}

const rootReducer = (state = globalState, action) => {
	switch(action.type){
		case 'STEP_DEPOSIT':
			return{
				...state,
				stepDeposit : action.newstepDeposit
			}
		case 'START_PAGE':
			return{
				...state,
				isStartPage : action.newisStartPage
			}
		case 'DASHBOARD_ACTIVE':
			return{
				...state,
				isDashboardActivePage : action.newisDashboardActivePage
			}
		case 'TUHISTORY_ACTIVE':
			return{
				...state,
				isTuhistoryActivePage : action.newisTuhistoryActivePage
			}
		case 'TFHISTORY_ACTIVE':
			return{
				...state,
				isTfhistoryActivePage : action.newisTfhistoryActivePage
			}
		case 'TRHISTORY_ACTIVE':
			return{
				...state,
				isTrHistoryActivePage : action.newisTrhistoryActivePage
			}
		default : 
			return state;
	}
}

const store = createStore(rootReducer);

const App = () => {
	return (
		<>
			<Provider store={store}>
				<Router />
			</Provider>
		</>
	)
}

export default App;