import Navbar from "../layout/Navbar";

const Home = () => {
    return (
        <>
            <div className="application overflow-x-hidden">
                <Navbar color="white"/>

                <div className="grid h-screen place-items-center">
                    <div className="w-80 md:w-auto bg-blue-cst-box-bg bg-opacity-100 rounded-xl">
                        <div className="flex flex-col gap-6 items-center p-24">
                            <img src={process.env.PUBLIC_URL + '/images/components/success/confirm-new-password/success_cat.png'} alt="success" className="w-44"></img>
                            <h3 className="w-72 md:w-auto font-bold text-center">
                                Congratulations! Your account has been successfully created.
                            </h3>
                            <h6 className="w-72 md:w-96 text-md text-slate-400 text-center">
                                You can now access all of the features and services available to you. Thank you for joining our community!
                            </h6>
                            <a href="/login">
                                <button className="h-12 w-72 md:w-96 text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover">
                                    Go To Login
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;