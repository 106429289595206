import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";

import cryptoJS from "crypto-js";
import axios from "axios";

import Navbar from "../layout/Navbar";

const Login = () => {
    const [Tableusername, setTableusername] = useState(null);
    const [Tablepassword, setTablepassword] = useState(null);

    const [eye, setEye] = useState(false);

    const [errorUsername, setErrorUsername] = useState('');
    const [errorusernameMessage, setErrorusernamemessage] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [errorPasswordmessage, setErrorpasswordmessage] = useState('');
    const [Message, setMessage] = useState('');

    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    dispatch({type: 'START_PAGE', newisStartPage: true});

    const eyeHandle = () => {
        if (eye) {
            setEye(false);
        } else {
            setEye(true);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        let StringSignature = '';

        StringSignature += Tableusername;
        StringSignature += Tablepassword;
        StringSignature += 0;
        StringSignature += 0;
        StringSignature += 'EN';

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/auth/v01/signin',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                signature: signature
            },
            data: {
                tableusername: Tableusername,
                tableuserpassword: Tablepassword,
                latitude: 0,
                longitude: 0,
                tableuserlanguage: 'EN'
            }
        }).then(res => {
            if (res.data.status.status === 0) {

                res.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 9)) {
                        case '888005015':
                            setErrorUsername(element.code);
                            setErrorusernamemessage(element.errormassage);
                            break;
                        case '888005115':
                            setErrorPassword(element.code);
                            setErrorpasswordmessage(element.errormassage);
                            break;
                        default:
                            setMessage(element.errormassage);

                            setShowModal(true);
                            break;
                    }
                });

                let inputUsername = res.data.status.message.filter(key => key.code.substring(0, 9) === '888005015');
                let inputPassword = res.data.status.message.filter(key => key.code.substring(0, 9) === '888005115');

                if (inputUsername.length === 0) { setErrorUsername(''); }
                if (inputPassword.length === 0) { setErrorPassword(''); }
            } else {
                localStorage.setItem('useruuid', JSON.stringify(res.data.data));
                navigate('/dashboard');
            }
        }).catch(error => {
            console.log(error);
        })
    }

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                    onClick={() => setShowModal(false)}
                                >
                                    <span className="text-gray-400 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                                <div className="flex flex-col p-24 gap-8 items-center">
                                    <img src={process.env.PUBLIC_URL + '/images/components/error/error_cat.png'} alt="error" className=""></img>
                                    <h3 className="uppercase font-bold">{Message}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            <div className="application overflow-x-hidden">
                <Navbar color="white"/>

                <div className="grid h-screen place-items-center">
                    <div className="flex flex-col gap-6 items-center">
                        <h1 className="max-sm:text-3xl md:text-4xl text-blue-cst-font">
                            Login
                        </h1>
                        {/* <h6 className="text-blue-cst-font">
                            Lets login fellas
                        </h6> */}
                        <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                            <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Username" aria-label="Username" onChange={(e) => setTableusername(e.target.value)} />
                        </div>
                        {errorUsername.substring(0, 9) === '888005015' ?
                            <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {errorusernameMessage}
                            </span> :
                            null}
                        <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                            <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type={eye ? "text" : "password"} placeholder="Password" aria-label="Password" onChange={(e) => setTablepassword(e.target.value)} />
                            <span className="cursor-pointer" onClick={eyeHandle}>
                                {eye ?
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.5002 10C7.5002 11.3807 8.61949 12.5 10.0002 12.5C11.3809 12.5 12.5002 11.3807 12.5002 10C12.5002 8.61929 11.3809 7.50001 10.0002 7.50001C8.61949 7.50001 7.5002 8.61929 7.5002 10Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17.9517 9.99998C16.8898 6.61907 13.7312 4.16667 9.99984 4.16667C6.26846 4.16667 3.10988 6.6191 2.048 10C3.10989 13.3809 6.26846 15.8333 9.99982 15.8333C13.7312 15.8333 16.8898 13.3809 17.9517 9.99998Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    :
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5002 2.5L14.5092 5.49097M2.5002 17.5L5.49087 14.5093M8.43781 15.6872C8.94378 15.7831 9.46594 15.8333 9.99982 15.8333C13.7312 15.8333 16.8898 13.3809 17.9517 9.99997C17.6626 9.07958 17.2181 8.22801 16.649 7.47598M11.768 8.23223C11.3156 7.77982 10.6906 7.5 10.0002 7.5C8.61949 7.5 7.5002 8.61929 7.5002 10C7.5002 10.6904 7.78003 11.3154 8.23244 11.7678M11.768 8.23223L8.23244 11.7678M11.768 8.23223L14.5092 5.49097M8.23244 11.7678L14.5092 5.49097M8.23244 11.7678L5.49087 14.5093M14.5092 5.49097C13.2094 4.65295 11.6614 4.16667 9.99984 4.16667C6.26846 4.16667 3.10988 6.61909 2.048 10C2.63709 11.8756 3.87144 13.4654 5.49087 14.5093" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                }
                            </span>
                        </div>
                        {errorPassword.substring(0, 9) === '888005115' ?
                            <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {errorPasswordmessage}
                            </span> :
                            null}
                        <div className="max-sm:w-60 sm:w-88 md:w-96 inline-flex justify-between">
                            <h6 className="max-sm:text-xs md:text-sm">
                                <a href="forgot/username">
                                    Forgot username?
                                </a>
                            </h6>
                            <h6 className="max-sm:text-xs md:text-sm">
                                <a href="forgot/password">
                                    Forgot password?
                                </a>
                            </h6>
                        </div>
                        <button className="h-12 max-sm:w-60 sm:w-88 md:w-96 text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={onSubmit}>
                            Login
                        </button>
                        <div className="inline-flex gap-3 items-left">
                            <input type={"checkbox"} className="checked:bg-blue-500" /> Remember Me
                        </div>
                        <div className="inline-flex gap-3 items-left">
                            New to CST?<a href="/registration" className="text-blue-600">Register</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;