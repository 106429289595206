import Navbar from "../../layout/NavbarAuth";
import Sidebar from "../../layout/Sidebar";
import TransferContainer from "../../layout/container/transfer/TransferContainer"

const Transfer = () => {
    return (
        <>
            <div className="application overflow-x-hidden">
                <Navbar color="white"/>
                <Sidebar />

                <TransferContainer />
            </div>
        </>
    );
}

export default Transfer;