const TopupSuccessContainer = () => {
    return (
        <div className="overflow-y-hidden w-4/5 h-auto box-content p-2 pl-20 pt-20 md:p-8 md:pl-72 md:pt-24 left-16 md:left-64">
            <div className="flex flex-col w-auto gap-8">
                <div className="w-72 md:w-auto bg-blue-cst-box-bg-dash bg-opacity-100 rounded-xl">
                    <div className="flex flex-col gap-6 items-center p-8 md:p-24">
                        <img src={process.env.PUBLIC_URL + '/images/components/topup/topup_success_cat.png'} alt="success" className="logo-desktop w-44"></img>
                        <h3 className="font-bold text-center">
                            Top-Up is On Progress
                        </h3>
                        <h6 className="text-md text-slate-400 text-center">
                            Please check your email to complete your top-up transaction.
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopupSuccessContainer;