import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import cryptoJS from "crypto-js";
import axios from "axios";

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/auth/v01/logout',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                signature: signature
            },
            data: {
                userindex: useruuid.tableuserindex,
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                localStorage.clear();

                navigate('/login');
            } else {
                console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }, [navigate]);

    return (<div>Loading ...</div>)
}

export default Logout;