import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import cryptoJS from "crypto-js";
import axios from "axios";

const TopupSuccessContainer = () => {
    const [Message, setMessage] = useState('');

    const [showModal, setShowModal] = useState(false);

    const { verificationCode } = useParams();

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/transfer/v01/confirm',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            data: {
                codeverification: verificationCode,
            }
        }).then(res => {
            if (res.data.status.status === 0) {
                res.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 9)) {
                        default:
                            setMessage(element.errormassage);

                            setShowModal(true);
                            break;
                    }
                });
            } else {
                console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }, [verificationCode])

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                    onClick={() => setShowModal(false)}
                                >
                                    <span className="text-gray-400 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                                <div className="flex flex-col p-24 gap-8 items-center">
                                    <img src={process.env.PUBLIC_URL + '/images/components/error/error_cat.png'} alt="error" className=""></img>
                                    <h3 className="uppercase font-bold">{Message}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            <div className="overflow-y-hidden w-4/5 h-auto box-content p-2 pl-20 pt-20 md:p-8 md:pl-72 md:pt-24 left-16 md:left-64">
                <div className="flex flex-col w-auto gap-8">
                    <div className="w-80 md:w-auto bg-blue-cst-box-bg-dash bg-opacity-100 rounded-xl">
                        <div className="flex flex-col gap-6 items-center p-24">
                            <img src={process.env.PUBLIC_URL + '/images/components/transfer/transfer_success_cat.png'} alt="success" className="logo-desktop w-44"></img>
                            <h3 className="font-bold text-center">
                                Transfer Success
                            </h3>
                            <h6 className="text-md text-slate-400 text-center">
                                Your funds have been securely transferred to the specified recipient. You can view the details of this transaction in your account history. Thank you for using our service for your cryptocurrency needs!
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TopupSuccessContainer;