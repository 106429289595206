import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import cryptoJS from "crypto-js";
import axios from "axios";
import { useDispatch } from "react-redux";

import Navbar from "../layout/Navbar";
import { read } from "@popperjs/core";

const AuthVerification = () => {
   
    const dispatch = useDispatch();

    dispatch({ type: 'START_PAGE', newisStartPage: true });

    //Data Set Step 2
    const [Tablefullname, setTablefullname] = useState(null);
    const [TableIdKTP, setTableIdKTP] = useState(null);
    const [TablePOB, setTablePOB] = useState(null);
    const [TableDOB, setTableDOB] = useState(null);
    const [TableGender, setTableGender] = useState(null)

    const [Message, setMessage] = useState('');

    const [errorFullname, setErrorFullname] = useState('');
    const [errorKTP, setErrorKTP] = useState('');
    const [errorPOB, setErrorPOB] = useState('');
    const [errorDOB, setErrorDOB] = useState('');
    const [errorGender, setErrorGender] = useState('');

    //Error Message Step 2
    const [errorfullnameMessage, setErrorfullnamemessage] = useState('');
    const [errorKTPMessage, setErrorKTPMessage] = useState('');
    const [errorPOBMessage, setErrorPOBMessage] = useState('');
    const [errorDOBMessage, setErrorDOBMessage] = useState('');
    const [errorGenderMessage, setErrorGenderMessage] = useState('');

    const [tokenNotExist, setTokenNotExist] = useState(false);

    const [showModal, setShowModal] = useState(false);
    //Data Set Step 3
    const [TableAddress, setTableAddress] = useState(null);
    const [TableSubDistrict, setTableSubDistrict] = useState(null);
    const [TableDistrict, setTableDistrict] = useState(null);
    const [TableCity, setTableCity] = useState(null);
    const [TableProvince, setTableProvince] = useState(null)
    const [TableState, setTableState] = useState(null)
    const [TablePostalCode, setPostalCode] = useState(null)

    const [errorAddress, setErrorAddress] = useState('');
    const [errorSubDistrict, setErrorSubDistrict] = useState('');
    const [errorDistrict, setErrorDistrict] = useState('');
    const [errorCity, setErrorCity] = useState('');
    const [errorProvince, setErrorProvince] = useState('');
    const [errorState, setErrorState] = useState('');
    const [errorPostalCode, setErrorPostalCode] = useState('');

    const [errorAddressMessage, setErrorAddressMessage] = useState('');
    const [errorSubDistrictMessage, seterrorSubDistrictMessage] = useState('');
    const [errorDistrictMessage, setErrorDistrictMessage] = useState('');
    const [errorCityMessage, setErrorCityMessage] = useState('');
    const [errorProvinceMessage, setErrorProvinceMessage] = useState('');
    const [errorStateMessage, setErrorStateMessage] = useState('');
    const [errorPostalCodeMessage, setErrorPostalCodeMessage] = useState('');
    
    const [previewIDCardImage, setPreviewIDCardlImage] = useState(null);
    const [previewSelfPhotoImage, setPreviewSelfPhotoImage] = useState(null);

    const [fileGambarKTP, setFileGambarKTP] = useState({});
    const [fileGambarFotoUser, setFileGambarFotoUser] = useState({});
    
    const [onLoading, setOnLoading] = useState(false);
    const goBack = () =>{
        navigate('/dashboard')
    }
    //forNavigate
    const [goStep2, setGoStep2] = useState(true)
    const [goStep3, setGoStep3] = useState(false)
    const [goStep4, setGoStep4] = useState(false)

    const navigate = useNavigate();
    // const dispatch = useDispatch();

    // dispatch({ type: 'START_PAGE', newisStartPage: true });

    const { id  } = useParams();
    const handleIDImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        setFileGambarKTP(file);
        if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPreviewIDCardlImage(reader.result);
            };    
        }
           
    };
    const handleSelfPhotoImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        setFileGambarFotoUser(file);
         if(file){
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPreviewSelfPhotoImage(reader.result);
            };    
        }

        
    }
    const OnSubmitStep2 = (e) => {
        e.preventDefault();
        let StringSignature = '';

        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += TableSubDistrict;
        StringSignature += TableDistrict;
        StringSignature += TableCity;
        StringSignature += TableProvince;
        StringSignature += TableAddress;
        StringSignature += TableState;
        StringSignature += TablePostalCode;
        StringSignature += id;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/user/v01/kyc/store02',
            
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                signature: signature,
                userindex:useruuid.tableuserindex,
                tokenlogin:useruuid.tokenlogin
            },
            data: {
                tokentrans: id,
                subdistrict: TableSubDistrict,
                district: TableDistrict,
                city: TableCity,
                province: TableProvince,
                address: TableAddress,
                state: TableState,
                postalcode: TablePostalCode

            }
        }).then(res => {
            if (res.data.status.status === 0) {

                res.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 12)) {
                        case "888005017005":
                            setErrorSubDistrict(element.code);
                            seterrorSubDistrictMessage(element.errormassage);
                            break;
                        case "888005017030":
                            setErrorSubDistrict(element.code);
                            seterrorSubDistrictMessage(element.errormassage);
                            break;
                        case "888005018005":
                            setErrorDistrict(element.code);
                            setErrorDistrictMessage(element.errormassage);
                            break;
                        case "888005018030":
                            setErrorDistrict(element.code);
                            setErrorDistrictMessage(element.errormassage);
                            break;
                        case "888005027030":
                            setErrorCity(element.code);
                            setErrorCityMessage(element.errormassage);
                            break;
                        case "888005027005":
                            setErrorCity(element.code);
                            setErrorCityMessage(element.errormassage);
                            break;
                        case "888005019030":
                            setErrorProvince(element.code);
                            setErrorProvinceMessage(element.errormassage);
                            break;
                        case "888005021005":
                            setErrorAddress(element.code);
                            setErrorAddressMessage(element.errormassage);
                            break;
                        case "888005021030":
                            setErrorAddress(element.code);
                            setErrorAddressMessage(element.errormassage);
                            break;
                        case "888005022005":
                            setErrorPostalCode(element.code);
                            setErrorPostalCodeMessage(element.errormassage);
                            break;
                        case "888005022030":
                            setErrorPostalCode(element.code);
                            setErrorPostalCodeMessage(element.errormassage);
                            break;
                        case "888005041030":
                            setErrorState(element.code);
                            setErrorStateMessage(element.errormassage);
                            break;
                        case "888999999925":
                            setMessage(element.errormassage);
                            setTokenNotExist(true);
                            setShowModal(true);
                            break;
                        default:
                            setMessage(element.errormassage);
                            setShowModal(true);
                            break;
                    }
                });

                let inputSubdistrict = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005017005');
                let inputSubdistrict2 = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005017030');

                let inputDistrict = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005018005');
                let inputDistrict2 = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005018030');

                let inputCity = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005027030');
                let inputCity2 = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005027005');

                let inputProvince = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005019030');

                let inputAddress = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005021005');
                let inputAddress2 = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005021030');

                let inputPostalCode = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005022005');
                let inputPostalCode2 = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005022030');

                let inputState = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005041030');

                if (inputSubdistrict.length === 0 && inputSubdistrict2.length === 0) { setErrorSubDistrict(''); }
                if (inputDistrict.length === 0 && inputDistrict2.length === 0) { setErrorDistrict(''); }
                if (inputCity.length === 0 && inputCity2.length === 0) { setErrorCity(''); }
                if (inputProvince.length === 0) { setErrorProvince(''); }
                if (inputAddress.length === 0 && inputAddress2.length === 0) { setErrorAddress(''); }
                if (inputPostalCode.length === 0 && inputPostalCode2.length === 0) { setErrorPostalCode(''); }
                if (inputState.length === 0) { setErrorState(''); }
            }
            if (typeof (res.data.data) !== 'undefined') {
                if (res.data.data.message === "success") {
                    registerStep3();
                }
            }


        }).catch(error => {
            console.log(error);
        })
    }
    const OnSubmitStep1 = (e) => {
        e.preventDefault();
        let StringSignature = '';
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += TableIdKTP;
        StringSignature += Tablefullname;
        StringSignature += TableGender;
        StringSignature += TablePOB;
        StringSignature += TableDOB;
        StringSignature += id;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);
        
        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/user/v01/kyc/store01',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                signature: signature,
                userindex:useruuid.tableuserindex,
                tokenlogin:useruuid.tokenlogin
            },
            data: {
                tokentrans: id,
                tableuserfullname: Tablefullname,
                idnumber: TableIdKTP,
                gender: TableGender,
                birthplace: TablePOB,
                birthdate: TableDOB

            }
        }).then(res => {
                // console.log('OnSubmitStep1', res)
            if (res.data.status.status === 0) {
                res.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 12)) {
                        case "888005020015":
                            setErrorFullname(element.code);
                            setErrorfullnamemessage(element.errormassage);
                            break;
                        case "888005020010":
                            setErrorFullname(element.code);
                            setErrorfullnamemessage(element.errormassage);
                            break;
                        case "888005016030":
                            setErrorKTP(element.code);
                            setErrorKTPMessage(element.errormassage);
                            break;
                        case "888005023005":
                            setErrorGender(element.code);
                            setErrorGenderMessage(element.errormassage);
                            break;
                        case "888005024030":
                            setErrorPOB(element.code);
                            setErrorPOBMessage(element.errormassage);
                            break;
                        case "888005026030":
                            setErrorDOB(element.code);
                            setErrorDOBMessage(element.errormassage);
                            break;
                        case "888999999925":
                            setMessage(element.errormassage);
                            setShowModal(true);
                            setTokenNotExist(true);
                                break;
                        default:
                            setMessage(element.errormassage);
                            setShowModal(true);
                            break;
                    }
                });

                let inputFullname = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005020010');
                let inputFullname2 = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005020015');
                let inputIdKTP = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005016030');
                let inputPOB = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005024030');
                let inputDOB = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005026030');
                let inputGender = res.data.status.message.filter(key => key.code.substring(0, 12) === '888005023005');
       
                if ((inputFullname.length || inputFullname2.length)=== 0) { setErrorFullname(''); }
                if (inputIdKTP.length === 0) { setErrorKTP(''); }
                if (inputPOB.length === 0) { setErrorPOB(''); }
                if (inputDOB.length === 0) { setErrorDOB(''); }
                if (inputGender.length === 0) { setErrorGender(''); }
            }
            if (typeof (res.data.data) !== 'undefined') {
                if (res.data.data.message === "success") {
                    registerStep2();
                }
            }
        }).catch(error => {
            console.log(error);
        })
    }
    const OnSubmitStep3 = async (e) => {
        e.preventDefault()
        setOnLoading(true);
        let StringSignature = '';
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += fileGambarKTP;
        StringSignature += fileGambarFotoUser;
        StringSignature += id;

        let formData = new FormData();
        formData.append("tokentrans", id)
        formData.append("image_ktp", fileGambarKTP)
        formData.append("image_foto", fileGambarFotoUser)

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        let dataHeaders = {
            'platform': 'WEBSITE',
            'gtoken': 'PASSS',
            'signature': signature,
            'userindex':useruuid.tableuserindex,
            'tokenlogin':useruuid.tokenlogin
        }
         try {
            let response = await axios.post(process.env.REACT_APP_APIHOST + '/api/user/v01/kyc/store03', formData, {
                headers: dataHeaders,
    
            });

            if (response.data.status.status === 0) {
                setOnLoading(false);
                response.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 12)) {
                        case "888005031005":
                            setMessage(element.errormassage);
                            setShowModal(true);
                            break;
                        case "888999999925":
                            setMessage(element.errormassage);
                            setTokenNotExist(true);
                            setShowModal(true);
                            break;
                        default:
                            setMessage(element.errormassage);
                            setShowModal(true);
                            break;
                    }
                });
            }
            if (typeof (response.data.data) !== 'undefined') {
                if (response.data.data.message === "success") {
                    OnSubmitStep4();
                }
            }
         } catch (error) {
            setOnLoading(false);
            console.log(error)
         }     
    }
    const OnSubmitStep4 = async () =>{

        let StringSignature = '';
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));
        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += id;

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/user/v01/kyc/store04',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                signature: signature,
                userindex:useruuid.tableuserindex,
                tokenlogin:useruuid.tokenlogin
            },
            data: {
                tokentrans: id,
            }
        }).then(res => {
            // console.log('OnSubmitStep4', res)
                if (res.data.status.status === 0) {
                    setOnLoading(false);
                    res.data.status.message.forEach(element => {
                        switch (element.code.substring(0, 12)) {
                            case "888999999925":
                                setMessage(element.errormassage);
                                setTokenNotExist(true);
                                setShowModal(true);
                                break;
                            default:
                                setMessage(element.errormassage);
                                setShowModal(true);
                                break;
                        }
                    });
                    if (typeof (res.data.data) !== 'undefined') {
                        if (res.data.data.message === "success") {
                            setOnLoading(false);
                            navigate("/authVerification/success");
                        }
                    }
            }
        }).catch(error =>{ 
            setOnLoading(false);
            console.log(error)})
    }

    const registerStep2 = () => {
        setGoStep3(true);
        setGoStep2(false);
    }
    const registerStep3 = () => {
        setGoStep4(true);
        setGoStep3(false);
    }
    const goBackToStep3 = () => {
        setGoStep4(false);
        setGoStep3(true)
    }
    const goBackToStep2 = () => {
        setGoStep3(false);
        setGoStep2(true)
    }
    return (
        <>
          {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col items-center w-full bg-white outline-none focus:outline-none">
                                <div className="flex flex-col p-24 gap-8 items-center">
                                    <img src={process.env.PUBLIC_URL + '/images/components/error/error_cat.png'} alt="error" className=""></img>
                                    <h3 className="text-center uppercase font-bold">{Message}</h3>
                                </div>
                                <button
                                    className="h-12 max-sm:w-60 sm:w-88 mb-10 md:w-96 text-lg text-indigo-100 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover"
                                    onClick={() => tokenNotExist? navigate('/dashboard') : setShowModal(false) }
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        
            <div className="application overflow-x-hidden ">
                <Navbar color="white" />

                <div className="grid h-screen place-items-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/components/register/background.svg'})` }}>
                    <div className="flex flex-col gap-4 items-center overflow-y-auto rounded-3xl bg-white px-20 py-10">
                        <h2 className="max-sm:mt-10 md:mt-0 text-blue-cst-font">
                            {"Identity Verification"}
                        </h2>
                        <div className="flex flex-col  w-96">
                        <span className="text-blue-cst-font text-center">
                            {"Complete your KYC verification now and unlock the full potential of our services"}
                        </span>
                        </div>
                        <div className={"flex flex-row justify-between w-[100%] px-4"}>
                            <div className="w-[30%] bg-gray-200 h-2.5 rounded-full">
                                <div className={(goStep2 || goStep3 || goStep4) ? "bg-blue-600 h-2.5  w-[100%] rounded-full" : null} ></div>
                            </div>
                            <div className="w-[30%] bg-gray-200 h-2.5 rounded-full">
                                <div className={(goStep3 || goStep4) ? "bg-blue-600 h-2.5  w-[100%] rounded-full" : null}></div>
                            </div>
                            <div className="w-[30%] bg-gray-200 h-2.5 rounded-full">
                                <div className={(goStep4) ? "bg-blue-600 h-2.5  w-[100%] rounded-full" : null}></div>
                            </div>
                        </div>

                        <div className={goStep2 ? "flex flex-col gap-4 items-center mb-10 px-3" : "hidden"}>
                            <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Full Name (according to ID)" aria-label="FullName" onChange={(e) => setTablefullname(e.target.value)} />
                            </div>
                            {(errorFullname.substring(0, 12) === "888005020015" || errorFullname.substring(0, 12) === "888005020010") ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorfullnameMessage}
                                </span> :
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-blue-cst-font-info inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#3267E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    Min 5 character and without space.
                                </span>}
                            <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                <input maxLength={"16"} className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="ID Number (according to ID)" aria-label="IDNumber" onChange={(e) => setTableIdKTP(e.target.value)} />
                            </div>
                            {errorKTP.substring(0, 12) === "888005016030" ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorKTPMessage}
                                </span> :
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-blue-cst-font-info inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#3267E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    Require 16 digit number.
                                </span>}
                            <div className="max-sm:w-60 sm:w-88 md:w-96 flex align-baseline flex-col justify-items-start   py-2">
                                <label className="mb-2">Gender</label>
                                <div className="flex flex-row">
                                    <div className="flex flex-row  ">
                                        <input
                                            id="male"
                                            name="gender"
                                            className="w-4 h-4 self-center"
                                            type="radio"
                                            placeholder="Gender"
                                            aria-label="Gender"
                                            onChange={(e) => setTableGender("MALE")} />
                                        <label className="pl-2">Male</label>
                                    </div>
                                    <div className="px-[10%] flex flex-row ">
                                        <input
                                            id="female"
                                            name="gender"
                                            className="w-4 h-4 self-center"
                                            type="radio"
                                            placeholder="Gender"
                                            aria-label="Gender"
                                            onChange={(e) => setTableGender("FEMALE")} />
                                        <label className="pl-2">Female</label>
                                    </div>
                                </div>
                            </div>
                            {errorGender.substring(0, 12) === "888005023005" ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorGenderMessage}
                                </span> :
                                null}
                            <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Place of Birth" aria-label="PlaceOfBirth" onChange={(e) => setTablePOB(e.target.value)} />
                            </div>
                            {errorPOB.substring(0, 12) === "888005024030" ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorPOBMessage}
                                </span> :
                                null}
                            <div>
                                <label className="align-baseline">Birth Date</label>
                                <div className="max-sm:w-60 sm:w-88 md:w-96 flex flex-row items-center border-b border-blue-cst-border py-2">
                                    <input className="appearance-none pointer-events-auto border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="date" placeholder="Birth Date" aria-label="BirthDate" onChange={(e) => setTableDOB(e.target.value)} />
                                </div>
                            </div>

                            {errorDOB.substring(0, 12) === "888005026030" ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorDOBMessage}
                                </span> :
                                null}

                        </div>

                        <div className={goStep3 ? "flex flex-col gap-4 items-center mb-10  h-[100%] overflow-y-scroll px-2" : "hidden"}>
                            <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Address (according to ID)" aria-label="Address" onChange={(e) => setTableAddress(e.target.value)} />
                            </div>
                            {errorAddress.substring(0, 12) === '888005021005' ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorAddressMessage}
                                </span> : null}
                            {errorAddress.substring(0, 12) === '888005021030' ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorAddressMessage}
                                </span> : null}
                            <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Subdistrict (according to ID)" aria-label="Subdistrict" onChange={(e) => setTableSubDistrict(e.target.value)} />
                            </div>
                            {errorSubDistrict.substring(0, 12) === '888005017005' ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorSubDistrictMessage}
                                </span> : null}
                            {errorSubDistrict.substring(0, 12) === '888005017030' ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorSubDistrictMessage}
                                </span> : null}
                            <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="District (according to ID)" aria-label="District" onChange={(e) => setTableDistrict(e.target.value)} />
                            </div>
                            {errorDistrict.substring(0, 12) === "888005018005" ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorDistrictMessage}
                                </span> : null}
                            {errorDistrict.substring(0, 12) === "888005018030" ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorDistrictMessage}
                                </span> : null}

                            <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="City (according to ID)" aria-label="City" onChange={(e) => setTableCity(e.target.value)} />
                            </div>
                            {errorCity.substring(0, 12) === '888005027030' ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorCityMessage}
                                </span> : null}

                            <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Province (according to ID)" aria-label="Province" onChange={(e) => setTableProvince(e.target.value)} />
                            </div>
                            {errorProvince.substring(0, 12) === '888005019030' ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorProvinceMessage}
                                </span> : null}

                            <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="State (according to ID)" aria-label="State" onChange={(e) => setTableState(e.target.value)} />
                            </div>
                            {errorState.substring(0, 12) === '888005041030' ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorStateMessage}
                                </span> : null}

                            <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Postal Code (according to ID)" aria-label="PostalCode" onChange={(e) => setPostalCode(e.target.value)} />
                            </div>
                            {errorPostalCode.substring(0, 12) === '888005022005' ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorPostalCodeMessage}
                                </span> : null}
                            {errorPostalCode.substring(0, 12) === '888005022030' ?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {errorPostalCodeMessage}
                                </span> : null}
                        </div>

                        <div className={goStep4 ? "flex flex-col gap-4 items-center mb-10 px-3 overflow-y-hidden" : "hidden"}>
                            <div className="mb-8">
                                <div className="flex flex-col max-sm:w-60 sm:w-88 md:w-96  py-2 ">
                                    <label htmlFor="title" className="text-[#5D5F61] mb-3 block flex-auto">Upload ID</label>
                                    <div className="flex-initial w-full">
                                        <div className="relative h-[135px] border-2 border-dashed rounded-3xl border-[#5D5F61]  flex justify-center items-start">
                                            <input type="file" accept="image/png, image/jpeg" name="imageUpload" onChange={handleIDImage} style={{ display: "none" }} />
                                            <div className="w-[100%] h-[100%] cursor-pointer" onClick={() =>!onLoading? document.querySelector('input[name="imageUpload"]').click(): null}>
                                                {previewIDCardImage ? (
                                                    <img src={previewIDCardImage} alt="Preview" className="w-[100%] object-contain h-[100%] rounded-3xl" />
                                                ) : (
                                                    <p className="text-center w-full leading-[135px]">
                                                        Upload image file
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-blue-cst-font-info inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#3267E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    ID Card is required
                                </span>
                                <div className="flex flex-col max-sm:w-60 sm:w-88 md:w-96  py-2 ">
                                    <label htmlFor="title" className="mb-3 text-[#5D5F61]  block flex-auto">Upload Photo</label>
                                    <div className="flex-initial w-full">
                                        <div className="relative h-[135px] border-2 border-[#5D5F61]  rounded-3xl border-dashed flex justify-center items-start">
                                            <input type="file" accept="image/png, image/jpeg" name="imageUserUpload" onChange={handleSelfPhotoImage} style={{ display: "none" }} />
                                            <div className="w-[100%] h-[100%] cursor-pointer" onClick={() => !onLoading? document.querySelector('input[name="imageUserUpload"]').click(): null}>
                                                {previewSelfPhotoImage ? (
                                                    <img src={previewSelfPhotoImage} alt="Preview" className="w-full object-contain h-full rounded-3xl" />
                                                ) : (
                                                    <p className="text-center w-full leading-[135px]">
                                                        Upload image file
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-blue-cst-font-info inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#3267E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    User photo is required
                                </span>
                            </div> 
                            {/* {
                                errorUploadPhoto?
                                <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    Your network connection failed to connect to our server, please try again
                                </span>
                                : null
                                } */}
                        </div>
                        {
                            goStep2 ?
                                <div className="flex flex-row justify-between w-[100%]">

                                    <button className="h-12 w-[48%] text-lg text-blue-cst bg-[#F5F5FF] rounded-lg border-[#D7DCDF] focus:shadow-outline hover:bg-blue-cst-hover" onClick={goBack} >
                                        Back
                                    </button>
                                    <button className="h-12 w-[48%] text-lg text-indigo-100 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={OnSubmitStep1} >
                                        Next
                                    </button>
                                </div>
                                : null
                        }
                        {
                            goStep3 ?
                                <div className="flex flex-row justify-between w-[100%]">

                                    <button className="h-12 w-[48%] text-lg text-blue-cst bg-[#F5F5FF] rounded-lg border-[#D7DCDF] focus:shadow-outline hover:bg-blue-cst-hover" onClick={goBackToStep2}>
                                        Back
                                    </button>
                                    <button className="h-12 w-[48%] text-lg text-indigo-100 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={OnSubmitStep2} >
                                        Next
                                    </button>
                                </div>
                                : null
                        }
                        {
                            goStep4 ?
                                <div className="flex flex-row justify-between w-[100%]">

                                    <button className="h-12 w-[48%] text-lg text-blue-cst bg-[#F5F5FF] rounded-lg border-[#D7DCDF] focus:shadow-outline hover:bg-blue-cst-hover" onClick={!onLoading? goBackToStep3 : null}>
                                        Back
                                    </button>
                                    <button className="h-12 w-[48%] text-lg text-indigo-100 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={!onLoading? OnSubmitStep3 : null} >
                                        {onLoading ? "Waiting..." : "Submit"}
                                    </button>
                                </div>
                                : null
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default AuthVerification;