import { useEffect, useState, createRef } from "react";
import { createPopper } from "@popperjs/core";

import cryptoJS from "crypto-js";
import axios from "axios";

const NavbarAuth = ({ color }) => {
    const [notifList, setNotiflist] = useState([]);

    let useruuid = JSON.parse(localStorage.getItem("useruuid"));

    const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
    const [dropdownPopoverShow2, setDropdownPopoverShow2] = useState(false);
    const btnDropdownRef = createRef();
    const btnDropdownRef2 = createRef();
    const popoverDropdownRef = createRef();
    const popoverDropdownRef2 = createRef();

    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "bottom-start"
        });
        setDropdownPopoverShow(true);
        setDropdownPopoverShow2(false);
    };

    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };

    const openDropdownPopover2 = () => {

        if(window.innerWidth < 640){
            createPopper(btnDropdownRef2.current, popoverDropdownRef2.current, {
                placement: "bottom-start"
            });
        }else{
            createPopper(btnDropdownRef2.current, popoverDropdownRef2.current, {
                placement: "bottom-end"
            });
        }

        setDropdownPopoverShow2(true);
        setDropdownPopoverShow(false);
};

    const closeDropdownPopover2 = () => {
        setDropdownPopoverShow2(false);
    };

    // let bgColor;
    // color === "white"
    //     ? (bgColor = "bg-slate-700")
    //     : (bgColor = "bg-" + color + "-500");

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'GET',
            url: process.env.REACT_APP_APIHOST + '/api/activity/v01/lists',
            headers: {
                gtoken: 'PASSS',
                signature: signature,
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin
            },
        }).then(res => {
            if (res.data.status.status === 1) {
                setNotiflist(res.data.data);

            } else {
                console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }, []);

    let resultnotifList = notifList.map((result, key) => {
        let dateString = new Date(result.datetime).toLocaleDateString('en-US');
        let timeString = new Date(result.datetime).toLocaleTimeString('en-US');

        let datetimeString = dateString + ' ' + timeString;

        return (
            <div className="flex flex-col mb-4" key={key}>
                <div className="inline-flex justify-between">
                    <div className="inline-flex justify-start gap-4">
                        <img src={process.env.PUBLIC_URL + '/images/components/navbar/bell.png'} alt="icon" className="w-10 h-10"></img>

                        <div className="flex flex-col">
                            <h5>{result.action}</h5>
                            <h6 className="text-gray-400">{datetimeString}</h6>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <>
            <nav className="fixed bg-white px-2 sm:px-4 py-2.5 dark:bg-gray-900 w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
                <div className="container flex flex-row items-center justify-end gap-4 md:gap-5">
                    <svg width="24" height="24" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" onClick={() => { dropdownPopoverShow2 ? closeDropdownPopover2() : openDropdownPopover2(); }} ref={btnDropdownRef2}>
                        <path d="M12.5 29.5C14.2259 29.5 15.625 28.1009 15.625 26.375H9.375C9.375 28.1009 10.7741 29.5 12.5 29.5Z" fill="#212529" />
                        <path d="M14.0549 6.21724C14.0599 6.16634 14.0625 6.11472 14.0625 6.0625C14.0625 5.19956 13.3629 4.5 12.5 4.5C11.6371 4.5 10.9375 5.19956 10.9375 6.0625C10.9375 6.11472 10.9401 6.16635 10.9451 6.21725C7.37531 6.9382 4.68753 10.0927 4.68753 13.875C4.68753 15.59 3.90625 23.25 1.5625 24.8125H23.4375C21.0938 23.25 20.3125 15.59 20.3125 13.875C20.3125 10.0927 17.6247 6.93816 14.0549 6.21724Z" fill="#212529" />
                    </svg>
                    <div
                        ref={popoverDropdownRef2}
                        className={
                            (dropdownPopoverShow2 ? "block " : "hidden ") +
                            (color === "white" ? "bg-white " : "bg-white") +
                            "text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 p-4 sm:bottom-start md:bottom-end"
                        }
                        style={{ minWidth: "15rem" }}
                    >
                        <div className="w-96">
                            <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                <h5 className="text-xl font-medium leading-normal text-gray-800">
                                    Notification
                                </h5>
                            </div>
                            <div className="mt-2">
                                {resultnotifList}
                            </div>
                        </div>
                    </div>

                    <button
                        className={
                            "inline-flex gap-4 text-black font-bold uppercase text-sm px-6 py-3 rounded-lg shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 bg-blue-dashboard-profile"
                        }
                        type="button"
                        ref={btnDropdownRef}
                        onClick={() => {
                            dropdownPopoverShow
                                ? closeDropdownPopover()
                                : openDropdownPopover();
                        }}
                    >
                        <div className="w-6 h-6 mr-2 rounded-full bg-gray-400">{useruuid.tableuserinitial}</div>
                        {useruuid.tableuserfullname}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 9L12 16L5 9" stroke="#707375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                    </button>
                    <div
                        ref={popoverDropdownRef}
                        className={
                            (dropdownPopoverShow ? "block " : "hidden ") +
                            (color === "white" ? "bg-blue-dashboard-profile " : "bg-blue-dashboard-profile") +
                            "text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
                        }
                        style={{ minWidth: "15rem" }}
                    >
                        <a
                            href="/profile"
                            className={
                                "inline-flex gap-3 text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent " +
                                (color === "white" ? " text-slate-700" : "text-white")
                            }
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#707375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#707375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            Your Profile
                        </a>
                        <div className="h-0 my-2 border border-solid border-t-0 border-slate-800 opacity-25" />
                        <a
                            href="/logout"
                            className={
                                "inline-flex gap-3 text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent " +
                                (color === "white" ? " text-slate-700" : "text-white")
                            }
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8" stroke="#707375" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            Logout
                        </a>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default NavbarAuth