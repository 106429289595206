const Header = ({ heading, description }) => {
    return (
        <div className="flex flex-col gap-3">
            <h2 className="font-bold">
                {heading}
            </h2>
            <h6 className="text-gray-400">
                {description}
            </h6>
        </div>
    );
}

export default Header;