import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";

import cryptoJS from "crypto-js";
import axios from "axios";

import Navbar from "../../layout/Navbar";

const Login = () => {
    const [Tableemail, setTableemail] = useState(null);

    const [errorEmail, setErrorEmail] = useState('');
    const [erroremailMessage, setErroremailmessage] = useState('');
    const [Message, setMessage] = useState('');

    const [showModalSuccess, setShowmodalsuccess] = useState(false);
    const [showModalError, setShowmodalerror] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    dispatch({type: 'START_PAGE', newisStartPage: true});

    const successcloseModal = () => {
        setShowmodalsuccess(false);
        navigate('/login');
    }

    const onSubmit = (e) => {
        e.preventDefault();

        let StringSignature = '';

        StringSignature += Tableemail;
        StringSignature += 'EN';

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/auth/v01/forgotusername',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                signature: signature
            },
            data: {
                tableuseremail: Tableemail
            }
        }).then(res => {
            if (res.data.status.status === 0) {

                res.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 9)) {
                        case '888005035':
                            setErrorEmail(element.code);
                            setErroremailmessage(element.errormassage);
                            break;
                        default:
                            setMessage(element.errormassage);

                            setShowmodalerror(true);
                            break;
                    }
                });

                let inputEmail = res.data.status.message.filter(key => key.code.substring(0, 9) === '888005035');

                if (inputEmail.length === 0) { setErrorEmail(''); }
            } else {
                setMessage("Please check your email, we had sent an email to inform your username.");

                setShowmodalsuccess(true);
                setTimeout(() => {
                    navigate('/login');
                }, 5000);
            }
        }).catch(error => {
            setMessage(error.errormassage);

            setShowmodalerror(true);
        })
    }

    return (
        <>
            {showModalSuccess ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                    onClick={successcloseModal}
                                >
                                    <span className="text-gray-400 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                                <div className="flex flex-col p-8 gap-8 items-center text-center">
                                    <img src={process.env.PUBLIC_URL + '/images/components/success/success_cat.png'} alt="success" className=""></img>
                                    <h3 className="max-sm:text-sm md:text-lg">{Message}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {showModalError ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                    onClick={() => setShowmodalerror(false)}
                                >
                                    <span className="text-gray-400 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                                <div className="flex flex-col p-8 gap-8 items-center text-center">
                                    <img src={process.env.PUBLIC_URL + '/images/components/error/error_cat.png'} alt="error" className=""></img>
                                    <h3 className="max-sm:text-sm md:text-lg">{Message}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            <div className="application overflow-x-hidden">
                <Navbar color="white" />

                <div className="grid h-screen place-items-center">
                    <div className="flex flex-col gap-6 items-center">
                        <h2 className="text-blue-cst-font">
                            Forgot Username
                        </h2>
                        <h6 className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-blue-cst-font text-justify">
                            Enter your email and we'll send an instructions to your email.
                        </h6>
                        <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                            <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Email" aria-label="Email" onChange={(e) => setTableemail(e.target.value)} />
                        </div>
                        {errorEmail.substring(0, 9) === '888005035' ?
                            <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {erroremailMessage}
                            </span> :
                            null}
                        <button className="h-12 max-sm:w-60 sm:w-88 md:w-96 text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={onSubmit}>
                            Forgot Username
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;