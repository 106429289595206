import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import cryptoJS from "crypto-js";
import axios from "axios";

import Header from "../../components/Header";

const DashboardContainer = () => {
    let heading = "Profile";
    let description = ""

    const [Tableuserfullname, setTableuserfullname] = useState('');
    const [Tableuseremail, setTableuseremail] = useState('');
    const [Tableusername, setTableusername] = useState('');
    const [Tableoldpassword, setTableoldpassword] = useState('');
    const [Tablenewpassword, setTablenewpassword] = useState('');
    const [Tablephonenumber, setTablephonenumber] = useState(0);

    const [eye, setEye] = useState(false);
    const [eyeconfirm, setEyeConfirm] = useState(false);

    const [showusernameModal, setShowusernamemodal] = useState(false);
    const [showpasswordModal, setShowpasswordmodal] = useState(false);

    const navigate = useNavigate();

    const eyeHandle = () => {
        if (eye) {
            setEye(false);
        } else {
            setEye(true);
        }
    }

    const eyeconfirmHandle = () => {
        if (eyeconfirm) {
            setEyeConfirm(false);
        } else {
            setEyeConfirm(true);
        }
    }

    const editUsername = () => {
        setShowusernamemodal(true);
    }

    const editPassword = () => {
        setShowpasswordmodal(true);
    }

    const editusernameHandle = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += Tableusername;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/user/v01/changeusername',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            data: {
                tableusername: Tableusername
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                localStorage.removeItem('useruuid');
                navigate('/login');

            } else {
                console.log(res.data.status);
            }
        })
    }

    const editpasswordHandle = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += Tableusername;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/user/v01/changepassword',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            data: {
                userpasswordold: Tableoldpassword,
                userpasswordnew: Tablenewpassword
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                localStorage.removeItem('useruuid');
                navigate('/login');

            } else {
                console.log(res.data.status);
            }
        })
    }

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/user/v01/profile',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
        }).then(res => {
            if (res.data.status.status === 1) {
                setTableuserfullname(res.data.data.tableuserfullname);
                setTableuseremail(res.data.data.tableuseremail);
                setTableusername(res.data.data.tableusername);
                setTablephonenumber(res.data.data.tableuserphonenumber);

            } else {
                console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }, [])

    return (
        <>
            {showusernameModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-5">
                                <button
                                    className="p-1 ml-auto border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                    onClick={() => setShowusernamemodal(false)}
                                >
                                    <span className="text-gray-400 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                                <div className="flex flex-col gap-4 mt-5">
                                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Username</label>
                                    <input type="text" name="username" id="username" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" placeholder="cst123" required onChange={(e) => setTableusername(e.target.value)} />
                                    <button className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={editusernameHandle}>Edit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {showpasswordModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-5">
                                <button
                                    className="p-1 ml-auto border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                    onClick={() => setShowpasswordmodal(false)}
                                >
                                    <span className="text-gray-400 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                                <div className="flex flex-col gap-4 mt-5">
                                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Old Password</label>
                                    <div className="w-80 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                        <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type={eye ? "text" : "password"} placeholder="Old Password" aria-label="Password" onChange={(e) => setTableoldpassword(e.target.value)} />
                                        <span className="cursor-pointer" onClick={eyeHandle}>
                                            {eye ?
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.5002 10C7.5002 11.3807 8.61949 12.5 10.0002 12.5C11.3809 12.5 12.5002 11.3807 12.5002 10C12.5002 8.61929 11.3809 7.50001 10.0002 7.50001C8.61949 7.50001 7.5002 8.61929 7.5002 10Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M17.9517 9.99998C16.8898 6.61907 13.7312 4.16667 9.99984 4.16667C6.26846 4.16667 3.10988 6.6191 2.048 10C3.10989 13.3809 6.26846 15.8333 9.99982 15.8333C13.7312 15.8333 16.8898 13.3809 17.9517 9.99998Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                :
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.5002 2.5L14.5092 5.49097M2.5002 17.5L5.49087 14.5093M8.43781 15.6872C8.94378 15.7831 9.46594 15.8333 9.99982 15.8333C13.7312 15.8333 16.8898 13.3809 17.9517 9.99997C17.6626 9.07958 17.2181 8.22801 16.649 7.47598M11.768 8.23223C11.3156 7.77982 10.6906 7.5 10.0002 7.5C8.61949 7.5 7.5002 8.61929 7.5002 10C7.5002 10.6904 7.78003 11.3154 8.23244 11.7678M11.768 8.23223L8.23244 11.7678M11.768 8.23223L14.5092 5.49097M8.23244 11.7678L14.5092 5.49097M8.23244 11.7678L5.49087 14.5093M14.5092 5.49097C13.2094 4.65295 11.6614 4.16667 9.99984 4.16667C6.26846 4.16667 3.10988 6.61909 2.048 10C2.63709 11.8756 3.87144 13.4654 5.49087 14.5093" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            }
                                        </span>
                                    </div>

                                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Password</label>
                                    <div className="w-80 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                                        <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type={eyeconfirm ? "text" : "password"} placeholder="New Password" aria-label="ReTypePassword" onChange={(e) => setTablenewpassword(e.target.value)} />
                                        <span className="cursor-pointer" onClick={eyeconfirmHandle}>
                                            {eyeconfirm ?
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.5002 10C7.5002 11.3807 8.61949 12.5 10.0002 12.5C11.3809 12.5 12.5002 11.3807 12.5002 10C12.5002 8.61929 11.3809 7.50001 10.0002 7.50001C8.61949 7.50001 7.5002 8.61929 7.5002 10Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M17.9517 9.99998C16.8898 6.61907 13.7312 4.16667 9.99984 4.16667C6.26846 4.16667 3.10988 6.6191 2.048 10C3.10989 13.3809 6.26846 15.8333 9.99982 15.8333C13.7312 15.8333 16.8898 13.3809 17.9517 9.99998Z" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                :
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.5002 2.5L14.5092 5.49097M2.5002 17.5L5.49087 14.5093M8.43781 15.6872C8.94378 15.7831 9.46594 15.8333 9.99982 15.8333C13.7312 15.8333 16.8898 13.3809 17.9517 9.99997C17.6626 9.07958 17.2181 8.22801 16.649 7.47598M11.768 8.23223C11.3156 7.77982 10.6906 7.5 10.0002 7.5C8.61949 7.5 7.5002 8.61929 7.5002 10C7.5002 10.6904 7.78003 11.3154 8.23244 11.7678M11.768 8.23223L8.23244 11.7678M11.768 8.23223L14.5092 5.49097M8.23244 11.7678L14.5092 5.49097M8.23244 11.7678L5.49087 14.5093M14.5092 5.49097C13.2094 4.65295 11.6614 4.16667 9.99984 4.16667C6.26846 4.16667 3.10988 6.61909 2.048 10C2.63709 11.8756 3.87144 13.4654 5.49087 14.5093" stroke="#5D5F61" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            }
                                        </span>
                                    </div>
                                    <button className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={editpasswordHandle}>Edit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            <div className="overflow-y-hidden w-4/5 h-auto box-content p-2 pl-20 pt-20 md:p-8 md:pl-72 md:pt-24 left-16 md:left-64">
                <div className="flex flex-col w-48 md:w-auto gap-8">
                    <div className="flex inline-flex justify-between">
                        <Header heading={heading} description={description} />
                    </div>
                    <div className="flex flex-col justify-items-center gap-4">
                        <div className="flex-row md:inline-flex justify-start gap-3 md:gap-0">
                            <div className="w-40 md:w-40 p-4">Fullname</div>
                            <div className="inline-flex justify-start md:justify-between w-72 md:w-full bg-gray-100 rounded-md p-4 items-center">
                                <div className="inline-flex gap-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#989B9D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#989B9D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <div>{Tableuserfullname}</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-row md:inline-flex justify-start">
                            <div className="w-40 p-4">Email</div>
                            <div className="inline-flex gap-3 w-full bg-gray-100 w-72 md:w-full rounded-md p-4 items-center">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 8L10.8906 13.2604C11.5624 13.7083 12.4376 13.7083 13.1094 13.2604L21 8M5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19Z" stroke="#989B9D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <div>{Tableuseremail}</div>
                            </div>
                        </div>
                        <div className="flex-row md:inline-flex justify-start">
                            <div className="w-40 p-4">Username</div>
                            <div className="inline-flex justify-between w-72 md:w-full bg-gray-100 rounded-md p-4 items-center">
                                <div className="inline-flex gap-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke="#989B9D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" stroke="#989B9D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <div>{Tableusername}</div>
                                </div>
                                <button className="h-8 w-24 md:w-24 text-lg text-indigo-100 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={editUsername}>
                                    Edit
                                </button>
                            </div>
                        </div>
                        <div className="flex-row md:inline-flex justify-start">
                            <div className="w-40 p-4">Password</div>
                            <div className="inline-flex justify-between w-72 md:w-full bg-gray-100 rounded-md p-4 items-center">
                                <div className="inline-flex gap-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21ZM16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11H16Z" stroke="#989B9D" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                    <div>••••••••</div>
                                </div>
                                <button className="h-8 w-24 md:w-24 text-lg text-indigo-100 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={editPassword}>
                                    Edit
                                </button>
                            </div>
                        </div>
                        <div className="flex-row md:inline-flex justify-start">
                            <div className="w-40 p-4">Phone Number</div>
                            <div className="inline-flex justify-between w-72 md:w-full bg-gray-100 rounded-md p-4 items-center">
                                <div className="inline-flex gap-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 5C3 3.89543 3.89543 3 5 3H8.27924C8.70967 3 9.09181 3.27543 9.22792 3.68377L10.7257 8.17721C10.8831 8.64932 10.6694 9.16531 10.2243 9.38787L7.96701 10.5165C9.06925 12.9612 11.0388 14.9308 13.4835 16.033L14.6121 13.7757C14.8347 13.3306 15.3507 13.1169 15.8228 13.2743L20.3162 14.7721C20.7246 14.9082 21 15.2903 21 15.7208V19C21 20.1046 20.1046 21 19 21H18C9.71573 21 3 14.2843 3 6V5Z" stroke="#989B9D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <div>+{Tablephonenumber}</div>
                                </div>
                            </div>
                        </div>
                        {/* <table className="w-full table-auto">
                        <tbody>
                            <tr>
                                <td className="w-40">Fullname</td>
                                <td className="bg-gray-100 rounded-md p-4">{Tableuserfullname}</td>
                            </tr>
                            <tr>
                                <td className="w-40">Email</td>
                                <td className="bg-gray-100 rounded-md p-4">{Tableuseremail}</td>
                            </tr>
                            <tr>
                                <td className="w-40">Username</td>
                                <td className="bg-gray-100 rounded-md p-4">{Tableusername}</td>
                            </tr>
                            <tr>
                                <td className="w-40">password</td>
                                <td className="bg-gray-100 rounded-md p-4">••••••••</td>
                            </tr>
                        </tbody>
                    </table> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardContainer