import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import cryptoJS from "crypto-js";
import axios from "axios";

import Header from "../../components/Header";

const TopupContainer = () => {
  var heading = "Top-Up";
  var description = "Top-Up your account in a snap!";

  const [tableAmount, setTableamount] = useState(0);
  const [tableMessage, setTablemessage] = useState("");
  const [tableCurrencyCode, setTablecurrencycode] = useState([]);
  const [tableCurrency, setTablecurrency] = useState("");
  const [tablePaymentmethodcode, setTablepaymentmethodcode] = useState([]);
  const [tablePaymentmethod, setTablepaymentmethod] = useState("");

  const [errorAmount, setErroramount] = useState("");
  const [erroramountMessage, setErroramountmessage] = useState("");
  const [errorMessage, setErrormessage] = useState("");
  const [errormessageMessage, setErrormessagemessage] = useState("");
  const [errorCurrency, setErrorcurrency] = useState("");
  const [errorcurrencyMessage, setErrorcurrencymessage] = useState("");
  const [errorPayment, setErrorpayment] = useState("");
  const [errorpaymentMessage, setErrorpaymentmessage] = useState("");
  const [Message, setMessage] = useState("");

  const [showModal, setShowModal] = useState(false);

  // const [step, setStep] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stepDeposit = useSelector((state) => state.stepDeposit);

  if (stepDeposit === 4) {
    var tempdeposit = JSON.parse(localStorage.getItem("temp-deposit"));
    var dateString = new Date(
      tempdeposit.tempdeposittimestamp
    ).toLocaleDateString("en-US");
    var timeString = new Date(
      tempdeposit.tempdeposittimestamp
    ).toLocaleTimeString("en-US");

    var datetimeString = dateString + " " + timeString;
  }

  const storeamountHandle = () => {
    let useruuid = JSON.parse(localStorage.getItem("useruuid"));
    let tokenTrans = JSON.parse(localStorage.getItem("tokentrans"));

    let StringSignature = "";

    StringSignature += "WEBSITE";
    StringSignature += "PASSS";
    StringSignature += useruuid.tableuserindex;
    StringSignature += useruuid.tokenlogin;
    StringSignature += tokenTrans;
    StringSignature += tableAmount;

    let signature = cryptoJS.HmacSHA256(
      StringSignature,
      process.env.REACT_APP_SECRET
    );

    axios({
      method: "POST",
      url: process.env.REACT_APP_APIHOST + "/api/deposit/v02/storeamount",
      headers: {
        platform: "WEBSITE",
        gtoken: "PASSS",
        userindex: useruuid.tableuserindex,
        tokenlogin: useruuid.tokenlogin,
        signature: signature,
      },
      data: {
        tokentrans: tokenTrans,
        tabletrxamount: tableAmount,
        tabletrxmessage: tableMessage,
      },
    }).then((res) => {
      if (res.data.status.status === 1) {
        setTableamount(res.data.data.tempdepositamount);
        setTablemessage(res.data.data.tempdepositmessage);
        setTablecurrencycode(res.data.support);

        // setStep(2);
        dispatch({ type: "STEP_DEPOSIT", newstepDeposit: 2 });
      } else {
        res.data.status.message.forEach((element) => {
          switch (element.code.substring(0, 9)) {
            case "888050040":
              setErroramount(element.code);
              setErroramountmessage(element.errormassage);
              break;
            case "888050045":
              setErrormessage(element.code);
              setErrormessagemessage(element.errormassage);
              break;
            default:
              setMessage(element.errormassage);

              setShowModal(true);
              break;
          }
        });

        let inputAmount = res.data.status.message.filter(
          (key) => key.code.substring(0, 9) === "888050040"
        );
        let inputMessage = res.data.status.message.filter(
          (key) => key.code.substring(0, 9) === "888050045"
        );

        if (inputAmount.length === 0) {
          setErroramount("");
        }
        if (inputMessage.length === 0) {
          setErrormessage("");
        }
      }
    });
  };

  const storeCurrencyHandle = () => {
    let useruuid = JSON.parse(localStorage.getItem("useruuid"));
    let tokenTrans = JSON.parse(localStorage.getItem("tokentrans"));

    let StringSignature = "";

    StringSignature += "WEBSITE";
    StringSignature += "PASSS";
    StringSignature += useruuid.tableuserindex;
    StringSignature += useruuid.tokenlogin;
    StringSignature += tokenTrans;
    StringSignature += tableCurrency;

    let signature = cryptoJS.HmacSHA256(
      StringSignature,
      process.env.REACT_APP_SECRET
    );

    axios({
      method: "POST",
      url: process.env.REACT_APP_APIHOST + "/api/deposit/v02/storecurrency",
      headers: {
        platform: "WEBSITE",
        gtoken: "PASSS",
        userindex: useruuid.tableuserindex,
        tokenlogin: useruuid.tokenlogin,
        signature: signature,
      },
      data: {
        tokentrans: tokenTrans,
        currencycode: tableCurrency,
      },
    }).then((res) => {
      if (res.data.status.status === 1) {
        setTableamount(res.data.data.tempdepositamount);
        setTablemessage(res.data.data.tempdepositmessage);
        setTablecurrency(res.data.data.tempdepositcurrencycode);
        setTablepaymentmethodcode(res.data.support);

        // setStep(3);
        dispatch({ type: "STEP_DEPOSIT", newstepDeposit: 3 });
      } else {
        res.data.status.message.forEach((element) => {
          switch (element.code.substring(0, 9)) {
            case "888050050":
              setErrorcurrency(element.code);
              setErrorcurrencymessage(element.errormassage);
              break;
            default:
              setMessage(element.errormassage);

              setShowModal(true);
              break;
          }
        });

        let inputCurrency = res.data.status.message.filter(
          (key) => key.code.substring(0, 9) === "888050050"
        );

        if (inputCurrency.length === 0) {
          setErrorcurrency("");
        }
      }
    });
  };

  const paymentmethodHandle = () => {
    let useruuid = JSON.parse(localStorage.getItem("useruuid"));
    let tokenTrans = JSON.parse(localStorage.getItem("tokentrans"));

    let StringSignature = "";

    StringSignature += "WEBSITE";
    StringSignature += "PASSS";
    StringSignature += useruuid.tableuserindex;
    StringSignature += useruuid.tokenlogin;
    StringSignature += tokenTrans;
    StringSignature += tablePaymentmethod;

    let signature = cryptoJS.HmacSHA256(
      StringSignature,
      process.env.REACT_APP_SECRET
    );

    axios({
      method: "POST",
      url:
        process.env.REACT_APP_APIHOST + "/api/deposit/v02/storepaymentmethod",
      headers: {
        platform: "WEBSITE",
        gtoken: "PASSS",
        userindex: useruuid.tableuserindex,
        tokenlogin: useruuid.tokenlogin,
        signature: signature,
      },
      data: {
        tokentrans: tokenTrans,
        tablepaymentmethodindex: tablePaymentmethod,
      },
    }).then((res) => {
      if (res.data.status.status === 1) {
        localStorage.setItem("temp-deposit", JSON.stringify(res.data.data));

        dispatch({ type: "STEP_DEPOSIT", newstepDeposit: 4 });

        // navigate('/topup/confirmation');
      } else {
        console.log(res.data);
        res.data.status.message.forEach((element) => {
          switch (element.code.substring(0, 9)) {
            case "888050100":
              setErrorpayment(element.code);
              setErrorpaymentmessage(element.errormassage);
              break;
            default:
              console.log("here");
              setMessage(element.errormassage);

              setShowModal(true);
              break;
          }
        });

        let inputPayment = res.data.status.message.filter(
          (key) => key.code.substring(0, 9) === "888050100"
        );

        if (inputPayment.length === 0) {
          setErrorpayment("");
        }
      }
    });
  };

  const storeHandle = () => {
    let useruuid = JSON.parse(localStorage.getItem("useruuid"));
    let tokenTrans = JSON.parse(localStorage.getItem("tokentrans"));

    let StringSignature = "";

    StringSignature += "WEBSITE";
    StringSignature += "PASSS";
    StringSignature += useruuid.tableuserindex;
    StringSignature += useruuid.tokenlogin;
    StringSignature += tokenTrans;

    let signature = cryptoJS.HmacSHA256(
      StringSignature,
      process.env.REACT_APP_SECRET
    );

    axios({
      method: "POST",
      url: process.env.REACT_APP_APIHOST + "/api/deposit/v02/store",
      headers: {
        platform: "WEBSITE",
        gtoken: "PASSS",
        userindex: useruuid.tableuserindex,
        tokenlogin: useruuid.tokenlogin,
        signature: signature,
      },
      data: {
        tokentrans: tokenTrans,
      },
    }).then((res) => {
      if (res.data.status.status === 1) {
        localStorage.removeItem("temp-deposit");
        localStorage.removeItem("tokentrans");

        navigate("/topup/success");
      } else {
        res.data.status.message.forEach((element) => {
          switch (element.code.substring(0, 9)) {
            default:
              setMessage(element.errormassage);

              setShowModal(true);
              break;
          }
        });
      }
    });
  };

  const backtoAmount = () => {
    let useruuid = JSON.parse(localStorage.getItem("useruuid"));
    let tokenTrans = JSON.parse(localStorage.getItem("tokentrans"));

    let StringSignature = "";

    StringSignature += "WEBSITE";
    StringSignature += "PASSS";
    StringSignature += useruuid.tableuserindex;
    StringSignature += useruuid.tokenlogin;
    StringSignature += tokenTrans;
    StringSignature += tablePaymentmethod;

    let signature = cryptoJS.HmacSHA256(
      StringSignature,
      process.env.REACT_APP_SECRET
    );

    axios({
      method: "POST",
      url:
        process.env.REACT_APP_APIHOST + "/api/deposit/v02/back/storecurrency",
      headers: {
        platform: "WEBSITE",
        gtoken: "PASSS",
        userindex: useruuid.tableuserindex,
        tokenlogin: useruuid.tokenlogin,
        signature: signature,
      },
      data: {
        tokentrans: tokenTrans,
      },
    }).then((res) => {
      if (res.data.status.status === 1) {
        localStorage.setItem("temp-deposit", JSON.stringify(res.data.data));

        // setStep(1);
        dispatch({ type: "STEP_DEPOSIT", newstepDeposit: 1 });
      } else {
        res.data.status.message.forEach((element) => {
          switch (element.code.substring(0, 9)) {
            default:
              setMessage(element.errormassage);

              setShowModal(true);
              break;
          }
        });

        let inputPayment = res.data.status.message.filter(
          (key) => key.code.substring(0, 9) === "888050100"
        );

        if (inputPayment.length === 0) {
          setErrorpayment("");
        }
      }
    });
  };

  const bakctoCurrency = () => {
    let useruuid = JSON.parse(localStorage.getItem("useruuid"));
    let tokenTrans = JSON.parse(localStorage.getItem("tokentrans"));

    let StringSignature = "";

    StringSignature += "WEBSITE";
    StringSignature += "PASSS";
    StringSignature += useruuid.tableuserindex;
    StringSignature += useruuid.tokenlogin;
    StringSignature += tokenTrans;
    StringSignature += tablePaymentmethod;

    let signature = cryptoJS.HmacSHA256(
      StringSignature,
      process.env.REACT_APP_SECRET
    );

    axios({
      method: "POST",
      url:
        process.env.REACT_APP_APIHOST +
        "/api/deposit/v02/back/storepaymentmethod",
      headers: {
        platform: "WEBSITE",
        gtoken: "PASSS",
        userindex: useruuid.tableuserindex,
        tokenlogin: useruuid.tokenlogin,
        signature: signature,
      },
      data: {
        tokentrans: tokenTrans,
      },
    }).then((res) => {
      if (res.data.status.status === 1) {
        localStorage.setItem("temp-deposit", JSON.stringify(res.data.data));

        // setStep(2);
        dispatch({ type: "STEP_DEPOSIT", newstepDeposit: 2 });
      } else {
        res.data.status.message.forEach((element) => {
          switch (element.code.substring(0, 9)) {
            default:
              setMessage(element.errormassage);

              setShowModal(true);
              break;
          }
        });

        let inputPayment = res.data.status.message.filter(
          (key) => key.code.substring(0, 9) === "888050100"
        );

        if (inputPayment.length === 0) {
          setErrorpayment("");
        }
      }
    });
  };

  const backtoPayment = () => {
    let useruuid = JSON.parse(localStorage.getItem("useruuid"));
    let tokenTrans = JSON.parse(localStorage.getItem("tokentrans"));

    let StringSignature = "";

    StringSignature += "WEBSITE";
    StringSignature += "PASSS";
    StringSignature += useruuid.tableuserindex;
    StringSignature += useruuid.tokenlogin;
    StringSignature += tokenTrans;
    StringSignature += tablePaymentmethod;

    let signature = cryptoJS.HmacSHA256(
      StringSignature,
      process.env.REACT_APP_SECRET
    );

    axios({
      method: "POST",
      url: process.env.REACT_APP_APIHOST + "/api/deposit/v02/back/store",
      headers: {
        platform: "WEBSITE",
        gtoken: "PASSS",
        userindex: useruuid.tableuserindex,
        tokenlogin: useruuid.tokenlogin,
        signature: signature,
      },
      data: {
        tokentrans: tokenTrans,
      },
    }).then((res) => {
      if (res.data.status.status === 1) {
        localStorage.setItem("temp-deposit", JSON.stringify(res.data.data));

        // setStep(2);
        dispatch({ type: "STEP_DEPOSIT", newstepDeposit: 3 });
      } else {
        res.data.status.message.forEach((element) => {
          switch (element.code.substring(0, 9)) {
            default:
              setMessage(element.errormassage);

              setShowModal(true);
              break;
          }
        });

        let inputPayment = res.data.status.message.filter(
          (key) => key.code.substring(0, 9) === "888050100"
        );

        if (inputPayment.length === 0) {
          setErrorpayment("");
        }
      }
    });
  };

  const handleBack = () => {
    if (stepDeposit === 1) {
      navigate("/topup");
    } else if (stepDeposit === 2) {
      backtoAmount();
    } else if (stepDeposit === 3) {
      bakctoCurrency();
    } else if (stepDeposit === 4) {
      backtoPayment();
    }
  };

  console.log({ tempdeposit });

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <button
                  className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="text-gray-400 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    x
                  </span>
                </button>
                <div className="flex flex-col p-24 gap-8 items-center">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/components/error/error_cat.png"
                    }
                    alt="error"
                    className=""
                  ></img>
                  <h3 className="uppercase font-bold">{Message}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <div className="overflow-y-hidden w-4/5 h-auto box-content p-2 pl-20 pt-20 md:p-8 md:pl-72 md:pt-24 left-16 md:left-64">
        <div className="flex flex-col w-auto gap-8">
          <div className="inline-flex justify-between">
            <Header heading={heading} description={description} />

            <div className="inline-flex gap-3">
              <button
                type="button"
                className="w-36 h-11 hover:bg-blue-cst-hover border-2 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex justify-center"
                onClick={handleBack}
              >
                <span className="mr-2">Back</span>
              </button>
            </div>
          </div>
          {stepDeposit === 1 ? (
            <div className="flex flex-col md:flex-row gap-8">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="amount"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Amount
                  </label>
                  <div className="w-72 md:w-96 relative items-center justify-center">
                    <input
                      type="text"
                      id="amount"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="200"
                      value={tableAmount}
                      onChange={(e) => setTableamount(e.target.value)}
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <span className="text-blue-cst-primary">CST</span>
                    </div>
                  </div>
                  {errorAmount.substring(0, 9) === "888050040" ? (
                    <span className="w-72 md:w-96 text-red-600 inline-flex gap-2">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                          stroke="#CB3A31"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {erroramountMessage}
                    </span>
                  ) : (
                    <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-blue-cst-font-info inline-flex gap-2">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                          stroke="#3267E3"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Minimum Top-Up 1 CST
                    </span>
                  )}
                </div>

                <div className="flex flex-col gap-2">
                  <div className="w-72 md:w-96 relative items-center justify-center">
                    <label
                      htmlFor="message"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Message
                    </label>
                    <input
                      type="text"
                      id="message"
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 md:w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Message"
                      required
                      value={tableMessage}
                      onChange={(e) => setTablemessage(e.target.value)}
                    />
                  </div>
                  {errorMessage.substring(0, 9) === "888050045" ? (
                    <span className="w-72 md:w-96 text-red-600 inline-flex gap-2">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                          stroke="#CB3A31"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {errormessageMessage}
                    </span>
                  ) : (
                    <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-blue-cst-font-info inline-flex gap-2">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                          stroke="#3267E3"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Max 32 characters (optional)
                    </span>
                  )}
                </div>
                <button
                  className="h-12 w-72 md:w-96 text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover"
                  onClick={storeamountHandle}
                >
                  Next
                </button>
              </div>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/components/topup/topup_cat.png"
                }
                alt="topup"
                className="w-72 md:w-96"
              ></img>
            </div>
          ) : (
            ""
          )}

          {stepDeposit === 2 ? (
            <div className="flex flex-col md:flex-row gap-8">
              <div>
                <label
                  htmlFor="amount"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Amount
                </label>
                <div className="relative mb-6 w-72 md:w-96">
                  <input
                    type="text"
                    id="amount"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    disabled
                    value={tableAmount}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <span className="text-blue-cst-primary">CST</span>
                  </div>
                </div>
                <div className="mb-6 w-72 md:w-96">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Message
                  </label>
                  <input
                    type="text"
                    id="message"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 md:w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    disabled
                    value={tableMessage}
                  />
                </div>
                <div className="mb-6 w-72 md:w-96">
                  <label
                    htmlFor="currency"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Currency
                  </label>
                  <select
                    id="currency"
                    className="form-control w-72 md:w-96"
                    onChange={(e) => setTablecurrency(e.target.value)}
                  >
                    <option value="">-- CHOOSE CURRENCY --</option>
                    {tableCurrencyCode.map((item, i) => (
                      <option
                        key={i}
                        value={item.temptrxcurrencyratecurrencycode}
                      >
                        {item.temptrxcurrencyratecurrencycode}
                      </option>
                    ))}
                  </select>
                  {errorCurrency.substring(0, 9) === "888050050" ? (
                    <span className="w-80 md:w-96 text-red-600 inline-flex gap-2">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                          stroke="#CB3A31"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {errorcurrencyMessage}
                    </span>
                  ) : null}
                </div>
                <button
                  className="h-12 w-72 md:w-96 text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover"
                  onClick={storeCurrencyHandle}
                >
                  Next
                </button>
              </div>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/components/topup/topup_cat.png"
                }
                alt="topup"
                className="w-72 md:w-96"
              ></img>
            </div>
          ) : (
            ""
          )}

          {stepDeposit === 3 ? (
            <div className="flex flex-col md:flex-row gap-8">
              <div>
                <label
                  htmlFor="amount"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Amount
                </label>
                <div className="relative mb-6 w-72 md:w-96">
                  <input
                    type="text"
                    id="amount"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    disabled
                    value={tableAmount}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <span className="text-blue-cst-primary">CST</span>
                  </div>
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Message
                  </label>
                  <input
                    type="text"
                    id="message"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 md:w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    disabled
                    value={tableMessage}
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="currency"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Currency
                  </label>
                  <input
                    type="text"
                    id="currency"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 md:w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    disabled
                    value={tableCurrency}
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="payment_method"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Choose Network
                  </label>
                  <select
                    id="payment_method"
                    className="form-control w-72 md:w-96"
                    onChange={(e) => setTablepaymentmethod(e.target.value)}
                  >
                    <option value="">-- CHOOSE NETWORK --</option>
                    {tablePaymentmethodcode.map((item, i) => (
                      <option key={i} value={item.index}>
                        {item.code}
                      </option>
                    ))}
                  </select>
                  {errorPayment.substring(0, 9) === "888050100" ? (
                    <span className="w-80 md:w-96 text-red-600 inline-flex gap-2">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                          stroke="#CB3A31"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {errorpaymentMessage}
                    </span>
                  ) : null}
                </div>
                <button
                  className="h-12 w-72 md:w-96 text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover"
                  onClick={paymentmethodHandle}
                >
                  Next
                </button>
              </div>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/components/topup/topup_cat.png"
                }
                alt="topup"
                className="w-72 md:w-96"
              ></img>
            </div>
          ) : (
            ""
          )}

          {stepDeposit === 4 ? (
            <div className="flex flex-col w-auto gap-8 text-xs">
              <div className="w-72 md:w-auto border-2 rounded-md p-8">
                <div className="flex flex-col gap-8">
                  <div className="inline-flex">
                    <div className="flex w-96">Date Time</div>
                    <div>{datetimeString}</div>
                  </div>
                  <div className="inline-flex">
                    <div className="flex w-96">Transaction Code</div>
                    <div>{tempdeposit.tempdepositindex}</div>
                  </div>
                  <div className="inline-flex">
                    <div className="flex w-96">Amount CST</div>
                    <div>{tempdeposit.tempdepositamount} CST</div>
                  </div>
                </div>
              </div>
              <div className="w-72 md:w-auto border-2 rounded-md p-8">
                <div className="flex flex-col gap-8">
                  <div className="inline-flex">
                    <div className="flex w-96">Currency</div>
                    <div>
                      {tempdeposit.tempdepositpaymentmethodcurrencycode}
                    </div>
                  </div>
                  {/* <div className="inline-flex">
                    <div className="flex w-96">Currency Rate</div>
                    <div>{tempdeposit.tempdepositraterate}</div>
                  </div> */}
                  <div className="inline-flex">
                    <div className="flex w-96">Platform</div>
                    <div>{tempdeposit.tempdepositpaymentmethodcode}</div>
                  </div>
                  <div className="inline-flex">
                    <div className="flex w-96">Rate</div>
                    <div>{tempdeposit.tempdepositraterate}</div>
                  </div>
                  <div className="inline-flex">
                    <div className="flex w-96">Amount</div>
                    <div>{tempdeposit._reate_amount}</div>
                  </div>
                  <div className="inline-flex">
                    <div className="flex w-96">Fee</div>
                    <div>{tempdeposit._fee}</div>
                  </div>
                  {/* <div className="inline-flex">
                                        <div className="flex w-96">
                                            Unique Code
                                        </div>
                                        <div>
                                            {tempdeposit._unique_code}
                                        </div>
                                    </div>
                                    <div className="inline-flex bg-blue-cst-box-bg py-4">
                                        <div className="flex w-96">
                                            Total
                                        </div>
                                        <div>
                                            {tempdeposit._total} CST
                                        </div>
                                    </div> */}
                  <button
                    className="h-12 w-full md:w-full text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover"
                    onClick={storeHandle}
                  >
                    Confirm
                  </button>
                </div>
              </div>
              {/* <table class="w-full table-auto">
                            <tbody>
                                <tr>
                                    <td>Amount</td>
                                    <td>{tempdeposit.tempdepositamount} CST</td>
                                </tr>
                                <tr>
                                    <td>Currency</td>
                                    <td>{tempdeposit.tempdepositcurrencycode}</td>
                                </tr>
                                <tr>
                                    <td>Total in USDT</td>
                                    <td>{tempdeposit.tempdepositrateamount}</td>
                                </tr>
                                <tr>
                                    <td>Network</td>
                                    <td>{tempdeposit.tempdepositpaymentmethodcode}</td>
                                </tr>
                                <tr>
                                    <td>Fee</td>
                                    <td>{tempdeposit.tempdepositfee}</td>
                                </tr>
                                <tr>
                                    <td>Total</td>
                                    <td>{tempdeposit.tempdeposittotal}</td>
                                </tr>
                                <tr>
                                    <td className="col-span-2">
                                        <button className="h-12 w-72 md:w-96 text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={storeHandle}>
                                            Top-Up
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default TopupContainer;
