import Navbar from "../layout/Navbar";

const Home = () => {
    return (
        <>
            <div className="application overflow-x-hidden">
                <Navbar color="white"/>

                <div className="grid h-screen place-items-center">
                    <div className="w-80 md:w-auto bg-blue-cst-box-bg bg-opacity-100 rounded-xl">
                        <div className="flex flex-col gap-6 items-center p-24">
                            <img src={process.env.PUBLIC_URL + '/images/components/success/registration/success_cat.png'} alt="brand" className="logo-desktop w-44"></img>
                            <h3 className="w-72 md:w-auto font-bold text-center">
                                Registration Success
                            </h3>
                            <h6 className="w-72 md:w-auto text-md text-slate-400 text-center">
                                Our system just sent initial password. Please check your email
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;