import Navbar from "../../layout/NavbarAuth";
import Sidebar from "../../layout/Sidebar";
import MutationContainer from "../../layout/container/mutation/MutationContainer"

const Mutation = () => {
    return (
        <>
            <div className="application overflow-x-hidden">
                <Navbar color="white"/>
                <Sidebar />

                <MutationContainer />
            </div>
        </>
    );
}

export default Mutation;