import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';

import cryptoJS from "crypto-js";
import axios from "axios";

import Header from "../../components/Header";

const TransferConfirmationContainer = () => {
    let transfer = JSON.parse(localStorage.getItem("transfer"));
    let dateString = new Date(transfer.data.tempdeposittimestamp).toLocaleDateString('en-US');
    let timeString = new Date(transfer.data.tempdeposittimestamp).toLocaleTimeString('en-US');

    let datetimeString = dateString + ' ' + timeString;

    let heading = "Confirmation";
    let description = "Confirmation your transaction"

    const alert = useAlert();
    const navigate = useNavigate();

    const storeHandle = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));
        // let tokenTrans = JSON.parse(localStorage.getItem('tokentrans'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += transfer.status.tokentrans;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/transfer/v01/store',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            data: {
                tokentrans: transfer.status.tokentrans
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                localStorage.removeItem('transfer');

                navigate('/transfer/success');
            } else {
                res.data.status.message.forEach(element => {
                    alert.error(element.code);
                });
            }
        })
    }

    return (
        <div className="overflow-y-hidden w-4/5 h-auto box-content p-2 pl-20 pt-20 md:p-8 md:pl-72 md:pt-24 left-16 md:left-64">
            <div className="flex flex-col w-auto gap-8">
                <div className="flex inline-flex justify-between">
                    <Header heading={heading} description={description} />
                </div>
                <div className="w-80 md:w-auto border-2 rounded-md p-8">
                    <div className="flex flex-col gap-8">
                        <div className="inline-flex">
                            <div className="flex w-96">
                                Date Time
                            </div>
                            <div>
                                {datetimeString}
                            </div>
                        </div>
                        <div className="inline-flex">
                            <div className="flex w-96">
                                Transaction Code
                            </div>
                            <div>
                                {transfer.data.tempdepositindex}
                            </div>
                        </div>
                        <div className="inline-flex">
                            <div className="flex w-96">
                                Amount CST
                            </div>
                            <div>
                                {transfer.data.tempdepositamount} CST
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-80 md:w-auto border-2 rounded-md p-8">
                    <div className="flex flex-col gap-8">
                        <div className="inline-flex">
                            <div className="flex w-96">
                                Currency
                            </div>
                            <div>
                                {transfer.data.tempdepositpaymentmethodcurrencycode}
                            </div>
                        </div>
                        <div className="inline-flex">
                            <div className="flex w-96">
                                Platform
                            </div>
                            <div>
                                {transfer.data.tempdepositpaymentmethodcode}
                            </div>
                        </div>
                        <div className="inline-flex">
                            <div className="flex w-96">
                                Rate
                            </div>
                            <div>
                                {transfer.data.tempdepositraterate}
                            </div>
                        </div>
                        <div className="inline-flex">
                            <div className="flex w-96">
                                Amount
                            </div>
                            <div>
                                {transfer.data._amount} CST
                            </div>
                        </div>
                        <div className="inline-flex">
                            <div className="flex w-96">
                                Fee
                            </div>
                            <div>
                                {transfer.data.fee} CST
                            </div>
                        </div>
                        <div className="inline-flex">
                            <div className="flex w-96">
                                Unique Code
                            </div>
                            <div>
                                -
                            </div>
                        </div>
                        <div className="inline-flex bg-blue-cst-box-bg py-4">
                            <div className="flex w-96">
                                Total
                            </div>
                            <div>
                                {transfer.data.total} CST
                            </div>
                        </div>
                        <button className="h-12 w-full md:w-full text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={storeHandle}>
                            Confirm
                        </button>
                    </div>
                </div>
                {/* <table class="w-full table-auto">
                        <tbody>
                            <tr>
                                <td>Date</td>
                                <td>{transfer.date} CST</td>
                            </tr>
                            <tr>
                                <td>Recipient</td>
                                <td>{transfer.recipent}</td>
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <td>{transfer.amount}</td>
                            </tr>
                            <tr>
                                <td>Fee</td>
                                <td>{transfer.fee}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>{transfer.total}</td>
                            </tr>
                            <tr>
                                <td className="col-span-2">
                                    <button className="h-12 w-72 md:w-96 text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={storeHandle}>
                                        Transfer
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
            </div>
        </div>
    );
};

export default TransferConfirmationContainer