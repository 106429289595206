import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

const Sidebar = () => {
    const isDashboardActivePage = useSelector(state => state.isDashboardActivePage);
    const isTuhistoryActivePage = useSelector(state => state.isTuhistoryActivePage);
    const isTfhistoryActivePage = useSelector(state => state.isTfhistoryActivePage);
    const isTrHistoryActivePage = useSelector(state => state.isTrHistoryActivePage);

    var backGroundDash = '';
    var backGroundTuHist = '';
    var backGroundTfHist = '';
    var backGroundTrHist = '';

    if (isDashboardActivePage) {
        backGroundDash = 'bg-blue-cst-primary-active';
    }

    if (isTuhistoryActivePage) {
        backGroundTuHist = 'bg-blue-cst-primary-active';
    }

    if (isTfhistoryActivePage) {
        backGroundTfHist = 'bg-blue-cst-primary-active';
    }

    if (isTrHistoryActivePage) {
        backGroundTrHist = 'bg-blue-cst-primary-active';
    }

    return (
        <aside className="w-16 md:w-64 fixed z-50 top-0 bg-white" aria-label="Sidebar">
            <div className="flex flex-col min-h-screen pt-6 md:pt-0 px-2 py-2 overflow-y-scroll rounded border-l-2 justify-between">
                <div className='flex flex-col'>
                    <Link to={'/dashboard'} className="flex items-center mb-10 block md:hidden">
                        <img src={process.env.PUBLIC_URL + '/images/logo_mobile_cst.png'} alt="brand" className="logo-mobile"></img>
                    </Link>
                    <Link to={'/dashboard'} className="flex items-center mb-10 hidden md:block">
                        <img src={process.env.PUBLIC_URL + '/images/logo_cst.svg'} alt="brand" className="logo-desktop max-sm:w-24 sm:w-30 md:w-36 ml-4 mt-4"></img>
                    </Link>
                    <ul className="space-y-2">
                        <li className={backGroundDash + " rounded-lg block md:hidden"}>
                            <a href="/dashboard" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 6C20 4.89543 19.1046 4 18 4H16C14.8954 4 14 4.89543 14 6V8C14 9.10457 14.8954 10 16 10H18C19.1046 10 20 9.10457 20 8V6Z" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 6C10 4.89543 9.10457 4 8 4H6C4.89543 4 4 4.89543 4 6V8C4 9.10457 4.89543 10 6 10H8C9.10457 10 10 9.10457 10 8V6Z" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20 16C20 14.8954 19.1046 14 18 14H16C14.8954 14 14 14.8954 14 16V18C14 19.1046 14.8954 20 16 20H18C19.1046 20 20 19.1046 20 18V16Z" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 16C10 14.8954 9.10457 14 8 14H6C4.89543 14 4 14.8954 4 16V18C4 19.1046 4.89543 20 6 20H8C9.10457 20 10 19.1046 10 18V16Z" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </a>
                        </li>
                        <li className={backGroundTuHist + " rounded-lg block md:hidden"}>
                            <a href="/topup/history" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 9V12M12 12V15M12 12H9M12 12H15M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12Z" stroke="#989B9D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </a>
                        </li>
                        <li className={backGroundTfHist + " rounded-lg block md:hidden"}>
                            <a href="/transfer/history" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 7L4 7M4 7L8 3M4 7L8 11M8 17L20 17M20 17L16 21M20 17L16 13" stroke="#989B9D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </a>
                        </li>
                        <li className={backGroundTrHist + " rounded-lg block md:hidden"}>
                            <a href="/transaction/history" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.35135 10.3591V15.4873C7.35135 16.6202 9.28741 17.5386 11.6757 17.5386C14.0639 17.5386 16 16.6202 16 15.4873V10.3591M7.35135 10.3591C7.35135 11.492 9.28741 12.4104 11.6757 12.4104C14.0639 12.4104 16 11.492 16 10.3591M7.35135 10.3591C7.35135 9.22625 9.28741 8.30786 11.6757 8.30786C14.0639 8.30786 16 9.22625 16 10.3591M16 12.9232C16 14.0561 14.0639 14.9745 11.6757 14.9745C9.28741 14.9745 7.35135 14.0561 7.35135 12.9232" stroke="#989B9D" />
                                    <path d="M5.72308 2.91238C7.99359 1.59035 10.6633 1.0227 13.3113 1.29889C15.9594 1.57508 18.4353 2.6794 20.3489 4.43782C22.2624 6.19624 23.5048 8.50877 23.8802 11.011C24.2557 13.5132 23.7428 16.0627 22.4224 18.2579C21.102 20.453 19.0493 22.1689 16.5876 23.1351C14.1259 24.1014 11.3954 24.263 8.82621 23.5945C6.25704 22.9261 3.99538 21.4656 2.39766 19.4431C0.799935 17.4207 -0.0430037 14.9514 0.00168878 12.4243L1.38885 12.4464C1.34933 14.6813 2.09481 16.8651 3.50782 18.6537C4.92082 20.4424 6.92101 21.734 9.19314 22.3252C11.4653 22.9164 13.8802 22.7734 16.0572 21.9189C18.2343 21.0644 20.0497 19.5469 21.2174 17.6055C22.3852 15.6642 22.8388 13.4094 22.5067 11.1964C22.1747 8.98355 21.076 6.93838 19.3836 5.38325C17.6913 3.82813 15.5016 2.85148 13.1597 2.60722C10.8178 2.36297 8.45679 2.86499 6.44877 4.03417L5.72308 2.91238Z" fill="#989B9D" />
                                    <path d="M4.66137 1.29115C5.05567 0.684093 5.94433 0.684092 6.33863 1.29115L8.3938 4.45529C8.8259 5.12057 8.34846 6 7.55517 6H3.44483C2.65154 6 2.17409 5.12057 2.6062 4.4553L4.66137 1.29115Z" fill="#989B9D" />
                                </svg>
                            </a>
                        </li>
                        <li className={backGroundDash + " rounded-lg hidden md:block"}>
                            <a href="/dashboard" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 6C20 4.89543 19.1046 4 18 4H16C14.8954 4 14 4.89543 14 6V8C14 9.10457 14.8954 10 16 10H18C19.1046 10 20 9.10457 20 8V6Z" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 6C10 4.89543 9.10457 4 8 4H6C4.89543 4 4 4.89543 4 6V8C4 9.10457 4.89543 10 6 10H8C9.10457 10 10 9.10457 10 8V6Z" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M20 16C20 14.8954 19.1046 14 18 14H16C14.8954 14 14 14.8954 14 16V18C14 19.1046 14.8954 20 16 20H18C19.1046 20 20 19.1046 20 18V16Z" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 16C10 14.8954 9.10457 14 8 14H6C4.89543 14 4 14.8954 4 16V18C4 19.1046 4.89543 20 6 20H8C9.10457 20 10 19.1046 10 18V16Z" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <span className="ml-3">Dashboard</span>
                            </a>
                        </li>
                        <li className={backGroundTuHist + " rounded-lg hidden md:block"}>
                            <a href="/topup/history" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 9V12M12 12V15M12 12H9M12 12H15M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12Z" stroke="#989B9D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <span className="flex-1 ml-3 whitespace-nowrap">Top-Up History</span>
                            </a>
                        </li>
                        <li className={backGroundTfHist + " rounded-lg hidden md:block"}>
                            <a href="/transfer/history" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 7L4 7M4 7L8 3M4 7L8 11M8 17L20 17M20 17L16 21M20 17L16 13" stroke="#989B9D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                                <span className="flex-1 ml-3 whitespace-nowrap">Transfer History</span>
                            </a>
                        </li>
                        <li className={backGroundTrHist + " rounded-lg hidden md:block"}>
                            <a href="/transaction/history" className="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.35135 10.3591V15.4873C7.35135 16.6202 9.28741 17.5386 11.6757 17.5386C14.0639 17.5386 16 16.6202 16 15.4873V10.3591M7.35135 10.3591C7.35135 11.492 9.28741 12.4104 11.6757 12.4104C14.0639 12.4104 16 11.492 16 10.3591M7.35135 10.3591C7.35135 9.22625 9.28741 8.30786 11.6757 8.30786C14.0639 8.30786 16 9.22625 16 10.3591M16 12.9232C16 14.0561 14.0639 14.9745 11.6757 14.9745C9.28741 14.9745 7.35135 14.0561 7.35135 12.9232" stroke="#989B9D" />
                                    <path d="M5.72308 2.91238C7.99359 1.59035 10.6633 1.0227 13.3113 1.29889C15.9594 1.57508 18.4353 2.6794 20.3489 4.43782C22.2624 6.19624 23.5048 8.50877 23.8802 11.011C24.2557 13.5132 23.7428 16.0627 22.4224 18.2579C21.102 20.453 19.0493 22.1689 16.5876 23.1351C14.1259 24.1014 11.3954 24.263 8.82621 23.5945C6.25704 22.9261 3.99538 21.4656 2.39766 19.4431C0.799935 17.4207 -0.0430037 14.9514 0.00168878 12.4243L1.38885 12.4464C1.34933 14.6813 2.09481 16.8651 3.50782 18.6537C4.92082 20.4424 6.92101 21.734 9.19314 22.3252C11.4653 22.9164 13.8802 22.7734 16.0572 21.9189C18.2343 21.0644 20.0497 19.5469 21.2174 17.6055C22.3852 15.6642 22.8388 13.4094 22.5067 11.1964C22.1747 8.98355 21.076 6.93838 19.3836 5.38325C17.6913 3.82813 15.5016 2.85148 13.1597 2.60722C10.8178 2.36297 8.45679 2.86499 6.44877 4.03417L5.72308 2.91238Z" fill="#989B9D" />
                                    <path d="M4.66137 1.29115C5.05567 0.684093 5.94433 0.684092 6.33863 1.29115L8.3938 4.45529C8.8259 5.12057 8.34846 6 7.55517 6H3.44483C2.65154 6 2.17409 5.12057 2.6062 4.4553L4.66137 1.29115Z" fill="#989B9D" />
                                </svg>

                                <span className="flex-1 ml-3 whitespace-nowrap">Transaction History</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='text-center'>
                    <h6 className='text-sm'>V 1.0.99</h6>
                    <h6 className='text-gray-400 hidden md:block'>Last Update : 2023/01/16 18:29:00</h6>
                </div>
            </div>
        </aside>
    );
}

export default Sidebar;