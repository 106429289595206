import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

const Navbar = () => {
    const isStartPage = useSelector(state => state.isStartPage);

    return (
        <>
            <nav className="bg-white px-2 sm:px-4 py-2.5 dark:bg-gray-900 w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">
                <div className="container flex flex-wrap items-center justify-between mx-auto">
                    <Link to={'/'} className="flex items-center">
                        <img src={process.env.PUBLIC_URL + '/images/logo_cst.svg'} alt="brand" className="logo-desktop max-sm:w-24 sm:w-30 md:w-36"></img>
                    </Link>
                    {isStartPage ? null : (
                        <div className="flex md:order-2">
                            <a href="https://csttoken.net/">
                                <button type="button" className="max-sm:w-24 max-sm:h-8 sm:h-10 text-white text-lg bg-blue-cst hover:bg-blue-cst-hover focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-sm text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                                    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 15.8334L8 10.0001L15 4.16675" stroke="#FAF7F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className="mr-2 max-sm:text-sm md:text-lg">Back</span>
                                </button>
                            </a>
                        </div>
                    )}
                </div>
            </nav>
        </>
    );
};

export default Navbar