import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import cryptoJS from 'crypto-js';

const Registration = () => {

    const navigate = useNavigate();

    useEffect(() => {
        let StringSignature = '';

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/auth/v01/insertnew',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                signature: signature
            },
            data: {}
        }).then(res => {
            if (res.data.status.status === 1) {
                const Id = res.data.status.tokentrans;

                localStorage.setItem('phone-country', JSON.stringify(res.data.support));
                navigate(`/register/${Id}`);
            } else {
                navigate('/login');
            }
        }).catch(error => {
            console.log(error);
        })
    }, [navigate]);

    return (<div>Loading ...</div>)
};

export default Registration;
