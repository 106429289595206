import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";

import cryptoJS from "crypto-js";
import axios from "axios";
import copy from 'clipboard-copy';

import Header from "../../components/Header";

const DashboardContainer = () => {
    let useruuid = JSON.parse(localStorage.getItem("useruuid"));
    let heading = "Dashboard";
    let description = "Welcome back " + useruuid.tableuserfullname + "!"

    const [verifyButtonText, setVerifyButtonText] = useState("Verify Now!")
    const [historyList, setHistorylist] = useState([]);
    const [historyListtransfer, setHistorylisttransfer] = useState([]);
    const [resBalance, setResbalance] = useState(0);
    const [resAccount, setResaccount] = useState('');
    const [KYCStatus, setKYCStatus] = useState(2);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    dispatch({ type: 'DASHBOARD_ACTIVE', newisDashboardActivePage: true });
    dispatch({ type: 'TUHISTORY_ACTIVE', newisTuhistoryActivePage: false });
    dispatch({ type: 'TFHISTORY_ACTIVE', newisTfhistoryActivePage: false });
    dispatch({ type: 'TRHISTORY_ACTIVE', newisTrhistoryActivePage: false });

    let balanceIDR = Math.floor(resBalance * 10000);

    const handleCopyClick = async () => {
        await copy(resAccount);
    };

    const topupInsertnew = () => {
        navigate('/topup');
    }

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/wallet/v01/info',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
        }).then(res => {
            if (res.data.status.status === 1) {
                setResbalance(res.data.data.ballance);
                setResaccount(res.data.data.walletaccount);

            } else {
                // console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }, [])

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'GET',
            url: process.env.REACT_APP_APIHOST + '/api/transaction/v01/history',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            params: {
                condition: "TOPUP",
                startdate: today,
                enddate: today
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                setHistorylist(res.data.data);

            } else {
                // console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }, [])

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'GET',
            url: process.env.REACT_APP_APIHOST + '/api/transaction/v01/history',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            params: {
                condition: "TRANSFER",
                startdate: today,
                enddate: today
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                setHistorylisttransfer(res.data.data);

            } else {
                // console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }, [])
    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/user/v01/profile',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
        }).then(res => {
            if (res.data.status.status === 1) {
                setKYCStatus(res.data.data.tableuser_kyc_status)
        
            } else {
                // console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }, [])
    const doVerification  = async ()=>{
        let StringSignature = '';
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));
        setVerifyButtonText("Mohon Tunggu")
        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);
        
        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/user/v01/kyc/insertnew',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            data: {}
        }).then(res => {
            if (res.data.status.status === 0) {
                const Id = res.data.status.tokentrans;
                navigate(`/authVerification/${Id}`);
            } else {
                // navigate('/login');
            }
            setVerifyButtonText("Verifikasi Identitas")
        }).catch(error => {
            console.log(error);
        })
    }  
    
    let resulthistoryList = historyList.map((result, key) => {
        let dateString = new Date(result.timestampblockchaintransaction).toLocaleDateString('en-US');
        let timeString = new Date(result.timestampblockchaintransaction).toLocaleTimeString('en-US');

        let datetimeString = dateString + ' ' + timeString;

        let status = result.status;
        var color = '';

        if (status === 'CANCELED') {
            color = 'bg-status-cancel';
        } else if (status === 'UNPAID') {
            color = 'bg-status-unpaid';
        } else if (status === 'PAID') {
            color = 'bg-status-paid';
        } else if (status === 'CONFIRMED') {
            color = 'bg-status-confirm';
        } else if (status === 'COMPLETE') {
            color = 'bg-status-success';
        }

        return (
            <div className={"flex flex-col mb-10 border-b-2"} key={key}>
                <div className="inline-flex justify-between">
                    <div className="inline-flex justify-start gap-5">
                        <img src={process.env.PUBLIC_URL + '/images/components/topup/icon/icon_topup.png'} alt="icon" className="w-10 h-8"></img>

                        <div className="flex flex-col">
                            <h5>Topup</h5>
                            <h6 className="text-gray-400 hidden md:block">{datetimeString}</h6>
                        </div>
                    </div>

                    <div className="flex flex-col items-end mb-4 gap-2">
                        <h5 className="text-green-600 text-sm md:text-md">+ {result.total} CST</h5>
                        <div className="inline-flex">
                            <button type="button" className={color + " w-24 h-6 text-white font-medium rounded-3xl text-xs px-1 py-1 text-center cursor-default"} >
                                <span>{result.status}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    let resulthistorytransferList = historyListtransfer.map((result, key) => {
        let dateString = new Date(result.timestampblockchaintransaction).toLocaleDateString('en-US');
        let timeString = new Date(result.timestampblockchaintransaction).toLocaleTimeString('en-US');

        let datetimeString = dateString + ' ' + timeString;

        let status = result.status;
        var color = '';

        if (status === 'QUEUEING') {
            color = 'bg-status-paid';
        } else if (status === 'CONFIRMED') {
            color = 'bg-status-confirm';
        } else if (status === 'COMPLETE') {
            color = 'bg-status-success';
        }

        return (
            <div className={"flex flex-col mb-10 border-b-2"} key={key}>
                <div className="inline-flex justify-between">
                    <div className="inline-flex justify-start gap-5">
                        <img src={process.env.PUBLIC_URL + '/images/components/transfer/icon/icon_transfer.png'} alt="icon" className="w-10 h-8"></img>

                        <div className="flex flex-col">
                            <h5>Transfer</h5>
                            <h6 className="hidden md:block">To : {result.recepient}</h6>
                            <h6 className="text-gray-400 hidden md:block">{datetimeString}</h6>
                        </div>
                    </div>

                    <div className="flex flex-col items-end mb-4 gap-2">
                        <h5 className="text-red-600 text-sm md:text-md">- {result.total} CST</h5>
                        <div className="inline-flex">
                            <button type="button" className={color + " w-24 h-6 text-white font-medium rounded-3xl text-xs px-1 py-1 text-center cursor-default"} >
                                <span>{result.status}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return (
        <div className="overflow-y-hidden w-4/5 h-auto box-content p-2 pl-20 pt-20 md:p-8 md:pl-72 md:pt-24 left-16 md:left-64">
            <div className="flex flex-col w-auto gap-8">
                <div className="flex flex-col md:flex-row justify-between gap-5 md:gap-0">
                    <Header heading={heading} description={description} />
                    <div className="inline-flex gap-2 md:gap-3">
                        <a href="/transfer">
                            <button type="button" className="w-18 md:w-36 md:h-11 hover:bg-blue-cst-hover-white border-2 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex justify-center">
                                <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 5.83333L4 5.83333M4 5.83333L8 2.5M4 5.83333L8 9.16667M8 14.1667L20 14.1667M20 14.1667L16 17.5M20 14.1667L16 10.8333" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="mr-2">Transfer</span>
                            </button>
                        </a>
                        <button type="button" className="w-36 h-11 text-white bg-blue-cst-primary hover:bg-blue-cst-hover border-2 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex justify-center" onClick={topupInsertnew} >
                            <span className="mr-2">Top-Up</span>
                        </button>
                    </div>
                </div>
                {
                    (KYCStatus === -1 || KYCStatus === 0)?
                    <div className="flex flex-row w-[100%] bg-[#EECEB0] px-5 py-5  rounded-xl justify-between">
                        <div className="flex flex-col gap-3">
                            <span className="font-semibold">
                                Verify your identity to enjoy our full services!
                            </span>
                            <label className="font-normal text-[14px]">
                            Protect your identity and gain access to all the features you need with just a few clicks.
                            </label>
                        </div>
                        <div className="flex items-center">
                            <button className="bg-[#CD7B2E] justify-center py-[10px] px-[25px] font-normal text-[14px] rounded-lg text-[#FFFFFF]"
                            onClick={verifyButtonText !== 'Mohon Tunggu' ? ()=>doVerification() : null}
                            >
                               {verifyButtonText}
                            </button>
                        </div>
                    </div>: null
                }
                {
                    (KYCStatus === 1) ?
                    <div className="flex flex-row w-[100%] bg-[#B1C5F6] px-5 py-5  rounded-xl justify-between">
                        <div className="flex flex-col gap-3">
                            <span className="font-semibold">
                            Your identity verification is being reviewed by our team!
                            </span>
                            <label className="font-normal text-[14px]">
                            We are currently reviewing your data verification, please wait for the process approcimately 1x24 hours
                            </label>
                        </div>
                    </div>: null
                }     
                    
               
                <div className="flex flex-col gap-5">
                    <div className="font-bold">My Wallet</div>
                    <div className="flex flex-col md:flex-row gap-16">
                        <div className="w-64 md:w-72 h-40 bg-[url('/public/images/components/dashboard/bg_card.png')] rounded-lg p-6">
                            <div className="flex flex-col gap-4 text-white">
                                <div className="inline-flex justify-between">
                                    <div className="flex flex-col">
                                        <h6>{useruuid.tableuserfullname}</h6>
                                    </div>
                                    <div className="inline-flex">
                                        <img src={process.env.PUBLIC_URL + '/images/components/dashboard/logo_cst.png'} alt="brand" className="w-8 h-8"></img>
                                        <h5>CST</h5>
                                    </div>
                                </div>
                                <div className="inline-flex gap-5">
                                    <h5>{resAccount}</h5>
                                    <svg className="cursor-pointer" onClick={handleCopyClick} width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="12" cy="12" r="12" fill="#E0E0F3" />
                                        <path d="M15.3333 15.3333H17C17.9204 15.3333 18.6666 14.5871 18.6666 13.6666V6.99992C18.6666 6.07944 17.9204 5.33325 17 5.33325H10.3333C9.41282 5.33325 8.66663 6.07944 8.66663 6.99992V8.66659M13.6666 18.6666H6.99996C6.07949 18.6666 5.33329 17.9204 5.33329 16.9999V10.3333C5.33329 9.41278 6.07949 8.66659 6.99996 8.66659H13.6666C14.5871 8.66659 15.3333 9.41278 15.3333 10.3333V16.9999C15.3333 17.9204 14.5871 18.6666 13.6666 18.6666Z" stroke="#4C4DDC" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <div className="flex flex-col">
                                        <h6>{resBalance} CST</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-64 md:w-72 h-40 border-2 rounded-md p-4">
                            <div className="flex flex-col">
                                <div className="flex flex-row justify-between">
                                    <div className="font-bold">IDR</div>
                                    <svg width="53" height="63" viewBox="0 0 53 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M46.5 20C46.5 31.0457 37.5457 40 26.5 40C15.4543 40 6.5 31.0457 6.5 20C6.5 8.9543 15.4543 0 26.5 0C37.5457 0 46.5 8.9543 46.5 20ZM12.5 15.5C12.5 14.1193 13.6193 13 15 13H37C38.3807 13 39.5 14.1193 39.5 15.5C39.5 16.8807 38.3807 18 37 18H15C13.6193 18 12.5 16.8807 12.5 15.5ZM15 23C13.6193 23 12.5 24.1193 12.5 25.5C12.5 26.8807 13.6193 28 15 28H37C38.3807 28 39.5 26.8807 39.5 25.5C39.5 24.1193 38.3807 23 37 23H15Z" fill="black" fillOpacity="0.2" />
                                        <path d="M11.5205 56V54.6992H14.5527V44.293H11.5205V42.9922H19.3252V44.293H16.293V54.6992H19.3252V56H11.5205ZM21.9883 56V42.9922H25.3896C27.4111 42.9922 28.8525 43.5459 29.7139 44.6533C30.5811 45.7549 31.0146 47.2842 31.0146 49.2412C31.0146 50.2725 30.8975 51.2188 30.6631 52.0801C30.4346 52.9355 30.0244 53.6885 29.4326 54.3389C28.8643 54.9658 28.2168 55.3994 27.4902 55.6396C26.7637 55.8799 25.9082 56 24.9238 56H21.9883ZM23.7197 54.6992H24.5898C25.8027 54.6992 26.7109 54.5146 27.3145 54.1455C27.9238 53.7705 28.3838 53.1904 28.6943 52.4053C29.0107 51.6201 29.1689 50.6211 29.1689 49.4082C29.1689 48.2188 29.0195 47.2461 28.7207 46.4902C28.4277 45.7285 28 45.1719 27.4375 44.8203C26.8809 44.4629 25.9111 44.2842 24.5283 44.2842L23.7197 44.293V54.6992ZM33.3086 56V42.9922H36.4639C37.6826 42.9922 38.5615 43.1064 39.1006 43.335C39.6396 43.5635 40.0615 43.9414 40.3662 44.4688C40.6768 44.9902 40.832 45.5527 40.832 46.1562C40.832 46.7832 40.6533 47.4365 40.2959 48.1162C39.9443 48.7959 39.25 49.3994 38.2129 49.9268L42.2646 56H40.1641L36.6045 50.4365H35.04V56H33.3086ZM35.04 49.1357H35.9541C36.9561 49.1357 37.7119 48.8545 38.2217 48.292C38.7314 47.7236 38.9863 47.0586 38.9863 46.2969C38.9863 45.8926 38.9014 45.541 38.7314 45.2422C38.5674 44.9434 38.3037 44.7119 37.9404 44.5479C37.5771 44.3779 36.9648 44.293 36.1035 44.293H35.04V49.1357Z" fill="black" fillOpacity="0.2" />
                                    </svg>
                                </div>
                                <div className="flex-col">
                                    <h5>My Balance</h5>
                                    <h2>{balanceIDR}</h2>
                                </div>
                            </div>
                        </div>
                        {/* <div className="w-72 md:w-72 rounded-md p-8">
                            <div className="flex flex-col gap-5">
                                <div className="font-bold">Wallet Information</div>
                                <div className="flex-col">
                                    <h5>wallet Address</h5>
                                    <h6>{resAccount}</h6>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="grid grid-rows-2 md:grid-cols-2 gap-8">
                    <div className="flex flex-col gap-5">
                        <div>
                            {/* <h5 className="font-bold">Recent Top-Up</h5> */}
                            <h5 className="font-bold">Recent Transaction</h5>
                        </div>
                        <div className="inline-flex gap-8">
                            <div className="w-72 md:w-[560px] h-auto border-2 rounded-md p-4 md:p-8">
                                {resulthistoryList}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-5">
                        <div>
                            <h5 className="font-bold">Recent Transfer</h5>
                        </div>
                        <div className="inline-flex gap-8">
                            <div className="w-72 md:w-[560px] h-auto border-2 rounded-md p-4 md:p-8">
                                {resulthistorytransferList}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardContainer