import { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import cryptoJS from "crypto-js";
import axios from "axios";

import Header from "../../components/Header";

const MutationContainer = () => {
    let heading = "Transaction History";
    let description = "";

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    var thismonth = new Date();
    var ddthismonth = String(thismonth.getDate()).padStart(2, '0');
    var mmthismonth = String(thismonth.getMonth()).padStart(2, '0');
    var yyyythismonth = thismonth.getFullYear();

    if (mmthismonth === '00') {
        mmthismonth = '12';

        yyyythismonth = thismonth.getFullYear() - 1;
    }

    thismonth = yyyythismonth + '-' + mmthismonth + '-' + ddthismonth;

    const [dateStart, setDatestart] = useState(thismonth);
    // const [filterStatus, setFilterstatus] = useState('');

    const [isFilter, setIsfilter] = useState(false);
    const [mutationList, setMutationlist] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();
    
    dispatch({type: 'DASHBOARD_ACTIVE', newisDashboardActivePage: false});
    dispatch({type: 'TUHISTORY_ACTIVE', newisTuhistoryActivePage: false});
    dispatch({type: 'TFHISTORY_ACTIVE', newisTfhistoryActivePage: false});
    dispatch({type: 'TRHISTORY_ACTIVE', newisTrhistoryActivePage: true});


    const filterHandle = () => {
        setShowModal(true);
    }

    const mutationsList = useCallback(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'GET',
            url: process.env.REACT_APP_APIHOST + '/api/transaction/v01/mutation',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            params: {
                startdate: dateStart
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                setMutationlist(res.data.data);

            } else {
                console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }, [dateStart])

    const processFilter = () => {
        setIsfilter(true);
        setShowModal(false);

        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'GET',
            url: process.env.REACT_APP_APIHOST + '/api/transaction/v01/history',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            params: {
                enddate: dateStart
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                setMutationlist(res.data.data);

            } else {
                console.log(res.data);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        if (isFilter) {

        } else {
            mutationsList();
        }
    }, [mutationsList, isFilter]);

    let resultmutationList = mutationList.map((result, key) => {
        let dateString = new Date(result.timestamp).toLocaleDateString('en-US');
        let timeString = new Date(result.timestamp).toLocaleTimeString('en-US');

        let datetimeString = dateString + ' ' + timeString;

        let status = result.status;
        var color = '';

        if (status === 'QUEUEING') {
            color = 'bg-status-paid';
        } else if (status === 'CONFIRMED') {
            color = 'bg-status-confirm';
        } else if (status === 'COMPLETE') {
            color = 'bg-status-success';
        }

        return (
            <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700" key={key}>
                <td className="px-6 py-4">
                    {datetimeString}
                </td>
                <td className="px-6 py-4">
                    {result.sender}
                </td>
                <td className="px-6 py-4">
                    {result.recepient}
                </td>
                <td className="px-6 py-4">
                    {result.description}
                </td>
                <td className="px-6 py-4">
                    {result.amount}
                </td>
                <td className="px-6 py-4">
                    <button type="button" className={color + " w-36 h-8 text-white font-medium rounded-3xl text-sm px-1 py-1 text-center mr-3 cursor-default"} >
                        <span>{result.status}</span>
                    </button>
                </td>
            </tr>
        )
    })

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Filter
                                    </h3>
                                    <button
                                        className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="text-gray-400 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            x
                                        </span>
                                    </button>
                                </div>
                                <div className="flex flex-col p-8 gap-8">
                                    <div className="flex flex-col gap-5">
                                        <h6 className="text-gray-500">Filter by Date</h6>
                                        <div className="flex flex-row gap-4 items-center">
                                            <input type="date" className="h-12 border-2 rounded-lg p-2" value={dateStart} onChange={(e) => setDatestart(e.target.value)} />
                                        </div>
                                    </div>
                                    <button className="h-12 w-full text-lg text-indigo-100 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={processFilter}>
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null
            }

            <div className="overflow-y-hidden w-4/5 h-auto box-content p-2 pl-20 pt-20 md:p-8 md:pl-72 md:pt-24 left-16 md:left-64">
                <div className="flex flex-col w-auto gap-8">
                    <div className="flex flex-col md:flex-row justify-between gap-5 md:gap-0">
                        <Header heading={heading} description={description} />

                        <div className="inline-flex gap-3">
                            <button type="button" className="w-36 h-11 hover:bg-blue-cst-hover-white border-2 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex justify-center" onClick={filterHandle} >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5 3.5C17.5 2.94771 17.0523 2.5 16.5 2.5H3.5C2.94772 2.5 2.5 2.94772 2.5 3.5V5.41912C2.5 5.68434 2.60536 5.93869 2.79289 6.12623L8.04044 11.3738C8.22798 11.5613 8.33333 11.8157 8.33333 12.0809V14.1667L11.6667 17.5V12.0809C11.6667 11.8157 11.772 11.5613 11.9596 11.3738L17.2071 6.12623C17.3946 5.93869 17.5 5.68434 17.5 5.41912V3.5Z" stroke="#0A0A0A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="mr-2">Filter</span>
                            </button>
                        </div>
                    </div>
                    <div className="w-72 md:w-auto border-2 rounded-md p-8">
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Date Time
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Sender
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Recipient
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Description
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Total
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resultmutationList}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MutationContainer