import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import cryptoJS from "crypto-js";
import axios from "axios";

import Header from "../../components/Header";

const TopupConfirmationContainer = () => {
    let tempdeposit = JSON.parse(localStorage.getItem("temp-deposit"));
    let dateString = new Date(tempdeposit.tempdeposittimestamp).toLocaleDateString('en-US');
    let timeString = new Date(tempdeposit.tempdeposittimestamp).toLocaleTimeString('en-US');

    let datetimeString = dateString + ' ' + timeString;

    let heading = "Confirmation";
    let description = "Confirmation your transaction"

    const [Message, setMessage] = useState('');

    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stepDeposit = useSelector(state => state.stepDeposit);

    const storeHandle = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));
        let tokenTrans = JSON.parse(localStorage.getItem('tokentrans'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += tokenTrans;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/deposit/v02/store',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            data: {
                tokentrans: tokenTrans
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                localStorage.removeItem('temp-deposit');
                localStorage.removeItem('tokentrans');

                navigate('/topup/success');
            } else {
                res.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 9)) {
                        default:
                            setMessage(element.errormassage);

                            setShowModal(true);
                            break;
                    }
                });
            }
        })
    }

    const backtoPayment = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));
        let tokenTrans = JSON.parse(localStorage.getItem('tokentrans'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += tokenTrans;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/deposit/v02/back/store',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            data: {
                tokentrans: tokenTrans
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                // setStep(2);
                dispatch({ type: 'STEP_DEPOSIT', newstepDeposit: 3 });
                navigate('/topup');
            } else {
                res.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 9)) {
                        default:
                            setMessage(element.errormassage);

                            setShowModal(true);
                            break;
                    }
                });
            }
        })
    }

    const handleBack = () => {
        if (stepDeposit === 4) {
            backtoPayment();
        }
    }

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                    onClick={() => setShowModal(false)}
                                >
                                    <span className="text-gray-400 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                                <div className="flex flex-col p-24 gap-8 items-center">
                                    <img src={process.env.PUBLIC_URL + '/images/components/error/error_cat.png'} alt="error" className=""></img>
                                    <h3 className="uppercase font-bold">{Message}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            <div className="overflow-y-hidden w-4/5 h-auto box-content p-2 pl-20 pt-20 md:p-8 md:pl-72 md:pt-24 left-16 md:left-64">
                <div className="flex flex-col w-auto gap-8">
                    <div className="flex inline-flex justify-between">
                        <Header heading={heading} description={description} />

                        <div className="inline-flex gap-3">
                            <button type="button" className="w-36 h-11 hover:bg-blue-cst-hover border-2 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2.5 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex justify-center" onClick={handleBack} >
                                <span className="mr-2">Back</span>
                            </button>
                        </div>
                    </div>
                    <div className="w-80 md:w-auto border-2 rounded-md p-8">
                        <div className="flex flex-col gap-8">
                            <div className="inline-flex">
                                <div className="flex w-96">
                                    Date Time
                                </div>
                                <div>
                                    {datetimeString}
                                </div>
                            </div>
                            <div className="inline-flex">
                                <div className="flex w-96">
                                    Transaction Code
                                </div>
                                <div>
                                    {tempdeposit.tempdepositindex}
                                </div>
                            </div>
                            <div className="inline-flex">
                                <div className="flex w-96">
                                    Amount CST
                                </div>
                                <div>
                                    {tempdeposit.tempdepositamount} CST
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-80 md:w-auto border-2 rounded-md p-8">
                        <div className="flex flex-col gap-8">
                            <div className="inline-flex">
                                <div className="flex w-96">
                                    Currency
                                </div>
                                <div>
                                    {tempdeposit.tempdepositpaymentmethodcurrencycode}
                                </div>
                            </div>
                            <div className="inline-flex">
                                <div className="flex w-96">
                                    Platform
                                </div>
                                <div>
                                    {tempdeposit.tempdepositpaymentmethodcode}
                                </div>
                            </div>
                            <div className="inline-flex">
                                <div className="flex w-96">
                                    Rate
                                </div>
                                <div>
                                    {tempdeposit.tempdepositraterate}
                                </div>
                            </div>
                            <div className="inline-flex">
                                <div className="flex w-96">
                                    Amount
                                </div>
                                <div>
                                    {tempdeposit._amount} CST
                                </div>
                            </div>
                            <div className="inline-flex">
                                <div className="flex w-96">
                                    Fee
                                </div>
                                <div>
                                    {tempdeposit._fee} CST
                                </div>
                            </div>
                            <div className="inline-flex">
                                <div className="flex w-96">
                                    Unique Code
                                </div>
                                <div>
                                    {tempdeposit._unique_code}
                                </div>
                            </div>
                            <div className="inline-flex bg-blue-cst-box-bg py-4">
                                <div className="flex w-96">
                                    Total
                                </div>
                                <div>
                                    {tempdeposit._total} CST
                                </div>
                            </div>
                            <button className="h-12 w-full md:w-full text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={storeHandle}>
                                Confirm
                            </button>
                        </div>
                    </div>
                    {/* <table class="w-full table-auto">
                        <tbody>
                            <tr>
                                <td>Amount</td>
                                <td>{tempdeposit.tempdepositamount} CST</td>
                            </tr>
                            <tr>
                                <td>Currency</td>
                                <td>{tempdeposit.tempdepositcurrencycode}</td>
                            </tr>
                            <tr>
                                <td>Total in USDT</td>
                                <td>{tempdeposit.tempdepositrateamount}</td>
                            </tr>
                            <tr>
                                <td>Network</td>
                                <td>{tempdeposit.tempdepositpaymentmethodcode}</td>
                            </tr>
                            <tr>
                                <td>Fee</td>
                                <td>{tempdeposit.tempdepositfee}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td>{tempdeposit.tempdeposittotal}</td>
                            </tr>
                            <tr>
                                <td className="col-span-2">
                                    <button className="h-12 w-72 md:w-96 text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={storeHandle}>
                                        Top-Up
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table> */}
                </div>
            </div>
        </>
    );
};

export default TopupConfirmationContainer