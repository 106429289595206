import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { positions, Provider as Providers } from "react-alert";

import AlertTemplate from "react-alert-template-basic";

import Home from '../components/pages/home/Home';
import Login from '../components/auth/Login';
import Logout from '../components/auth/Logout';
import Register from '../components/auth/Register';
import Registration from '../components/auth/Registration';
import RegisterSuccess from '../components/success/Registration';
import AuthSuccess from '../components/success/AuthVerification';
import CreateNewPassword from '../components/pages/createnewpassword/CreateNewPassword';
import CreateNewPasswordSuccess from '../components/success/CreateNewPassword';
import ForgotPassword from '../components/pages/forgot/ForgotPassword';
import ForgotUsername from '../components/pages/forgot/ForgotUsername';

import Authenticate from '../hoc/Authenticate';

import Dashboard from '../components/pages/dashboard/Dashboard';
import Profile from '../components/pages/profile/Profile';
import Topup from '../components/pages/topup/Topup';
import TopupConfirmation from '../components/pages/topup/TopupConfirmation';
import TopupSuccess from '../components/pages/topup/TopupSuccess';
import TopupHistory from '../components/pages/topup/TopupHistory';
import Transfer from '../components/pages/transfer/Transfer';
import TransferConfirmation from '../components/pages/transfer/TransferConfirmation';
import TransferSuccess from '../components/pages/transfer/TransferSuccess';
import TransferHistory from '../components/pages/transfer/TransferHistory';
import TransferConfirm from '../components/pages/transfer/TransferConfirm';
import Mutation from '../components/pages/mutation/Mutation';
import AuthVerification from '../components/auth/AuthVerification';

const options = {
    timeout: 5000,
    position: positions.TOP_RIGHT
};

const Router = () => {
    return (
        <>
            <Providers template={AlertTemplate} {...options}>
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/logout' element={<Logout />} />
                        <Route path='/registration' element={<Registration />} />
                        <Route path='/register/:id' element={<Register />} />
                        <Route path='/authVerification/:id' element={<AuthVerification />} />
                        <Route path='/authVerification/success' element={<AuthSuccess />} />
                        <Route path='/register/success' element={<RegisterSuccess />} />
                        <Route path='/new-password/:verificationCode' element={<CreateNewPassword />} />
                        <Route path='/new-password/success' element={<CreateNewPasswordSuccess />} />
                        <Route path='/forgot/password' element={<ForgotPassword />} />
                        <Route path='/forgot/username' element={<ForgotUsername />} />

                        <Route element={<Authenticate />}>
                            <Route path='/dashboard' element={<Dashboard />} />
                            <Route path='/profile' element={<Profile />} />
                            <Route path='/topup' element={<Topup />} />
                            <Route path='/topup/confirmation' element={<TopupConfirmation />} />
                            <Route path='/topup/success' element={<TopupSuccess />} />
                            <Route path='/topup/history' element={<TopupHistory />} />
                            <Route path='/transfer' element={<Transfer />} />
                            <Route path='/transfer/confirmation' element={<TransferConfirmation />} />
                            <Route path='/transfer/success' element={<TransferSuccess />} />
                            <Route path='/transfer/history' element={<TransferHistory />} />
                            <Route path='/confirm-transfer/:verificationCode' element={<TransferConfirm />} />
                            <Route path='/transaction/history' element={<Mutation />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </Providers>
        </>
    );
}

export default Router;