import Navbar from "../../layout/NavbarAuth";
import Sidebar from "../../layout/Sidebar";
import TopupHistoryContainer from "../../layout/container/topup/TopupHistoryContainer"

const TopupHistory = () => {
    return (
        <>
            <div className="application overflow-x-hidden">
                <Navbar color="white"/>
                <Sidebar />

                <TopupHistoryContainer />
            </div>
        </>
    );
}

export default TopupHistory;