import Navbar from "../../layout/NavbarAuth";
import Sidebar from "../../layout/Sidebar";
import TransferConfirmContainer from "../../layout/container/transfer/TransferConfirmContainer"

const TransferConfirm = () => {
    return (
        <>
            <div className="application overflow-x-hidden">
                <Navbar color="white"/>
                <Sidebar />

                <TransferConfirmContainer />
            </div>
        </>
    );
}

export default TransferConfirm;