import { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";

import cryptoJS from "crypto-js";
import axios from "axios";

import Navbar from "../layout/Navbar";

const Login = () => {
    const [Tableusername, setTableusername] = useState(null);
    const [Tablefullname, setTablefullname] = useState(null);
    const [Tableemail, setTableemail] = useState(null);
    const [Tablecountrycode, setTablecountrycode] = useState(62);
    const [Tablephonenumbershort, setTablephonenumbershort] = useState(null);

    const [errorUsername, setErrorUsername] = useState('');
    const [errorusernameMessage, setErrorusernamemessage] = useState('');
    const [errorFullname, setErrorFullname] = useState('');
    const [errorfullnameMessage, setErrorfullnamemessage] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [erroremailMessage, setErroremailmessage] = useState('');
    const [errorCountrycode, setErrorCountrycode] = useState('');
    const [errorcountrycodeMessage, setErrorcountrycodemessage] = useState('');
    const [errorPhonenumbershort, setErrorPhonenumbershort] = useState('');
    const [errorphonenumbershortMessage, setErrorphonenumbershortmessage] = useState('');
    const [Message, setMessage] = useState('');

    const [checkBoxtnc, setCheckboxtnc] = useState(false);
    const [checkBoxpnp, setCheckboxpnp] = useState(false);
    const [bottomTnc, setBottomtnc] = useState(false);
    const [bottomPnp, setBottompnp] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showtncModal, setShowtncmodal] = useState(false);
    const [showpnpModal, setShowpnpmodal] = useState(false);

    let phoneCountry = JSON.parse(localStorage.getItem('phone-country'));

    const navigate = useNavigate();
    const dispatch = useDispatch();

    dispatch({ type: 'START_PAGE', newisStartPage: true });

    const { id } = useParams();

    const opentncmodalHandle = () => {
        setBottomtnc(false);
        setBottompnp(false);
        setShowtncmodal(true);
        setShowpnpmodal(false);
        setCheckboxtnc(false);
        setCheckboxpnp(false);
    }

    const openpnpmodalHandle = () => {
        setShowtncmodal(false);
        setShowpnpmodal(true);
    }

    const checkboxtncHandle = () => {
        if (checkBoxtnc) {
            setCheckboxtnc(false);
        } else {
            setCheckboxtnc(true);
        }
    }

    const checkboxpnpHandle = () => {
        if (checkBoxpnp) {
            setCheckboxpnp(false);
        } else {
            setCheckboxpnp(true);
        }
    }

    const handleScrolltnc = (e) => {
        const countbottomTnc = e.target.scrollHeight - Math.trunc(e.target.scrollTop) === e.target.clientHeight;

        setBottomtnc(countbottomTnc);
    };

    const handleScrollpnp = (e) => {
        const bottomPnp = e.target.scrollHeight - Math.trunc(e.target.scrollTop) === e.target.clientHeight;

        setBottompnp(bottomPnp)
    };

    const registerHandle = () => {
        opentncmodalHandle();
    }

    const getCodeCountry = (country) => {
        let codeCountry = phoneCountry.filter(function (e) {
            return e.tablecountryphonecodecountryname === country;
        });

        setTablecountrycode(codeCountry[0].tablecountryphonecodephonecode);
    }

    const onfirstSubmit = (e) => {
        e.preventDefault();

        let StringSignature = '';

        StringSignature += Tableusername;
        StringSignature += Tablefullname;
        StringSignature += Tableemail;
        StringSignature += Tablecountrycode;
        StringSignature += Tablephonenumbershort;
        StringSignature += id;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/auth/v01/store',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                signature: signature
            },
            data: {
                tableusername: Tableusername,
                tableuserfullname: Tablefullname,
                tableuseremail: Tableemail,
                userphonecountrycode: Tablecountrycode,
                userphonenumbershort: Tablephonenumbershort,
                tokentrans: id
            }
        }).then(res => {
            if (res.data.status.status === 0) {

                res.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 9)) {
                        case '888005015':
                            setErrorUsername(element.code);
                            setErrorusernamemessage(element.errormassage);
                            break;
                        case '888005020':
                            setErrorFullname(element.code);
                            setErrorfullnamemessage(element.errormassage);
                            break;
                        case '888005035':
                            setErrorEmail(element.code);
                            setErroremailmessage(element.errormassage);
                            break;
                        case '888025015':
                            setErrorCountrycode(element.code);
                            setErrorcountrycodemessage(element.errormassage);
                            break;
                        case '888005060':
                            setErrorPhonenumbershort(element.code);
                            setErrorphonenumbershortmessage(element.errormassage);
                            break;
                        default:
                            setMessage(element.errormassage);

                            setShowModal(true);
                            break;
                    }
                });

                let inputUsername = res.data.status.message.filter(key => key.code.substring(0, 9) === '888005015');
                let inputFullname = res.data.status.message.filter(key => key.code.substring(0, 9) === '888005020');
                let inputEmail = res.data.status.message.filter(key => key.code.substring(0, 9) === '888005035');
                let inputCountrycode = res.data.status.message.filter(key => key.code.substring(0, 9) === '888025015');
                let inputPhonenumber = res.data.status.message.filter(key => key.code.substring(0, 9) === '888005060');

                if (inputUsername.length === 0) { setErrorUsername(''); }
                if (inputFullname.length === 0) { setErrorFullname(''); }
                if (inputEmail.length === 0) { setErrorEmail(''); }
                if (inputCountrycode.length === 0) { setErrorCountrycode(''); }
                if (inputPhonenumber.length === 0) { setErrorPhonenumbershort(''); }
            } else {
                registerHandle();
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const onsecondSubmit = (e) => {
        e.preventDefault();

        let StringSignature = '';

        StringSignature += Tableusername;
        StringSignature += Tablefullname;
        StringSignature += Tableemail;
        StringSignature += Tablecountrycode;
        StringSignature += Tablephonenumbershort;
        StringSignature += id;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/auth/v01/accept',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                signature: signature
            },
            data: {
                tokentrans: id
            }
        }).then(res => {
            if (res.data.status.status === 0) {

                res.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 9)) {
                        case '888005015':
                            setErrorUsername(element.code);
                            setErrorusernamemessage(element.errormassage);
                            break;
                        case '888005020':
                            setErrorFullname(element.code);
                            setErrorfullnamemessage(element.errormassage);
                            break;
                        case '888005035':
                            setErrorEmail(element.code);
                            setErroremailmessage(element.errormassage);
                            break;
                        case '888025015':
                            setErrorCountrycode(element.code);
                            setErrorcountrycodemessage(element.errormassage);
                            break;
                        case '888005060':
                            setErrorPhonenumbershort(element.code);
                            setErrorphonenumbershortmessage(element.errormassage);
                            break;
                        default:
                            setMessage(element.errormassage);

                            setShowModal(true);
                            break;
                    }
                });

                let inputUsername = res.data.status.message.filter(key => key.code.substring(0, 9) === '888005015');
                let inputFullname = res.data.status.message.filter(key => key.code.substring(0, 9) === '888005020');
                let inputEmail = res.data.status.message.filter(key => key.code.substring(0, 9) === '888005035');
                let inputCountrycode = res.data.status.message.filter(key => key.code.substring(0, 9) === '888025015');
                let inputPhonenumber = res.data.status.message.filter(key => key.code.substring(0, 9) === '888005060');

                if (inputUsername.length === 0) { setErrorUsername(''); }
                if (inputFullname.length === 0) { setErrorFullname(''); }
                if (inputEmail.length === 0) { setErrorEmail(''); }
                if (inputCountrycode.length === 0) { setErrorCountrycode(''); }
                if (inputPhonenumber.length === 0) { setErrorPhonenumbershort(''); }
            } else {
                setBottomtnc(false);
                setBottompnp(false);
                setShowtncmodal(false);
                setShowpnpmodal(false);
                setCheckboxtnc(false);
                setCheckboxpnp(false);

                navigate('/register/success');
            }
        }).catch(error => {
            console.log(error);
        })
    }

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                    onClick={() => setShowModal(false)}
                                >
                                </button>
                                <div className="flex flex-col p-24 gap-8 items-center">
                                    <img src={process.env.PUBLIC_URL + '/images/components/error/error_cat.png'} alt="error" className=""></img>
                                    <h3 className="uppercase font-bold">{Message}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            {showtncModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col max-sm:w-[260px] md:w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                    <h5 className="text-xl font-medium leading-normal text-gray-800">
                                        Term and Condition
                                    </h5>
                                    <button
                                        className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                    </button>
                                </div>
                                <div className="flex flex-col p-8 gap-8 items-center overflow-y-auto overflow-x-hidden max-sm:max-h-[400px] md:max-h-[500px] text-justify" onScroll={handleScrolltnc}>
                                    {/* <p>Syarat dan Ketentuan penggunaan situs atau aplikasi CST (selanjutnya disebut sebagai &ldquo;Syarat dan Ketentuan&rdquo;) adalah pernjanjian antara Anda (&ldquo;user&rdquo;&nbsp;dan &ldquo;pengguna&rdquo;) dan CST(&ldquo;CST&rdquo; atau &ldquo;Kami&rdquo;) yang mengatur tentang bagaimana Anda menggunakan produk, jasa, teknologi, dan layanan yang disediakan oleh CST termasuk namun tidak terbatas pada layanan ketika pengguna mendaftarkan akun melalui website https://transid.id (untuk selanjutnya disebut sebagai &ldquo;Situs CST&rdquo;). Dengan mendaftar sebagai pengguna, pengguna telah menyatakan bahwa pengguna telah MEMBACA, MEMAHAMI, MENERIMA, dan MENYETUJUI seluruh persyaratan dan ketentuan yang terdapat dalam Syarat dan Ketentuan ini dan Kebijakan Privasi sebagai berikut:</p>
                                    <p><br /></p>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>DEFINISI</p>
                                        </li>
                                    </ul>
                                    <p>Sepanjang konteks kalimat tidak menentukan lain, istilah atau definisi dalam syarat ketentuan memiliki arti sebagai berikut:</p>
                                    <ol>
                                        <li>
                                            <p>Akun CST&nbsp;adalah akun atau akses yang diberikan kepada pengguna untuk melaksanakan aktivitas penggunaan situs CST;</p>
                                        </li>
                                        <li>
                                            <p>Aset CST&nbsp;adalah komoditas digital, menggunakan kriptografi, jaringan informasi teknologi, dan buku besar yang terdistribusi, untuk mengatur penciptaan unit baru, memverifikasi transaksi, dan mengamankan transaksi tanpa campur tangan pihak lain, yang diperdagangkan didalam situ CST;</p>
                                        </li>
                                        <li>
                                            <p>USDT&nbsp;adalah mata uang secara khusus dirancang untuk membangun perantara antara mata uang tradisional dan&nbsp;cryptocurrency</p>
                                        </li>
                                        <li>
                                            <p>Dana&nbsp;adalah sejumlah uang yang disetor oleh Pengguna dalam mata uang Rupiah melalui pemindahbukuan antar bank untuk keperluan Aktivitas Perdagangan Aset Kripto melalui exchanger;</p>
                                        </li>
                                        <li>
                                            <p>Blockchain&nbsp;adalah merupakan sistem pencatatan transaksi atau&nbsp;database&nbsp;yang tersebar luas di jaringan&nbsp;peer-to-peer&nbsp;online;</p>
                                        </li>
                                        <li>
                                            <p>PIN&nbsp;merupakan nomor identifikasi pribadi untuk mengotentikasi kembali pengguna CST atau untuk mengkonfirmasi aktivitas yang dilakukan oleh pengguna dalam aplikasi CST;</p>
                                        </li>
                                        <li>
                                            <p>OTP&nbsp;adalah kata sandi yang dapat digunakan satu kali saja untuk memverifikasi pengguna situs CST;</p>
                                        </li>
                                        <li>
                                            <p>Kata Sandi&nbsp;adalah enkripsi yang dapat melindungi akun CST dengan kombinasi alfanumerik yang terdiri dari huruf besar, dan huruf kecil, angka, dan simbol;</p>
                                        </li>
                                        <li>
                                            <p>Wallet&nbsp;adalah media yang dipergunakan untuk penyimpanan dana atau aset kripto baik berupa koin atau token;</p>
                                        </li>
                                        <li>
                                            <p>Penempatan Dana&nbsp;adalah proses penyimpanan dana atau aset kripto oleh pengguna yang merujuk pada proses atau mekanisme penambahan (top-up) yang dilakukan melalui akun CST;</p>
                                        </li>
                                        <li>
                                            <p>Kebijakan Privasi&nbsp;adalah ketentuan yang mengatur bagaimana CST mengumpulkan, menggunakan, memelihara, dan mengungkapkan informasi yang dikumpulkan dari pengguna melalui situs CST;</p>
                                        </li>
                                        <li>
                                            <p>Layanan&nbsp;adalah layanan yang disediakan oleh CST termasuk namun tidak terbatas pada aktivitas perdagangan aset CST;</p>
                                        </li>
                                        <li>
                                            <p>Pengguna atau User&nbsp;adalah orang (perorangan) pemilik akun CST yang telah terdaftar pada situs CST, sehingga memperoleh otoritas dari situ CST untuk melakukan aktivitas perdagangan aset kripto.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>PROSES PENDAFTARAN</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Persyaratan umum. Untuk menggunakan layanan, anda harus tedaftar sebagai pengguna dengan syarat-syarat sebagai berikut:</p>
                                        </li>
                                    </ol>
                                    <ul>
                                        <li>
                                            <p>Telah berusia 18 (delapan belas) tahun;</p>
                                        </li>
                                        <li>
                                            <p>Memiliki Kartu Tanda Penduduk (KTP) bagi warga negara Indonesia, atau&nbsp;passport&nbsp;yang diterbitkan oleh negara asal pengguna dan/atau Kartu Izin Tinggal Sementara (KITAP) atau Kartu Izin Tinggal Terbatas (KITAS) bagi warga negara asing;</p>
                                        </li>
                                        <li>
                                            <p>Menggunakan dana atau aset kripto milik sendiri dan bukan dana atau aset kripto yang bersumber atau milik orang lain, atau hasil tindak pidana, pencucian uang, pendanaan terorisme dan/atau senjata pemusnah massal;</p>
                                        </li>
                                        <li>
                                            <p>Melakukan pendaftaran melalui situs CST;</p>
                                        </li>
                                        <li>
                                            <p>Menyetujui dan sepakat untuk mematuhi Syarat dan Ketentuan serta kebijakan privasi.</p>
                                        </li>
                                    </ul>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>Proses Pendaftaran, dalam pelaksanaan pendaftaran akun CST, pengguna wajib untuk memberikan informasi yang mencakup termasuk namun tidak terbatas:</p>
                                        </li>
                                    </ol>
                                    <ul>
                                        <li>
                                            <p>Nama;</p>
                                        </li>
                                        <li>
                                            <p>Alamat;</p>
                                        </li>
                                        <li>
                                            <p>Nomor Telepon;</p>
                                        </li>
                                        <li>
                                            <p>E-mail;</p>
                                        </li>
                                        <li>
                                            <p>Tempat dan Tanggal Lahir;</p>
                                        </li>
                                        <li>
                                            <p>Pekerjaan;</p>
                                        </li>
                                        <li>
                                            <p>NPWP (optional);</p>
                                        </li>
                                        <li>
                                            <p>Nomor KTP atau identitas lain (KITAP, KITAS dan lain-lain hal ini merupakan optional);dan</p>
                                        </li>
                                        <li>
                                            <p>Info mengenai rekening Bank pengguna (optional).</p>
                                        </li>
                                    </ul>
                                    <p><br /></p>
                                    <ol start="3">
                                        <li>
                                            <p>CST berhak untuk meminta informasi atau hal-hal lainnya selain yang disebutkan pada poin 2 yang diperlukan sehubungan dengan persyaratan pendaftaran yang ditentukan. Calon Pengguna dengan ini menyatakan dan menjamin bahwa setiap data/penjelasan/dokumen/informasi/pernyataan yang diberikan berkaitan dengan proses pendaftaran sebagai Pengguna adalah lengkap, asli, benar dan sesuai dengan keadaan sebenarnya dan merupakan data/penjelasan/dokumen/informasi/pernyataan terbaru yang belum diubah dan masih berlaku/belum kadaluarsa dan tidak ada perubahan atau kondisi lain yang telah disepakati berdasarkan ketentuan pada halaman pendaftaran Situs CST.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="4">
                                        <li>
                                            <p>Pengguna dengan ini setuju bahwa akses atas Akun CST efektif berlaku setelah semua persyaratan dipenuhi oleh Pengguna dan proses pendaftaran telah melalui proses verifikasi untuk disetujui oleh CST. Segala risiko yang timbul sehubungan dengan penutupan/pemblokiran/pembekuan Akun CST yang disebabkan oleh kesalahan dan/atau kelalaian Pengguna, akan menjadi tanggung jawab Pengguna dan CST tidak akan memberikan ganti rugi kepada Pengguna atau pihak lain dalam bentuk apapun atas segala tuntutan/gugatan dan ganti rugi dari Pengguna atau pihak manapun sehubungan dengan ditutupnya Akun CST Pengguna.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="5">
                                        <li>
                                            <p>Pengguna dengan ini setuju bahwa akses atas Akun CST efektif berlaku setelah semua persyaratan dipenuhi oleh Pengguna dan proses pendaftaran telah melalui proses verifikasi untuk disetujui oleh CST. Segala risiko yang timbul sehubungan dengan penutupan/pemblokiran/pembekuan Akun CST yang disebabkan oleh kesalahan dan/atau kelalaian Pengguna, akan menjadi tanggung jawab Pengguna dan CST tidak akan memberikan ganti rugi kepada Pengguna atau pihak lain dalam bentuk apapun atas segala tuntutan/gugatan dan ganti rugi dari Pengguna atau pihak manapun sehubungan dengan ditutupnya Akun CST Pengguna.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="6">
                                        <li>
                                            <p>Pengguna menyetujui dan memberikan wewenang kepada CST untuk menggunakan seluruh data, penjelasan dan informasi yang diperoleh CST mengenai Pengguna termasuk namun tidak terbatas pada penggunaan sarana komunikasi pribadi Pengguna untuk segala keperluan lainnya selama mungkin dan diizinkan oleh peraturan perundang-undangan yang berlaku, termasuk yang ditujukan untuk keperluan pemasaran CST atau pihak lain yang bekerjasama dengan CST. Untuk penggunaan data yang memerlukan persetujuan pihak lain, Pengguna menyatakan telah memberikan persetujuan tertulis kepada pihak ketiga manapun untuk penggunaan data, penjelasan dan informasi tersebut, dan oleh karena itu CST tidak akan memberikan ganti rugi dan/atau kewajiban dalam bentuk apapun kepada Pengguna dan pihak manapun atas segala risiko, tuntutan, gugatan dan/atau ganti rugi yang mungkin timbul di kemudian hari terkait penggunaan data, penjelasan dan informasi yang telah memperoleh persetujuan tertulis tersebut oleh CST.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="7">
                                        <li>
                                            <p>Perubahan kata sandi atau PIN, Pengguna dapat melakukan pengaturan ulang kata sandi dengan cara meminta untuk mendapatkan tautan pada email terdaftar agar dapat memasukkan kata sandi baru. Pengguna dapat melakukan pengaturan ulang PIN dengan memasukkan PIN lama yang disertai dengan memasukkan PIN baru pada aplikasi. Kami tidak dapat mengubah maupun mengatur ulang secara manual kata sandi maupun PIN pengguna demi keamanan seluruh pengguna kami.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>AKUN CST</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Setiap Akun CST yang dibuka akan diadministrasikan oleh CST berdasarkan identifikasi khusus menurut nama Pengguna yang akan berlaku juga sebagai nama/identitas Akun CST sesuai dengan yang tertera pada identitas Pengguna. Pengguna dilarang untuk menggunakan Akun CST selain akun milik Pengguna, atau mengakses Akun CST lain, atau membantu orang lain untuk mendapatkan akses tanpa izin ke dalam akun tersebut. Seluruh penggunaan Akun CST adalah tanggung jawab sepenuhnya dari pemilik Akun CST yang terdata pada Situs CST.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>Pengguna wajib menggunakan dan mencantumkan email Pengguna dan kata sandi yang telah diverifikasi oleh CST pada saat proses pendaftaran. Sistem CST akan menolak secara otomatis akses atas Akun CST, apabila tidak disertai dengan email dan kata sandi yang benar. Pengguna bertanggung jawab untuk menjaga kerahasiaan kata sandi, Akun CST, PIN, akses Wallet, akses login email dan segala jenis aktivitas yang meliputi transaksi di dalam Akun CST Pengguna. Pengguna bertanggung jawab penuh atas penggunaan kata sandi dan Akun CST. Apabila terjadi penggunaan sandi dan/atau Akun CST tanpa seizin Pengguna dan terjadi dugaan pelanggaran lain, Pengguna wajib menghubungi CSTdengan mengirimkan e-mail ke&nbsp;info@transid.id&nbsp;disertai informasi pendukung. CST tidak bertanggung jawab atas kerugian yang ditimbulkan atas adanya penyalahgunaan Akun CST dan/atau kata sandi, dengan atau tanpa sepengetahuan Pengguna.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="3">
                                        <li>
                                            <p>Pengguna setuju tidak akan menggunakan Layanan untuk melakukan tindakan kriminal dalam bentuk apapun, termasuk namun tidak terbatas pada, pencucian uang, perjudian, pembelian barang ilegal, kegiatan teroris atau kegiatan hacking. Setiap Pengguna yang melanggar peraturan tersebut dapat diberhentikan dan harus bertanggung jawab dengan kerugian yang diderita oleh CST atau para Pengguna lain di dalam Situs CST. CST berhak menutup Akun CST dan membekukan dana, Aset Kripto dan transaksi di dalamnya apabila menemukan aktivitas yang mencurigakan di dalam Akun CST tersebut hingga waktu yang tidak ditentukan.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>RUANG LINGKUP LAYANAN</p>
                                        </li>
                                    </ul>
                                    <p>Ruang lingkup layanan pada situs CST yang dapat digunakan oleh pengguna adalah sebagai berikut:</p>
                                    <ol>
                                        <li>
                                            <p>Pembelian dan penjualan aset kripto sesuai dengan daftar aset kripto yang diperdagangkan;</p>
                                        </li>
                                        <li>
                                            <p>Menerima penempatan dana dlam bentuk token CST</p>
                                        </li>
                                        <li>
                                            <p>Melakukan penarikan dana dalam bentuk token CST yang akan dikonversikan kedalam USDT kepada exchanger (penarikan dalam bentuk IDR);</p>
                                        </li>
                                        <li>
                                            <p>Melakukan pertukaran antar aset kripto terhadap sesama pengguna didalam situ CST;</p>
                                        </li>
                                        <li>
                                            <p>Melakukan pengiriman aset kripto kepada pengguna lain di dalam situs CST, maupun pengguna aset kripto di luar situs CST sesuai dengan daftar aset kripto yang diperdagangkan;</p>
                                        </li>
                                        <li>
                                            <p>Menerima deposit dalam bentuk CST sesuai dengan daftar aset kripto yang diperdagangkan.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>TEKNIS KESISTEMAN ASET KRIPTO</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Wallet Anda memungkinkan Anda untuk mengirim, menerima, dan menyimpan Aset Kripto, dengan memberikan instruksi melalui Situs CST;</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>Kami akan memproses transaksi sesuai dengan instruksi yang kami terima dari Anda. Anda harus memverifikasi semua informasi transaksi sebelum mengirimkan instruksi kepada kami. Kami tidak menjamin identitas pengguna, penerima, pemohon, atau pihak lain. Transaksi tidak dapat dibatalkan setelah disampaikan ke jaringan Aset Kripto yang relevan;</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="3">
                                        <li>
                                            <p>Setelah diajukan ke jaringan Aset Kripto, Pengguna akan menunggu konfirmasi dari jaringan Aset Kripto untuk jangka waktu tertentu. Transaksi tidak lengkap ketika sedang dalam status menunggu keputusan akan ditetapkan sebagaimana mestinya, dan tidak akan dimasukkan dalam saldo Wallet Anda atau tersedia untuk melakukan transaksi;</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="4">
                                        <li>
                                            <p>Kami dapat membebankan biaya jaringan untuk memproses transaksi atas nama Anda. Kami akan menghitung biaya jaringan sesuai kebijakannya, meskipun kami akan selalu memberitahu Anda tentang biaya jaringan pada atau sebelum waktu Anda mengotorisasi transaksi. Daftar lengkap biaya CST dapat ditemukan di halaman Penetapan Harga dan Biaya;</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="5">
                                        <li>
                                            <p>Kami dapat menolak untuk memproses atau membatalkan transaksi yang tertunda seperti yang dipersyaratkan oleh hukum atau pengadilan atau otoritas lain yang mana CST tunduk pada yurisdiksi tersebut;</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="6">
                                        <li>
                                            <p>Layanan Aset Kripto kami hanya tersedia sehubungan dengan Aset Kripto yang didukung dan terdaftar pada Situs CST, dan hal ini dapat berubah dari waktu ke waktu. Dalam situasi apapun Anda tidak boleh menggunakan Wallet untuk menyimpan, mengirim, meminta, atau menerima Aset Kripto dalam bentuk apa pun yang tidak Kami dukung. Kami tidak bertanggung jawab atau berkewajiban sehubungan dengan upaya apapun untuk menggunakan Wallet Anda untuk Aset Kripto yang tidak kami dukung. Jika Anda memiliki pertanyaan tentang Aset Kripto yang saat ini kami dukung, kunjungi&nbsp;<a href="https://transid.id/">https://transid.id/</a>.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="7">
                                        <li>
                                            <p>Kami tidak memiliki atau mengendalikan protokol perangkat lunak yang mendasari atau mengatur pengoperasian Aset Kripto yang didukung pada Situs CST: umumnya, protokol yang mendasari adalah sumber terbuka dan siapapun dapat menggunakan, menyalin, memodifikasi, dan mendistribusikannya. Kami tidak bertanggung jawab atas pengoperasian protokol yang mendasarinya dan kami tidak dapat menjamin fungsionalitas, keamanan, atau ketersediaannya.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="8">
                                        <li>
                                            <p>Anda mengakui dan menerima risiko bahwa protokol perangkat lunak yang mendasari transaksi Aset Kripto apa pun yang Anda simpan di Wallet Anda dapat berubah. Secara khusus, protokol yang mendasari cenderung tunduk pada perubahan mendadak dalam aturan operasi (&quot;fork&quot;), dan fork tersebut dapat secara material mempengaruhi nilai, fungsi, dan/atau nama Aset Kripto yang Anda simpan pada Wallet Anda. Jika memungkinkan, kami dapat memberi Anda pemberitahuan atau peringatan pada fork sesuai dengan Bagian Pemberitahuan pada Syarat dan Ketentuan ini dan Anda harus membaca pemberitahuan yang Anda diterima dari Kami agar Anda dapat mempertimbangkan cara menangani fork yang akan datang. Namun, Anda bertanggung jawab untuk membuat diri Anda sadar, dan mempertimbangkan cara menghadapi, fork yang akan datang. Dalam hal fork, terdapat risiko bahwa Kami mungkin perlu menghentikan sementara operasi dalam kaitannya dengan fork itu tanpa memberikan pemberitahuan terlebih dahulu kepada Anda. Kami mungkin, dalam kebijaksanaan wajar kami, menolak untuk mendukung salah satu atau kedua cabang dari fork.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="9">
                                        <li>
                                            <p>Anda mengakui risiko yang disajikan oleh fork dan Anda menerima bahwa kami tidak memiliki tanggung jawab untuk membantu Anda memindahkan atau menjual cabang protokol bercabang yang tidak didukung.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>PENANGGUHAN PENGAKHIRAN PEMBATALAN</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Kami dapat: (a) menolak untuk menyelesaikan atau memblokir, membatalkan atau membalikkan transaksi yang telah Anda otorisasi (b) menangguhkan, membatasi, atau menghentikan akses Anda ke salah satu atau semua Layanan, dan/atau (c) menonaktifkan atau membatalkan Akun CST apabila terjadi hal-hal, termasuk namun tidak lebih, sebagai berikut:</p>
                                        </li>
                                        <li>
                                            <p>Kami, menurut pendapat wajar kami, diharuskan oleh hukum yang berlaku atau pengadilan atau otoritas lain pada yurisdiksi mana pun;</p>
                                        </li>
                                        <li>
                                            <p>Kami cukup mencurigai bahwa Anda melanggar Syarat dan Ketentuan ini;</p>
                                        </li>
                                        <li>
                                            <p>Kami cukup menduga Anda telah melanggar Kebijakan Privasi;</p>
                                        </li>
                                        <li>
                                            <p>Kami khawatir bahwa transaksi yang Anda lakukan salah atau melanggar keamanan Akun CST Anda;</p>
                                        </li>
                                        <li>
                                            <p>Kami menduga Layanan digunakan dengan cara curang atau tidak sah;</p>
                                        </li>
                                        <li>
                                            <p>Kami mencurigai adanya pencucian uang, pendanaan teroris, penipuan, atau kejahatan keuangan lainnya;</p>
                                        </li>
                                        <li>
                                            <p>Kami menduga bahwa sumber Dana yang Anda gunakan bersumber dari pencucian uang, pendanaan teroris, penipuan, atau kejahatan keuangan lainnya;</p>
                                        </li>
                                        <li>
                                            <p>Penggunaan Akun CST Anda berada pada proses litigasi, penyelidikan, atau proses hukum yang tertunda, dan/atau kami melihat risiko tinggi hukum yang tinggi berkaitan dengan penggunaan Akun Anda; atau</p>
                                        </li>
                                        <li>
                                            <p>Anda mengambil tindakan apa pun yang dapat menghindari kontrol kami seperti membuka beberapa Akun CST atau menyalahgunakan promosi yang mungkin kami tawarkan dari waktu ke waktu.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>Kami juga dapat menolak untuk menyelesaikan transaksi yang Anda setujui apabila Dana dan/atau Aset Kripto tidak mencukupi untuk dilakukannya suatu transaksi</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="3">
                                        <li>
                                            <p>Jika kami menolak untuk menyelesaikan transaksi atau menutup Akun CST Anda atau menghentikan penggunaan Layanan dengan ketentuan sebagaimana disebutkan pada bagian ini, Kami akan (kecuali tidak diperbolehkan oleh hukum) memberi Anda pemberitahuan tentang tindakan Kami dan alasan penolakan, penangguhan, atau penutupan dan apabila perlu, dengan prosedur untuk mengoreksi kesalahan faktual apa pun yang menyebabkan penolakan, penangguhan, atau penutupan. Jika kami menolak untuk menyelesaikan transaksi dan/atau menangguhkan Akun CST Anda, Kami akan mencabut penangguhan sesegera mungkin setelah alasan penolakan dan/atau penangguhan telah terselesaikan. Namun, kami tidak berkewajiban untuk memungkinkan Anda mengembalikan transaksi dengan harga yang sama atau dengan ketentuan yang sama seperti transaksi yang ditangguhkan atau dibatalkan.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="4">
                                        <li>
                                            <p>Kami dapat menangguhkan, membatasi, atau menghentikan akses Anda ke salah satu atau semua Layanan dan/atau menonaktifkan atau membatalkan Akun CST Anda, tanpa alasan dengan memberi Anda pemberitahuan 1 (satu) bulan sebelum tanggal efektif penutupan atau pembatalan. Anda mengakui bahwa keputusan Kami untuk mengambil tindakan tertentu, termasuk membatasi akses, menangguhkan, atau menutup Akun CST Anda, dapat didasarkan pada kriteria rahasia yang penting bagi manajemen risiko dan protokol keamanan Kami. Anda setuju bahwa kami tidak berkewajiban untuk mengungkapkan rincian manajemen risiko dan prosedur keamanannya kepada Anda.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="5">
                                        <li>
                                            <p>Jika kami menangguhkan atau menutup Akun Anda, atau menghentikan penggunaan Layanan untuk alasan apapun, Kami berhak meminta Anda untuk menyelesaikan prosedur yang diuraikan di bagian Pendaftaran Akun CST sebelum mengizinkan Anda melakukan transfer atau menarik Aset Kripto atau Dana.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="6">
                                        <li>
                                            <p>Anda dapat menutup Akun CST Anda kapan saja dengan menarik semua saldo dengan mengirimkan email ke&nbsp;info@transid.id. Anda tidak akan dikenakan biaya untuk menutup Akun CST Anda, meskipun Anda akan diminta untuk membayar jumlah terutang kepada Kami (apabila ada). Anda mengizinkan Kami untuk membatalkan atau menangguhkan transaksi yang tertunda pada saat pembatalan.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="7">
                                        <li>
                                            <p>Apabila terjadi pemberhentian Akun, Pengguna setuju dan menyatakan bersedia untuk tetap terikat dengan Syarat dan Ketentuan dan menghentikan penggunaan layanan CST, memberikan hak kepada CST untuk menghapus semua informasi dan data dalam server CST, dan menyatakan CST tidak bertanggung jawab kepada Pengguna atau pihak ketiga atas penghentian akses dan penghapusan informasi serta data dalam Akun CST.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>TANGGAPAN, PERTANYAAN, PENGADUAN, DAN PENYELESAIAN PERMASALAHAN</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Jika Anda memiliki tanggapan, pertanyaan, pengaduan atau keluhan yang ingin disampaikan, hubungi Kami melalui halaman website dukungan pelanggan Kami di https://transid.id. Ketika Anda menghubungi kami, berikan nama, user id/alamat email yang terdaftar, dan informasi lain yang kami perlukan untuk mengidentifikasi Anda, Akun CST Anda, dan transaksi tempat Anda menerima masukan, pertanyaan, atau keluhan.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>Apabila Anda ingin menyampaikan keluhan, diharapkan Anda menyatakan penyebab keluhan tersebut, bagaimana Anda ingin Kami menyelesaikan keluhan dan informasi lain yang Anda yakini relevan. Kami akan mengakui penerimaan keluhan Anda jika Anda menghubungi kami melalui halaman web dukungan pelanggan Kami. Petugas Keluhan Pelanggan (&quot;Petugas&quot;) akan mempertimbangkan keluhan Anda. Petugas akan mempertimbangkan keluhan Anda tanpa prasangka berdasarkan informasi yang Anda berikan dan informasi apapun yang disediakan oleh CST. Dalam waktu 15 (lima belas) hari kerja sejak diterimanya keluhan Anda, Petugas akan membahas semua poin yang diangkat dalam keluhan Anda dengan mengirimkan e-mail (&quot;Pemberitahuan Resolusi&quot;) di mana Petugas akan: (i) menawarkan untuk menyelesaikan keluhan Anda dengan cara yang Anda minta; (ii) membuat tekad menolak keluhan Anda dan menjelaskan alasan penolakan; atau (iii) menawarkan untuk menyelesaikan keluhan Anda dengan solusi alternatif. Dalam keadaan luar biasa, jika Petugas tidak dapat menanggapi keluhan Anda dalam waktu 15 (lima belas) hari kerja karena alasan di luar kendali CST, Petugas akan mengirimkan balasan yang menunjukkan alasan penundaan dalam menjawab keluhan Anda dan menetapkan batas waktu yang digunakan oleh Petugas. akan menanggapi keluhan Anda (yang tidak lebih dari 35 (tiga puluh lima) hari kerja sejak kami menerima keluhan Anda.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>PERNYATAAN DAN JAMINAN</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Semua layanan dalam Situs CST tidak memberikan jaminan ataupun garansi apapun dan CST tidak menjamin bahwa Situs CST akan selalu dapat diakses setiap waktu dikarenakan adanya gangguan atau pemeliharaan sistem dan jaringan secara berkala.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>Pengguna menyatakan dan menjamin akan menggunakan Layanan dengan baik dan penuh tanggung jawab serta tidak melakukan tindakan yang berlawanan dengan hukum, undang-undang serta peraturan yang berlaku di wilayah Republik Indonesia.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="3">
                                        <li>
                                            <p>Pengguna menyatakan dan menjamin tidak akan menggunakan Layanan dalam perdagangan Aset Kripto yang berhubungan dengan: (i) narkotika, bahan dan/atau zat kimia untuk penelitian; (ii) uang dan apapun yang sejenis dengan uang, termasuk derivatif; (iii) barang dan/atau jasa yang melanggar Hak Kekayaan Intelektual; (iv) amunisi, senjata api, bahan peledak, senjata tajam sesuai dengan Ketentuan Hukum yang berlaku; (v) barang dan/atau jasa yang menunjukkan informasi pribadi dari pihak ketiga yang melanggar hukum; (vi) barang dan/atau jasa yang berhubungan dengan pembelian lotre, lay-away; (vii) jasa yang terkait dengan perbankan yang berada di luar wilayah Republik Indonesia; (viii) Pembayaran kartu; (ix) Penyelesaian kredit dan/atau; (x) dukungan terhadap organisasi terlarang atau dilarang oleh pemerintah.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="4">
                                        <li>
                                            <p>Pengguna menyatakan dan menjamin untuk tidak menggunakan Layanan terhadap perbuatan yang terlibat dalam praktek perjudian dan/atau kegiatan lain yang mengenakan biaya masuk dan menjanjikan hadiah, termasuk namun tidak terbatas pada permainan kasino, perjudian dalam olahraga, usaha yang memfasilitasi perjudian dan cara undian.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="5">
                                        <li>
                                            <p>Pengguna menyatakan dan menjamin bahwa seluruh data, informasi dan dokumen yang diberikan Pengguna kepada CST merupakan data, informasi dan dokumen yang sebenar-benarnya, sah, jujur, transparan, lengkap dan akurat. Pengguna menyatakan bersedia dituntut secara pidana maupun digugat secara perdata apabila CST mengetahui atau memperoleh informasi dari pihak manapun bahwa data, informasi dan dokumen yang diberikan Pengguna ternyata tidak benar/tidak sepenuhnya benar/palsu. Pengguna bersedia untuk melakukan pembaharuan data/informasi (profile update) apabila sewaktu-waktu diminta oleh CST dan selanjutnya seluruh dokumen yang sudah diberikan menjadi sepenuhnya milik CST.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="6">
                                        <li>
                                            <p>CST telah memberikan keterangan dan penjelasan yang cukup mengenai layanan CST yang akan dipergunakan Pengguna sesuai dengan ketentuan pada Syarat dan Ketentuan dan Pengguna telah mengerti dan memahami serta bersedia menanggung segala konsekuensi yang mungkin timbul sehubungan dengan penggunaan layanan CST termasuk manfaat, risiko dan biaya-biaya yang melekat layanan dan layanan.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="7">
                                        <li>
                                            <p>Pengguna dengan ini menyetujui dan memberi kuasa kepada CST untuk menggunakan semua data, keterangan dan informasi yang diperoleh CST mengenai Pengguna termasuk namun tidak terbatas pada penggunaan sarana komunikasi pribadi Pengguna untuk segala keperluan lainnya sepanjang dimungkinkan dan diperkenankan oleh perundang-undangan yang berlaku, termasuk yang bertujuan untuk pemasaran produk-produk CST ataupun pihak lain, yang bekerjasama dengan CST. Untuk penggunaan data yang memerlukan persetujuan pihak lain, dengan ini Pengguna menyatakan bahwa telah memperoleh persetujuan tertulis dari pihak ketiga manapun untuk penggunaan data, keterangan dan informasi tersebut, dan karena itu Pengguna menjamin dan menyetujui bahwa CST tidak akan memberikan ganti rugi dan/atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak manapun atas segala risiko, kerugian, tuntutan dan/atau tanggungjawab yang mungkin timbul dikemudian hari sehubungan dengan penggunaan data, keterangan, dan informasi yang telah memperoleh persetujuan tertulis tersebut oleh CST</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="8">
                                        <li>
                                            <p>Pengguna menyatakan dan menjamin bahwa transaksi yang dilakukan dengan menggunakan Layanan adalah transaksi yang tidak melanggar ketentuan peraturan perundang-undangan yang berlaku dan ketentuan mengenai penerimaan pelaksanaan transaksi sebagaimana diatur dalam Syarat dan Ketentuan. Dalam hal CST menemukan indikasi pelaksanaan transaksi yang tidak sesuai dengan ketentuan peraturan perundang-undangan yang berlaku dan atau ketentuan mengenai penerimaan pelaksanaan transaksi yang diatur dalam Syarat dan Ketentuan, maka CST mempunyai hak penuh untuk menutup Akun CST Pengguna dengan menonaktifkan Layanan, dan Pengguna dengan ini setuju bahwa CST dengan ini tidak akan memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Pengguna, atau pihak manapun atas segala klaim dan atau tuntutan yang timbul sehubungan dengan penonaktifan Layanan pada Pengguna</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="9">
                                        <li>
                                            <p>Pengguna menyatakan dan menjamin bahwa risiko terhadap penggunaan layanan, produk dan promosi pihak ketiga dengan Pengguna (apabila ada), ditanggung oleh Pengguna, dan Pengguna menyatakan bahwa CST tidak bertanggung jawab atas layanan dan kinerja layanan pihak ketiga.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="10">
                                        <li>
                                            <p>Pengguna dengan ini bertanggung jawab sepenuhnya dan setuju bahwa CST tidak akan memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak manapun atas segala kerugian dan atau klaim dan atau tuntutan yang timbul atau mungkin dialami oleh CST sebagai akibat dari kelalaian atau kegagalan Pengguna dalam menjalankan transaksi.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="11">
                                        <li>
                                            <p>Pengguna dengan ini bertanggung jawab sepenuhnya dan setuju bahwa CST tidak akan memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak manapun atas segala kerugian dan atau klaim dan atau tuntutan yang timbul atau mungkin dialami oleh CST sebagai akibat dari kelalaian atau kegagalan Pengguna dalam hal keamanan password atau kredensial dari Akun CST Pengguna.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="12">
                                        <li>
                                            <p>Pengguna dengan ini memberikan jaminan kepada CST bahwa Pengguna beserta dengan seluruh karyawannya dan atau pihak lain yang bekerja sama dengan Pengguna tidak akan memperbanyak dan atau membuat, memberikan, menyewakan, menjual, memindahkan, mengalihkan, dan atau mengalih-fungsikan Layanan baik sebagian atau seluruhnya kepada pihak lain dengan alasan apapun, termasuk namun tidak terbatas pada penyalahgunaan Situs CST untuk melakukan transaksi selain dari yang telah ditentukan dalam Syarat dan Ketentuan dengan maksud apapun, termasuk namun tidak terbatas untuk kejahatan/penipuan/kecurangan. Apabila Pengguna melanggar ketentuan tersebut, maka Pengguna wajib bertanggung jawab atas segala kerugian, tuntutan dan atau gugatan yang timbul akibat dari pelanggaran tersebut dan dengan ini setuju bahwa CST tidak akan memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak manapun atas segala klaim dan atau tuntutan dan atau gugatan yang timbul akibat pelanggaran tersebut.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>TANGGUNG JAWAB</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Pengguna setuju untuk menanggung setiap risiko, kerugian atau akibat yang diderita Pengguna yang disebabkan oleh, antara lain:</p>
                                        </li>
                                    </ol>
                                    <ul>
                                        <li>
                                            <p>kerusakan, keterlambatan, kehilangan atau kesalahan pengiriman perintah dan komunikasi, secara elektronik yang disebabkan oleh Pengguna;</p>
                                        </li>
                                        <li>
                                            <p>Laporan Akun CST atau pemberitahuan penggunaan layanan CST yang dikirim kepada Pengguna diterima atau dibaca atau disalahgunakan oleh pihak yang tidak berwenang atas Akun CST,</p>
                                        </li>
                                        <li>
                                            <p>Password Akun CST diketahui oleh orang/pihak lain atas kesalahan Pengguna.</p>
                                        </li>
                                    </ul>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>Pengguna memahami dan setuju bahwa Pengguna akan menggunakan Akun CST dan Layanan untuk transaksi yang tidak bertentangan dengan ketentuan peraturan perundang-undangan dan/atau kebijakan internal CST yang berlaku dan/atau peraturan-peraturan lainnya yang berlaku secara nasional maupun internasional yang terkait dengan pelaksanaan transaksi tersebut baik secara langsung maupun tidak langsung, dan CST tidak akan memberikan ganti rugi dan/atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak manapun atas segala klaim dan/atau tuntutan dan/atau kerugian yang timbul sehubungan dengan penggunaan Layanan oleh Pengguna untuk transaksi yang dikategorikan sebagai transaksi yang mencurigakan dan/atau transaksi yang dilarang oleh ketentuan peraturan perundang-undangan dan/atau kebijakan internal CST yang berlaku dan/atau peraturan-peraturan lainnya yang berlaku baik secara nasional maupun secara internasional yang terkait dengan kegiatan transaksi yang dilakukan oleh Pengguna secara langsung maupun tidak langsung.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="3">
                                        <li>
                                            <p>Dalam melakukan transaksi menggunakan Situs CST, Pengguna mengerti dan menyetujui bahwa terdapat sanksi-sanksi tertentu yang dikenakan oleh pemerintah, termasuk pemerintah Negara lain, dan/atau instansi berwenang lainnya terhadap beberapa negara, badan dan perorangan. Mengacu pada hal tersebut, CST berhak untuk tidak melaksanakan/memproses transaksi yang merupakan pelanggaran terhadap ketentuan sanksi tersebut, dan instansi berwenang dapat mensyaratkan pengungkapan informasi terkait. CST tidak bertanggung jawab apabila CST atau pihak lain gagal atau menunda pelaksanaan transaksi, atau adanya pengungkapan informasi sebagai akibat pelanggaran langsung maupun tidak langsung atas ketentuan sanksi tersebut.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>RISIKO</p>
                                        </li>
                                    </ul>
                                    <p>Tanpa mengurangi maksud dari ketentuan mengenai larangan-larangan yang terdapat dalam Syarat dan Ketentuan, maka Pengguna dilarang untuk melakukan hal-hal sebagai berikut:</p>
                                    <ol>
                                        <li>
                                            <p>Pengguna tidak diperbolehkan melakukan tindakan-tindakan yang dapat mengakibatkan kerugian bagi CST dan atau yang bertentangan dengan Syarat dan Ketentuan dan peraturan perundang-undangan yang berlaku.</p>
                                        </li>
                                        <li>
                                            <p>Apabila Pengguna melanggar salah satu atau beberapa ketentuan dalam ketentuan ini, maka Pengguna akan bertanggung jawab sepenuhnya dan dengan ini setuju bahwa atas pelanggaran tersebut CST berhak untuk membatasi fitur penggunaan layanan CST hingga penutupan Akun CST dan Pengguna mengetahui dan setuju bahwa CST tidak akan memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak manapun atas segala risiko atau akibat yang timbul atas pembatasan penggunaan layanan dan penutupan Akun CST tersebut. Pengguna selanjutnya berkewajiban untuk membayar ganti rugi kepada CST sebesar nilai kerugian yang mungkin dialami CST atas pelanggaran tersebut. Pengguna dengan ini memberikan kuasa kepada CST untuk melakukan pendebetan Akun Pengguna untuk pembayaran ganti rugi tersebut. Dalam hal Dana pada Akun Pengguna tidak tersedia dan atau tidak mencukupi, maka Pengguna wajib mengembalikan seluruh Dana tersebut kepada CST dalam waktu selambat-lambatnya 7 (tujuh) hari kerja sejak CST mengajukan klaim dimaksud.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>INFORMASI RAHASIA</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Setiap informasi dan atau data yang diberikan oleh CST kepada Pengguna dan atau data yang diperoleh Pengguna sebagai pelaksanaan dari Syarat dan Ketentuan baik yang diberikan atau disampaikan secara lisan, tertulis, grafik atau yang disampaikan melalui media elektronik atau informasi dan atau data dalam bentuk lainnya selama berlangsungnya pembicaraan atau selama pekerjaan lain adalah bersifat rahasia (selanjutnya disebut sebagai &ldquo;Informasi Rahasia&rdquo;).</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>Pengguna setuju dan sepakat bahwa setiap saat akan merahasiakan informasi rahasia yang diperoleh sebagai pelaksanaan dari kerjasama kepada siapapun atau tidak akan menggunakannya untuk kepentingan Pengguna atau kepentingan pihak lainnya, tanpa terlebih dahulu memperoleh persetujuan tertulis dari pejabat yang berwenang dari CST atau pihak yang berwenang lainnya sesuai dengan ketentuan hukum yang berlaku.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="3">
                                        <li>
                                            <p>Apabila Pengguna melanggar ketentuan sebagaimana dimaksud dalam angka 1 dan 2 ketentuan mengenai kerahasiaan ini, maka segala kerugian, tuntutan dan atau gugatan yang dialami CST merupakan tanggung jawab Pengguna sepenuhnya, dan atas permintaan pertama dari CST, Pengguna berkewajiban untuk menyelesaikannya sesuai dengan ketentuan hukum dan perundang-undangan yang berlaku dan Memberikan ganti rugi yang mungkin timbul akibat pelanggaran tersebut kepada CST. Pengguna dengan ini setuju bahwa CSTtidak akan memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak manapun atas segala tuntutan dan atau gugatan yang mungkin timbul dikemudian hari sehubungan dengan pelanggaran tersebut.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="4">
                                        <li>
                                            <p>Kewajiban untuk menyimpan informasi rahasia sebagaimana dimaksud dalam angka 1 dan 2 ketentuan mengenai kerahasiaan menjadi tidak berlaku, apabila:</p>
                                        </li>
                                    </ol>
                                    <ul>
                                        <li>
                                            <p>Informasi rahasia tersebut menjadi tersedia untuk masyarakat umum</p>
                                        </li>
                                        <li>
                                            <p>Informasi rahasia tersebut diperintahkan untuk dibuka untuk memenuhi perintah pengadilan atau badan pemerintahan lain yang berwenang.</p>
                                        </li>
                                        <li>
                                            <p>Informasi rahasia tersebut diberikan sesuai ketentuan hukum yang berlaku.</p>
                                        </li>
                                    </ul>
                                    <p><br /></p>
                                    <ol start="5">
                                        <li>
                                            <p>Pengguna selanjutnya menyetujui untuk melakukan segenap upaya dan mengambil setiap tindakan yang diperlukan untuk menghindari pihak-pihak ketiga dalam memperoleh akses terhadap atau mengakibatkan terjadinya pengungkapan atas informasi rahasia.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="6">
                                        <li>
                                            <p>Dalam hal kerjasama telah berakhir, Pengguna sepakat bahwa kewajiban untuk merahasiakan dokumen dan materi yang merupakan informasi rahasia sebagaimana diatur dalam ketentuan ini akan tetap berlaku.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>KELALAIAN</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Pengguna sepakat bahwa yang dimaksud dengan Kelalaian (&ldquo;Wanprestasi&rdquo;) adalah hal atau peristiwa sebagai berikut:</p>
                                        </li>
                                    </ol>
                                    <ul>
                                        <li>
                                            <p>Kelalaian;</p>
                                        </li>
                                        <li>
                                            <p>Apabila Pengguna lalai dalam melaksanakan sesuatu kewajiban atau melanggar suatu ketentuan dalam Syarat dan Ketentuan;</p>
                                        </li>
                                        <li>
                                            <p>Pernyataan Tidak Benar;</p>
                                        </li>
                                        <li>
                                            <p>Bilamana ternyata bahwa suatu pernyataan atau jaminan yang diberikan oleh Pengguna dalam Syarat dan Ketentuan &ndash; tidak benar atau tidak sesuai dengan kenyataannya.</p>
                                        </li>
                                    </ul>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>Dalam hal terjadi suatu kejadian kelalaian berdasarkan angka 1 ketentuan kelalaian ini oleh Pengguna, maka CST dapat memilih apakah tetap meneruskan atau menutup Akun Pengguna. Apabila CST berkehendak untuk menutup Akun Pengguna, maka kehendak tersebut harus diberitahukan kepada Pengguna dalam waktu yang wajar menurut CST.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="3">
                                        <li>
                                            <p>Dalam hal terjadi kejadian kelalaian oleh Pengguna sebagaimana dimaksud, maka CST berhak dengan seketika melakukan penonaktifan Akun CST tanpa harus melakukan pemberitahuan terlebih dahulu kepada Pengguna, dan Pengguna dengan ini setuju bahwa CST tidak akan Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak manapun atas segala tuntutan dan atau gugatan dan atau klaim dan atau permintaan ganti kerugian dari pihak manapun yang mungkin timbul sehubungan dengan terjadinya kelalaian tersebut.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>PAJAK</p>
                                        </li>
                                    </ul>
                                    <p>Bahwa pajak atas aktivitas perdagangan Aset Kripto adalah pajak yang ditanggung oleh masing-masing pelaku kegiatan, dalam hal ini Pengguna dan CST. Pengguna dapat mengkonsultasikan pembayaran pajak kepada Konsultan Pajak Pribadi, dan CST tidak menanggung pajak Pengguna kecuali ditentukan lain dalam Syarat dan Ketentuan ini.</p>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>KEAMANAN</p>
                                        </li>
                                    </ul>
                                    <p>CST telah menerapkan jaringan dan tindakan keamanan sebagai jaringan pengaman informasi terhadap akses yang tidak sah dalam penggunaan, perubahan dan/atau pengungkapan Akun, dengan standar keamanan yang telah sesuai dengan Ketentuan Peraturan Perundang-undangan yang berlaku. Masuknya pihak tidak bertanggung jawab terhadap akses dalam penggunaan, perubahan dan/atau pengungkapan Akun dari pihak ketiga akibat kelalaian dan/atau kesalahan Pengguna berakibat ditanggungnya risiko oleh Pengguna, maka CST tidak akan memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak lain manapun atas segala risiko, tanggungjawab dan tuntutan apapun yang mungkin timbul sehubungan dengan adanya kelalaian/kesengajaan/kesalahan Pengguna dalam memberikan informasi.</p>
                                    <p><br /></p>
                                    <p><br /></p>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>KEADAAN KAHAR (FORCE MAJEUR)</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Yang dimaksud dengan Force Majeure adalah kejadian-kejadian yang terjadi di luar kemampuan dan kekuasaan CST sehingga mempengaruhi pelaksanaan transaksi antara lain namun tidak terbatas pada:</p>
                                        </li>
                                    </ol>
                                    <ul>
                                        <li>
                                            <p>Gempa bumi, angin topan, banjir, tanah longsor, gunung meletus dan bencana alam lainnya;</p>
                                        </li>
                                        <li>
                                            <p>Perang, demonstrasi, huru-hara, terorisme, sabotase, embargo, dan pemogokan massal;</p>
                                        </li>
                                        <li>
                                            <p>Kebijakan ekonomi dari Pemerintah yang mempengaruhi secara langsung.</p>
                                        </li>
                                    </ul>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>Sepanjang CST telah melaksanakan segala kewajibannya sesuai dengan peraturan perundang-undangan yang berlaku sehubungan dengan terjadinya Force Majeure tersebut, maka CST tidak akan memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun kepada Pengguna atau pihak lain manapun atas segala risiko, tanggungjawab dan tuntutan apapun yang mungkin timbul sehubungan dengan keterlambatan maupun tidak dapat dilaksanakannya kewajiban akibat Force Majeure.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>BATASAN TANGGUNG JAWAB</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>CST selalu berusaha untuk menjaga Layanan yang aman, nyaman, dan berfungsi dengan baik, tetapi kami tidak dapat menjamin pengoperasian yang berkelanjutan atau bahwa akses ke Layanan kami selalu sempurna. Ada kemungkinan informasi dan data di situs CST tidak tersedia secara real time.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>CST tidak menjamin bahwa hasil yang diperoleh dari penggunaan layanan akan akurat atau dapat diandalkan.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="3">
                                        <li>
                                            <p>Pengguna setuju bahwa Pengguna menggunakan Layanan CST atas risiko Pengguna sendiri, dan bahwa Layanan CSTdisediakan untuk Pengguna atas dasar &ldquo;SEBAGAIMANA ADANYA&rdquo; dan &ldquo;SEBAGAIMANA TERSEDIA&rdquo;.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="4">
                                        <li>
                                            <p>Sejauh diizinkan oleh hukum yang berlaku, CST (termasuk Perusahaan Induk, direktur, dan karyawan) tidak bertanggung jawab, dan Pengguna setuju untuk tidak meminta pertanggungjawaban CST, atas kerusakan atau kerugian (termasuk tetapi tidak terbatas pada kehilangan uang, reputasi, keuntungan, atau kerugian tidak berwujud lainnya) yang secara langsung atau tidak langsung disebabkan oleh hal-hal berikut:</p>
                                        </li>
                                    </ol>
                                    <ul>
                                        <li>
                                            <p>Ketidakmampuan Pengguna dalam menggunakan layanan CST termasuk dalam risiko pribadi;</p>
                                        </li>
                                        <li>
                                            <p>Hilangnya Penggunaan, Hilangnya Keuntungan, Hilangnya Pendapatan, Hilangnya Data, Hilangnya Niat Baik, atau Kegagalan untuk merealisasikan simpanan yang diharapkan, untuk kasus apapun secara langsung atau tidak langsung;</p>
                                        </li>
                                        <li>
                                            <p>Segala kerugian yang disebabkan oleh kelalaian Pengguna termasuk namun tidak terbatas pada kelalaian login melalui perangkat pihak ketiga dan / atau kegagalan untuk menjaga kerahasiaan perangkat yang digunakan untuk login;</p>
                                        </li>
                                        <li>
                                            <p>Kondisi dan kualitas produk atau Aset Kripto yang dapat diperdagangkan;</p>
                                        </li>
                                        <li>
                                            <p>Pelanggaran Hak Kekayaan Intelektual;</p>
                                        </li>
                                        <li>
                                            <p>Perselisihan antara Pengguna;</p>
                                        </li>
                                        <li>
                                            <p>Pencemaran nama baik pihak lain;</p>
                                        </li>
                                        <li>
                                            <p>Setiap penyalahgunaan Aset yang dibeli oleh Pengguna;</p>
                                        </li>
                                        <li>
                                            <p>Virus atau malware lainnya (bot, script, alat otomatisasi) yang diperoleh dengan mengakses atau menghubungkan ke Layanan;</p>
                                        </li>
                                        <li>
                                            <p>Proses skimming atau hacking, yang menyebabkan kerugian Pengguna di Layanan CST;</p>
                                        </li>
                                        <li>
                                            <p>Gangguan, bug, kesalahan, atau ketidakakuratan dalam Layanan;</p>
                                        </li>
                                        <li>
                                            <p>Kerusakan perangkat keras Anda dari penggunaan Layanan apa pun;</p>
                                        </li>
                                        <li>
                                            <p>Tindakan penegakan yang diambil sehubungan dengan akun Pengguna;</p>
                                        </li>
                                        <li>
                                            <p>Setiap peretasan yang dilakukan oleh pihak ketiga pada akun Pengguna;</p>
                                        </li>
                                    </ul>
                                    <p><br /></p>
                                    <ol start="5">
                                        <li>
                                            <p>Pengguna mengakui dan menyetujui bahwa satu-satunya hak Pengguna sehubungan dengan masalah atau ketidakpuasan layanan adalah berhenti menggunakan Layanan.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>PEMBERITAHUAN</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Pengguna menyatakan setuju untuk berkomunikasi dengan CST dalam format elektronik, dan menyetujui bahwa semua syarat, kondisi, perjanjian, pemberitahuan, pengungkapan atau segala bentuk komunikasi lainnya yang disediakan oleh CST kepada Pengguna secara elektronik dianggap sebagai tertulis.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ol start="2">
                                        <li>
                                            <p>Pengguna menyatakan setuju untuk menerima email dari Situs CST. E-mail yang dikirim dapat berisi informasi seputar Akun, transaksi, sistem, promosi dan lain sebagainya.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <p>Saya menyatakan bahwa saya telah menerima dan membaca &ldquo;SYARAT DAN KETENTUAN&rdquo; PERJANJIAN PENGGUNA mengerti dan menyetujui isinya.</p> */}

                                    <p><strong>TERMS AND CONDITION</strong></p>
                                    <p><strong>&nbsp;</strong></p>
                                    <p>The Terms and Conditions for using the CST site or application (hereinafter referred to as the &ldquo;<strong>Terms and Conditions</strong>&rdquo;) are an agreement between you (&ldquo;<strong>user</strong>&rdquo; or &ldquo;<strong>you</strong>&rdquo;) and CST (&ldquo;<strong>CST</strong>&rdquo; or &ldquo;<strong>We</strong>&rdquo;) that governs how you use the product, services, technology and services provided by CST including but not limited to services when users register an account through the website https://csttoken.net (hereinafter referred to as the "CST Site"). By registering as a user, the user has stated that the user has READ, UNDERSTAND, ACCEPT, and AGREE to all terms and conditions contained in these Terms and Conditions and the Privacy Policy as follows:</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>Definition</strong></li>
                                    </ul>
                                    <p>As long as the context of the sentence does not specify otherwise, the terms or definitions in the terms and conditions have the following meanings:</p>
                                    <ol>
                                        <li>CST Account is an account or access given to users to carry out activities using the CST site;</li>
                                        <li>CST assets are digital commodities, using cryptography, information technology networks, and distributed ledgers, to manage the creation of new units, verify transactions, and secure transactions without the interference of other parties, which are traded on CST sites;</li>
                                        <li>USDT is a currency specifically designed to establish an intermediary between traditional currencies and cryptocurrencies;</li>
                                        <li>Funds are the amount of money deposited by Users in Rupiah through inter-bank transfers for the purposes of Crypto Asset Trading Activities through exchangers;</li>
                                        <li>Blockchain is a transaction recording system or database that is widespread in online peer-to-peer networks;</li>
                                        <li>OTP is a password that can be used only once to verify CST site users;</li>
                                        <li>Password is an encryption that can protect CST accounts with an alphanumeric combination consisting of uppercase and lowercase letters, numbers and symbols;</li>
                                        <li>A wallet is a medium used for storing funds or crypto assets in the form of coins or tokens;</li>
                                        <li>Placement of Funds is the process of storing funds or crypto assets by users which refers to the top-up process or mechanism carried out through a CST account;</li>
                                        <li>Privacy Policy is a provision governing how CST collects, uses, maintains and discloses information collected from users through the CST site;</li>
                                        <li>Services are services provided by CST including but not limited to CST asset trading activities;</li>
                                        <li>User or User is a person (individual) who owns a CST account who has registered on the CST site, thus obtaining authority from CST there to carry out crypto asset trading activities.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>Notification of Risk Documents</strong>
                                            <ol>
                                                <li>Crypto asset trading is a high risk activity;</li>
                                                <li>Crypto asset trading carries additional risks that are not experienced by other commodities in a market that is formed on the basis of technology and consensus;</li>
                                                <li>Be wary of saying that you will make huge profits trading crypto assets;</li>
                                                <li>You must read carefully and understand including but not limited to the Terms or other information before making a Crypto Asset transaction;</li>
                                                <li>CST does not guarantee the long-term viability of Crypto Assets traded or exchanged on the CST Site/Platform;</li>
                                                <li>This brief statement cannot contain in detail all the risks or other important aspects of Crypto Asset Trading. Therefore you must study Crypto Asset Trading activities carefully before deciding to make a transaction;</li>
                                                <li>This Risk Disclosure document is made in Indonesian.</li>
                                            </ol>
                                        </li>
                                    </ul>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>Registration Process</strong>
                                            <ol>
                                                <li>General requirements. To use the service, you must be registered as a user with the following conditions:
                                                    <ol>
                                                        <li>18 (eighteen) years old;</li>
                                                        <li>Have an identity card (KTP) for Indonesian citizens, or a passport issued by the user's country of origin and/or a Temporary Stay Permit Card (KITAP) or a Limited Stay Permit Card (KITAS) for foreign nationals;</li>
                                                        <li>Use your own crypto funds or assets and not crypto funds or assets that originate or belong to other people, or are the proceeds of crime, money laundering, terrorism financing and/or weapons of mass destruction;</li>
                                                        <li>Register via the CST website;</li>
                                                        <li>Agree and agree to comply with the Terms and Conditions and privacy policy.</li>
                                                    </ol>
                                                </li>
                                                <li>Registration Process, in carrying out CST account registration, users are required to provide information that includes but is not limited to:</li>
                                            </ol>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>Name;</li>
                                        <li>Address;</li>
                                        <li>Telephone Number;</li>
                                        <li>E-mail;</li>
                                        <li>Place and Date of Birth;</li>
                                        <li>Occupation;</li>
                                        <li>NPWP (optional);</li>
                                        <li>KTP number or other identification (KITAP, KITAS and others, this is optional).
                                            <ol start="3">
                                                <li>CST has the right to request information or other matters other than those mentioned in point 2 which are necessary in connection with the specified registration requirements. Prospective User hereby declares and guarantees that every data/explanation/document/information/statement provided in connection with the registration process as a User is complete, original, correct and in accordance with the actual situation and is the latest data/explanation/document/information/statement has not been changed and is still valid/has not expired and there are no changes or other conditions that have been agreed upon based on the provisions on the CST Site registration page.</li>
                                                <li>The User hereby agrees that access to the CST Account is effective after all requirements have been met by the User and the registration process has gone through a verification process to be approved by CST. All risks arising in connection with closing/blocking/freezing a CST Account caused by User error and/or negligence, will be the responsibility of the User and CST will not provide compensation to the User or other parties in any form whatsoever for all claims/claims and compensation. losses from the User or any party in connection with the closure of the User's CST Account.</li>
                                                <li>The User hereby agrees that access to the CST Account is effective after all requirements have been met by the User and the registration process has gone through a verification process to be approved by CST. All risks arising in connection with closing/blocking/freezing a CST Account caused by User error and/or negligence, will be the responsibility of the User and CST will not provide compensation to the User or other parties in any form whatsoever for all claims/claims and compensation. losses from the User or any party in connection with the closure of the User's CST Account.</li>
                                                <li>The user agrees and authorizes CST to use all data, explanations and information obtained by CST regarding the User including but not limited to the use of the User's personal communication means for all other purposes as long as possible and permitted by applicable laws and regulations, including those intended for marketing purposes CST or other parties who cooperate with CST. For the use of data that requires the approval of other parties, the User declares that he has given written consent to any third party for the use of such data, explanations and information, and therefore CST will not provide compensation and/or liability in any form to the User and any party. for all risks, claims, lawsuits and/or compensation that may arise in the future related to the use of data, explanations and information that has obtained written approval by CST.</li>
                                                <li>Password change, Users can reset their password by asking to get a link in the registered email so they can enter a new password. We cannot manually change or reset user passwords for the safety of all our users.</li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>CST Account</strong></li>
                                    </ul>
                                    <ol>
                                        <li>Each CST Account that is opened will be administered by CST based on special identification according to the User's name which will also apply as the name/identity of the CST Account according to what is stated on the User's identity. Users are prohibited from using a CST Account other than the User's account, or accessing other CST Accounts, or helping others to gain unauthorized access to that account. All use of the CST Account is the full responsibility of the owner of the CST Account recorded on the CST Site.</li>
                                        <li>The user must use and include the User's email and password that has been verified by CST during the registration process. The CST system will automatically refuse access to the CST Account, if it is not accompanied by the correct email and password. The User is responsible for maintaining the confidentiality of the password, CST Account, Wallet access, email login access and all types of activities that include transactions in the User's CST Account. The user is fully responsible for the use of passwords and CST Accounts. If there is use of a CST password and/or account without the user's permission and other suspected violations occur, the user must contact CST by sending an e-mail to info@transid.id accompanied by supporting information. CST is not responsible for any losses incurred due to misuse of the CST Account and/or password, with or without the User's knowledge.</li>
                                        <li>Users agree not to use the Service to commit criminal acts in any form, including but not limited to money laundering, gambling, purchasing illegal goods, terrorist activities or hacking activities. Any User who violates these rules can be terminated and must be responsible for losses suffered by CST or other Users on the CST Site. CST has the right to close the CST Account and freeze funds, Crypto Assets and transactions therein if it finds suspicious activity in the CST Account for an unspecified time.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>SCOPE OF SERVICES</strong></li>
                                    </ul>
                                    <p>The scope of services on the CST site that can be used by users are as follows:</p>
                                    <ol>
                                        <li>Purchase (Top-Up) and transfer of crypto assets according to a predetermined list of crypto assets;</li>
                                        <li>Accept placement of funds in the form of CST tokens;</li>
                                        <li>Withdraw funds in the form of CST tokens which will be converted into USDT to the exchanger (withdrawals in the form of IDR);</li>
                                        <li>Exchanging crypto assets between fellow users on the CST site;</li>
                                        <li>Sending crypto assets to other users on the CST site, as well as users of crypto assets outside the CST site according to the list of crypto assets provided;</li>
                                        <li>Receive a deposit in the form of CST according to the list of crypto assets provided.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>CRYPTO ASSET SYSTEMATIZATION TECHNICAL</strong></li>
                                    </ul>
                                    <ol>
                                        <li>Your Wallet allows you to send, receive, and store Crypto Assets, by providing instructions through the CST Wallet;</li>
                                        <li>We will process the transaction according to the instructions we receive from you. You must verify all transaction information before sending us instructions. We do not guarantee the identity of users, recipients, applicants or other parties. Transactions cannot be reversed once submitted to the relevant Crypto Asset network;</li>
                                        <li>After being submitted to the Crypto Asset network, the User will wait for confirmation from the Crypto Asset network for a certain period of time. Incomplete transactions while in pending status will be rendered accordingly, and will not be included in your Wallet balance or made available for transaction;</li>
                                        <li>We may charge network fees to process transactions on your behalf. We will calculate the network fee at our sole discretion, although we will always notify you of the network fee at or before the time you authorize the transaction;</li>
                                        <li>We may refuse to process or cancel pending transactions as required by law or any other court or authority to which CST is subject to such jurisdiction.</li>
                                        <li>Our Crypto Asset Services are only available in respect of Crypto Assets supported and listed on the CST Site, and this may change from time to time. Under no circumstances may you use Wallet to store, send, request, or receive Crypto Assets in any form that We do not support. We are not responsible or liable in connection with any attempt to use your Wallet for Crypto Assets that are not endorsed by us. If you have questions about the Crypto Assets we currently support, please visit <a href="https://csttoken.net">https://csttoken.net</a> .</li>
                                        <li>We do not own or control the underlying software protocols or govern the operation of the Crypto Assets supported on the CST Site: generally, the underlying protocols are open source and anyone can use, copy, modify and distribute them. We are not responsible for the operation of the underlying protocols and we cannot guarantee their functionality, security or availability.</li>
                                        <li>You acknowledge and accept the risk that the protocol of the software underlying the transaction of any Crypto Asset that you store in your Wallet may change. In particular, the underlying protocols are likely to be subject to sudden changes in operating rules ("forks"), and such forks could materially affect the value, functionality and/or name of the Crypto Assets you store in your Wallet. Where possible, we may provide you with notices or warnings on forks in accordance with the Notices Section of these Terms and Conditions and you should read the notices you receive from us so that you can consider how to handle future forks. However, it is your responsibility to make yourself aware of, and consider how to deal with, the upcoming fork. In the case of a fork, there is a risk that We may need to temporarily suspend operations in relation to the fork without giving you prior notice. We may, in our reasonable discretion, decline to support either or both branches of a fork.</li>
                                        <li>You acknowledge the risks presented by a fork and you accept that we have no responsibility to assist you in moving or selling an unsupported fork of a forked protocol</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>SUSPENSION OF CANCELLATION TERMINATION</strong></li>
                                    </ul>
                                    <ol>
                                        <li>We may:
                                            <ol>
                                                <li>refuse to complete or block, cancel or reverse transactions that you have authorized.</li>
                                                <li>suspend, limit or terminate your access to any or all of the Services, and/or</li>
                                                <li>deactivate or cancel the Account CST if things happen, including but not more, as follows:
                                                    <ol>
                                                        <li>In our reasonable opinion, required by applicable law or any court or other authority in any jurisdiction;</li>
                                                        <li>We reasonably suspect that you are in breach of these Terms and Conditions;</li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <ul>
                                        <li>We reasonably suspect you have violated the Privacy Policy;</li>
                                    </ul>
                                    <ol>
                                        <li>We are concerned that the transaction you made was wrong or violated the security of your CST Account;</li>
                                        <li>We suspect the Service is being used in a fraudulent or unauthorized manner;</li>
                                        <li>We suspect money laundering, terrorist financing, fraud or other financial crimes;</li>
                                    </ol>
                                    <ul>
                                        <li>Your use of your CST Account is in the process of litigation, investigation, or pending legal proceedings, and/or we perceive a high legal risk related to your use of your Account; or</li>
                                        <li>You take any action that may circumvent our control such as opening multiple CST Accounts or abusing promotions we may offer from time to time.</li>
                                    </ul>
                                    <ol start="2">
                                        <li>We can also refuse to complete a transaction that you agree with if the Funds and/or Crypto Assets are insufficient to carry out a transaction.</li>
                                        <li>If we refuse to complete the transaction or close your CST Account or stop using the Service with the conditions stated in this section, We will (except as prohibited by law) give You notice of Our actions and the reasons for the rejection, suspension or termination and if necessary, with procedures to correct any factual errors that led to the rejection, suspension or closure. If we refuse to complete the transaction and/or suspend your CST Account, we will lift the suspension as soon as possible after the reasons for the rejection and/or suspension have been resolved. However, we are under no obligation to enable you to reverse a transaction at the same price or on the same terms as a suspended or canceled transaction.</li>
                                        <li>We may suspend, limit or terminate your access to any or all of the services and/or disable or cancel your CST Account, without reason by giving you 1 (one) month's notice prior to the effective date of closure or cancellation. You acknowledge that Our decision to take certain actions, including limiting access to, suspending, or closing Your CST Account, may be based on confidential criteria that are important to our risk management and security protocols. You agree that we are under no obligation to disclose details of our risk management and security procedures to you.</li>
                                        <li>If we suspend or close your Account, or stop using the Service for any reason, We reserve the right to require You to complete the procedures outlined in the CST Account Registration section before allowing You to transfer or withdraw Crypto Assets or funds.</li>
                                        <li>You can close your CST Account at any time by withdrawing all balances by sending an email to info@transid.id. You will not be charged a fee to close your CST Account, although you will be required to pay the amount owed to us (if any). You allow us to cancel or suspend pending transactions at the time of cancellation.</li>
                                        <li>In the event of an Account termination, the User agrees and states that he is willing to remain bound by the Terms and Conditions and stop using CST services, grants CST the right to delete all information and data on the CST server, and states that CST is not responsible to Users or third parties for termination of access and deletion of information and data in the CST Account.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>RESPONSE, QUESTIONS, COMPLAINTS, AND PROBLEM SETTLEMENT</strong></li>
                                    </ul>
                                    <ol>
                                        <li>If you have comments, questions, complaints or complaints that you want to submit, contact us via our customer support website page at https://csttoken.net. When you contact us, provide your name, registered username/email address, and other information we need to identify you, your CST Account, and the transaction in which you received your feedback, question, or complaint.</li>
                                        <li>If you want to submit a complaint, you are expected to state the cause of the complaint, how you want us to resolve the complaint and other information that you believe is relevant. We will acknowledge receipt of your complaint if you contact us through our customer support web page. The Customer Complaints Officer ("Officer") will consider your complaint. Officers will consider your complaint without prejudice based on the information you provide and any information provided by CST. Within 15 (fifteen) working days of receipt of your complaint, the Officer will discuss all the points raised in your complaint by sending an e-mail ("Resolution Notification") in which the Officer will: (i) offer to resolve your complaint by what you ask; (ii) make a determination to reject your complaint and explain the reasons for the refusal; or (iii) offer to resolve your complaint with an alternative solution. In exceptional circumstances, if the Officer is unable to respond to your complaint within 15 (fifteen) working days for reasons beyond CST's control, the Officer will send a reply indicating the reason for the delay in responding to your complaint and setting the time limit used by the Officer. will respond to your complaint (which is not later than 35 (thirty five) working days from the time we receive your complaint.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>REPRESENTATIONS AND WARRANTIES</strong></li>
                                    </ul>
                                    <ol>
                                        <li>All services on the CST Site do not provide any guarantees or guarantees and CST does not guarantee that the CST Site will always be accessible at any time due to periodic system and network interruptions or maintenance.</li>
                                        <li>The user declares and guarantees that he will use the Service properly and responsibly and does not take actions that are contrary to the laws, laws and regulations that apply in the territory of the Republic of Indonesia.</li>
                                        <li>The user declares and guarantees that he will not use the Service in trading Crypto Assets related to: (i) narcotics, materials and/or chemicals for research; (ii) money and anything similar to money, including derivatives; (iii) goods and/or services that violate Intellectual Property Rights; (iv) ammunition, firearms, explosives, sharp weapons in accordance with the applicable legal provisions; (v) goods and/or services that reveal personal information from unlawful third parties; (vi) goods and/or services related to lottery purchases, lay-away; (vii) services related to banking outside the territory of the Republic of Indonesia; (viii) Card payments; (ix) Credit settlement and/or; (x) support for banned or banned organizations by the government.</li>
                                        <li>Users declare and guarantee not to use the Service for actions that are involved in gambling practices and/or other activities that charge an entry fee and promise prizes, including but not limited to casino games, gambling in sports, businesses that facilitate gambling and lottery methods.</li>
                                        <li>The User represents and guarantees that all data, information and documents provided by the User to CST are true, valid, honest, transparent, complete and accurate data, information and documents. The user states that he is willing to be prosecuted criminally or civilly sued if CST knows or obtains information from any party that the data, information and documents provided by the User are incorrect/not completely true/false. The user is willing to update data/information (profile update) at any time requested by CST and then all documents that have been provided become fully owned by CST.</li>
                                        <li>CST has provided sufficient information and explanation regarding CST services that will be used by Users in accordance with the provisions of the Terms and Conditions and Users have understood and understood and are willing to bear all the consequences that may arise in connection with the use of CST services including benefits, risks and costs costs attached to services and services.</li>
                                        <li>The User hereby agrees and authorizes CST to use all data, information and information obtained by CST regarding the User including but not limited to the use of the User's personal communication means for all other purposes as long as it is possible and permitted by applicable laws, including which aims to market CST products or other parties, who cooperate with CST. For the use of data that requires the approval of other parties, the User hereby declares that he has obtained written approval from any third party for the use of such data, information and information, and therefore the User guarantees and agrees that CST will not provide compensation and/or liability in any form to the User or any party for all risks, losses, demands and/or responsibilities that may arise in the future in connection with the use of data, information and information that has obtained written approval by CST.</li>
                                        <li>The user declares and guarantees that transactions carried out using the Service are transactions that do not violate the provisions of the applicable laws and regulations and the provisions regarding acceptance of the implementation of transactions as stipulated in the Terms and Conditions. In the event that CST finds indications of carrying out transactions that are not in accordance with the provisions of the applicable laws and regulations and or provisions regarding acceptance of the implementation of transactions regulated in the Terms and Conditions, then CST has the full right to close the User's CST Account by deactivating the Service, and the User hereby agrees that CST hereby will not provide compensation and or liability in any form to the User, or any party for any claims and or demands arising in connection with the deactivation of the Service to the User.</li>
                                        <li>The User represents and guarantees that the risks to the use of third party services, products and promotions with the User (if any), are borne by the User, and the User declares that CST is not responsible for the services and performance of third party services.</li>
                                        <li>The User hereby agrees and authorizes CST to use all data, information and information obtained by CST regarding the User including but not limited to the use of the User's personal communication means for all other purposes as long as it is possible and permitted by applicable laws, including which aims to market CST products or other parties, who cooperate with CST. For the use of data that requires the approval of other parties, the User hereby declares that he has obtained written approval from any third party for the use of such data, information and information, and therefore the User guarantees and agrees that CST will not provide compensation and/or liability in any form to the User or any party for all risks, losses, demands and/or responsibilities that may arise in the future in connection with the use of data, information and information that has been obtained written approval by CST.</li>
                                        <li>Users are hereby fully responsible and agree that CST will not provide compensation and or liability in any form to Users or any party for any losses and or claims and or demands that arise or may be experienced by CST as a result of users' negligence or mistake in terms of password security or credentials from the User's CST Account.</li>
                                        <li>The User represents and guarantees that the risks to the use of third party services, products and promotions with the User (if any), are borne by the User, and the User declares that CST is not responsible for the services and performance of third party services.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>RESPONSIBILITIES</strong></li>
                                    </ul>
                                    <ol>
                                        <li>The User agrees to bear any risks, losses or consequences suffered by the User caused by, among others:
                                            <ol>
                                                <li>damage, delay, loss or error in sending orders and communications, electronically caused by the User;</li>
                                                <li>CST Account Reports or notifications on the use of CST services sent to Users are received or read or misused by parties who are not authorized to the CST Account;</li>
                                                <li>The CST Account Password is known by another person/party due to User error.</li>
                                            </ol>
                                        </li>
                                        <li>The User understands and agrees that the User will use the CST Account and Services for transactions that do not conflict with the provisions of laws and regulations and/or applicable CST internal policies and/or other regulations that apply nationally or internationally related to the implementation the transaction either directly or indirectly, and CST will not provide compensation and/or liability in any form to the User or any party for any claims and/or demands and/or losses arising in connection with the use of the Service by the User for transactions involving categorized as suspicious transactions and/or transactions that are prohibited by applicable laws and/or CST internal policies and/or other regulations that apply both nationally and internationally related to transaction activities carried out by Users. directly or indirectly.</li>
                                        <li>In conducting transactions using the CST Site, Users understand and agree that there are certain sanctions imposed by the government, including governments of other countries, and/or other authorized agencies against several countries, entities and individuals. Referring to this, CST has the right not to carry out/process transactions that are a violation of the provisions of these sanctions, and the competent authority may require the disclosure of related information. CST is not responsible if CST or other parties fail or delay the implementation of transactions, or there is disclosure of information as a result of direct or indirect violation of the provisions of these sanctions.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>RISK</strong></li>
                                    </ul>
                                    <p>Without prejudice to the provisions regarding the prohibitions contained in the Terms and Conditions, Users are prohibited from doing the following:</p>
                                    <ol>
                                        <li>Users are not allowed to take actions that can cause harm to CST and or which are contrary to the Terms and Conditions and applicable laws and regulations.</li>
                                        <li>If the User violates one or more of the provisions in these terms, the User will be fully responsible and hereby agrees that for this violation CST has the right to limit the features of using CST services until the CST Account is closed and the User acknowledges and agrees that CST will not provide compensation and or liability in any form to the User or any party for all risks or consequences arising from the restriction on the use of services and closing the CST Account. The user is then obliged to pay compensation to CST in the amount of losses that CST may experience for the violation. User hereby authorizes CST to debit User Account for payment of such compensation in the form of CST tokens. In the event that the Funds in the User's Account are not available and/or insufficient, the User must return all of the Funds to CST no later than 7 (seven) working days after CST submits the said claim.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>NON DISCLOSURE</strong></li>
                                    </ul>
                                    <ol>
                                        <li>Any information and or data provided by CST to the User and or data obtained by the User as the implementation of the Terms and Conditions whether given or delivered orally, in writing, graphically or delivered via electronic media or information and or data in other forms during the conversation or during other confidential work (hereinafter referred to as "Confidential Information");</li>
                                        <li>Users agree and agree that at any time they will keep confidential information obtained as a result of collaboration with anyone or will not use it for the benefit of the User or the interests of other parties, without first obtaining written approval from an authorized official from CST or other authorized party in accordance with applicable legal provisions.</li>
                                        <li>If the User violates the provisions referred to in numbers 1 and 2 of this provision regarding confidentiality, then all losses, claims and or lawsuits experienced by CST are the full responsibility of the User, and at the first request from CST, the User is obliged to resolve them in accordance with legal provisions and applicable laws and provide compensation that may arise as a result of the violation to CST. The User hereby agrees that CST will not provide compensation and or liability in any form to the User or any party for any claims and or claims that may arise in the future in connection with the violation.</li>
                                        <li>The obligation to store confidential information as referred to in numbers 1 and 2 regarding confidentiality shall not apply if:
                                            <ol>
                                                <li>Such confidential information becomes available to the general public;</li>
                                                <li>Confidential information ordered to be disclosed to comply with a court order or other authorized government agency;</li>
                                                <li>Confidential information is provided in accordance with applicable legal provisions.</li>
                                            </ol>
                                        </li>
                                        <li>The user then agrees to make every effort and take every necessary action to prevent third parties from gaining access to or resulting in the disclosure of confidential information.</li>
                                        <li>In the event that the collaboration has ended, the User agrees that the obligation to keep documents and materials that constitute confidential information as stipulated in this provision will remain in force.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>NEGLIGENCE</strong></li>
                                    </ul>
                                    <ol>
                                        <li>The user agrees that what is meant by Negligence (&ldquo;Default&rdquo;) is the following matters or events:
                                            <ol>
                                                <li>Negligence;</li>
                                                <li>If the User is negligent in carrying out an obligation or violates a provision in the Terms and Conditions;</li>
                                                <li>Incorrect Statement;</li>
                                                <li>If it turns out that a statement or guarantee given by the User in the Terms and Conditions - is incorrect or not in accordance with reality.</li>
                                            </ol>
                                        </li>
                                        <li>In the case of an incident of negligence based on point 1 of this negligence provision by the User, CST can choose whether to continue or close the User's Account. If CST wishes to close a User's Account, such intention must be notified to the User within a reasonable time according to CST.</li>
                                        <li>In the case of negligence by the User as intended, then CST has the right to immediately deactivate the CST Account without having to make prior notification to the User, and the User hereby agrees that CST will not provide compensation and or liability in any form to the User. or any party for all claims and or lawsuits and or claims and or requests for compensation from any party that may arise in connection with the occurrence of such negligence.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>TAX</strong></li>
                                    </ul>
                                    <p>Whereas the tax on trading activities of Crypto Assets is the tax that is borne by each activity actor, in this case the User and CST. Users can consult tax payments with Personal Tax Consultants, and CST does not bear User taxes unless otherwise specified in these Terms and Conditions.</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>SECURITY</strong></li>
                                    </ul>
                                    <p>CST has implemented a network and security measures as an information security network against unauthorized access in using, changing and/or disclosing accounts, with security standards that are in accordance with the provisions of the applicable laws and regulations. The entry of the party is not responsible for access to the use, change and/or disclosure of accounts from third parties due to negligence and/or user error resulting in the user's risk being borne, so CST will not provide compensation and or liability in any form to the user or other parties any risks, responsibilities and claims that may arise in connection with the negligence/intentional/error of the User in providing information.</p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>FORCE MAJEUR</strong></li>
                                    </ul>
                                    <ol>
                                        <li>What is meant by Force Majeure are events that occur beyond the ability and power of CST so that they affect the implementation of transactions, including but not limited to:
                                            <ol>
                                                <li>Earthquakes, hurricanes, floods, landslides, volcanic eruptions and other natural disasters;</li>
                                                <li>Wars, demonstrations, riots, terrorism, sabotage, embargoes, and mass strikes;</li>
                                                <li>Economic policies from the Government that affect directly.</li>
                                            </ol>
                                        </li>
                                        <li>As long as CST has carried out all its obligations in accordance with the applicable laws and regulations in connection with the occurrence of the Force Majeure, then CST will not provide compensation and or liability in any form to the User or any other party for any risks, responsibilities and claims whatsoever which may arise in connection with delays or non-performance of obligations due to Force Majeure.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>LIMITATION OF LIABILITY</strong></li>
                                    </ul>
                                    <ol>
                                        <li>CST always strives to maintain a safe, convenient and well-functioning Service, but we cannot guarantee continuous operation or that access to our Service is always flawless. There is a possibility that the information and data on the CST website may not be available in real time.</li>
                                        <li>CST does not guarantee that the results obtained from the use of the service will be accurate or reliable.</li>
                                        <li>The User agrees that the User uses the CST Service at the User's own risk, and that the CST Service is provided to the User on an "AS IS" and "AS AVAILABLE" basis.</li>
                                        <li>To the extent permitted by applicable law, CST (including the Parent Company, directors and employees) is not responsible, and the User agrees not to hold CST liable, for damages or losses (including but not limited to loss of money, reputation, profits, or other intangible losses) which are directly or indirectly caused by the following:
                                            <ol>
                                                <li>User's inability to use CST services is included in personal risk;</li>
                                                <li>Loss of Use, Loss of Profits, Loss of Revenue, Loss of Data, Loss of Goodwill, or Failure to realize expected savings, in any case directly or indirectly;</li>
                                                <li>Any losses caused by the User's negligence including but not limited to negligence in logging in via third party devices and / or failure to maintain the confidentiality of the device used for logging in;</li>
                                                <li>Condition and quality of products or tradable Crypto Assets;</li>
                                                <li>Intellectual Property Rights Violation;</li>
                                                <li>Disputes between Users;</li>
                                                <li>Defamation of other parties;</li>
                                                <li>Any misuse of Assets purchased by Users;</li>
                                                <li>Viruses or other malware (bots, scripts, automation tools) obtained by accessing or connecting to the Service;</li>
                                                <li>The process of skimming or hacking, which causes losses to the User in the CST Service;</li>
                                                <li>Disturbances, bugs, errors or inaccuracies in the Service;</li>
                                                <li>Damage to your hardware from use of any Service;</li>
                                                <li>Enforcement actions taken in relation to User accounts;</li>
                                                <li>Any hacking performed by third parties on the User's account;</li>
                                            </ol>
                                        </li>
                                        <li>The User acknowledges and agrees that the User's only right with respect to service problems or dissatisfaction is to stop using the Service.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>NOTIFICATION</strong></li>
                                    </ul>
                                    <ol>
                                        <li>User agrees to communicate with CST in electronic format, and agrees that all terms, conditions, agreements, notices, disclosures or any other forms of communication provided by CST to Users electronically are considered as written.</li>
                                        <li>The user agrees to receive emails from the CST Site. E-mails sent can contain information about accounts, transactions, systems, promotions and so on</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <p>I declare that I have received and read the "<strong>TERMS AND CONDITIONS</strong>" USER AGREEMENT understand and agree to its contents.</p>
                                    <p>&nbsp;</p>

                                </div>
                                <div className="flex items-center justify-between p-4 border-t border-gray-200 rounded-b-md">
                                    <div className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-gray-400">
                                        <input type={"checkbox"} className="checked:bg-blue-500" onClick={checkboxtncHandle} /> I had read and agreed to the <span className="text-blue-400">terms and conditions </span>
                                    </div>
                                    {checkBoxtnc && bottomTnc ? (
                                        <button type="button"
                                            className={"inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1 disabled"} onClick={openpnpmodalHandle}>
                                            Accept
                                        </button>
                                    ) :
                                        <button type="button"
                                            className={"inline-block px-6 py-2.5 bg-gray-300 text-white font-medium text-xs leading-tight uppercase rounded shadow-md focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 transition duration-150 ease-in-out ml-1 disabled"} disabled onClick={openpnpmodalHandle}>
                                            Accept
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null
            }

            {showpnpModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col max-sm:w-[260px] md:w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                    <h5 className="text-xl font-medium leading-normal text-gray-800">
                                        Privacy and Policy
                                    </h5>
                                    <button
                                        className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                    </button>
                                </div>
                                <div className="flex flex-col p-8 gap-8 items-center overflow-y-auto overflow-x-hidden max-sm:max-h-[400px] md:max-h-[500px] text-justify" onScroll={handleScrollpnp}>
                                    {/* <p>Perlindungan dan kerahasiaan informasi pribadi Anda sangat penting bagi Kami. Oleh karena itu, CST menetapkan kebijakan privasi sebagai berikut:</p>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>Komitmen&nbsp;CST</p>
                                        </li>
                                    </ul>
                                    <p>Kami menggunakan informasi pribadi Anda sesuai dengan ketentuan yang relevan dari peraturan atau regulasi perlindungan data pribadi. Kebijakan privasi ini menjelaskan mengenai pengumpulan, penggunaan, penyimpanan dan perlindungan informasi pribadi Anda. Hal ini berlaku untuk aplikasi (mobile apps), semua situs web, situs, dan layanan terkait dari CST terlepas dari bagaimana Anda mengakses atau menggunakannya.</p>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>Lingkup dan Persetujuan</p>
                                        </li>
                                    </ul>
                                    <p>Anda menerima kebijakan privasi ini ketika Anda mendaftar, mengakses, atau menggunakan produk, layanan, konten, fitur, teknologi atau fungsi kami yang ditawarkan pada aplikasi (mobile apps), semua situs web, situs, dan layanan terkait (secara kolektif disebut &ldquo;Layanan CST&rdquo;). Kami dapat mengunggah perubahan kebijakan pada halaman ini secara berkala, versi revisi akan berlaku pada tanggal efektif publikasi. Anda bertanggung jawab untuk meninjau kebijakan privasi ini sesering mungkin.</p>
                                    <p>Untuk tujuan kebijakan privasi ini, kami menggunakan istilah &ldquo;informasi pribadi&rdquo; untuk menggambarkan informasi yang dapat dikaitkan dengan individu tertentu dan dapat digunakan untuk mengidentifikasi individu tersebut. Kebijakan privasi ini tidak berlaku untuk informasi yang dibuat anonim sehingga tidak dapat mengidentifikasi pengguna tertentu.</p>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>Pengumpulan Informasi Pribadi</p>
                                        </li>
                                    </ul>
                                    <ol>
                                        <li>
                                            <p>Kami dapat mengumpulkan informasi identifikasi pribadi dari Pengguna dengan berbagai cara, mengumpulkan menjadi satu, namun tidak membatasi, saat Pengguna mengunjungi situs kami, mendaftar di situs, dan menghubungkan dengan aktivitas lain yang serupa, layanan, fitur-fitur atau sumber (resource) yang kami sediakan di Situs kami. Pengguna mungkin diminta untuk mencantumkan nama, dan alamat email yang sesuai. Pengguna mungkin mengunjungi situs kami secara anonim. Kami akan mengumpulkan informasi identifikasi pribadi dari Pengguna ketika mereka secara sukarela mengirimkan informasi tersebut kepada kami. Pengguna selalu dapat menolak untuk memberikan informasi identifikasi pribadi, kecuali bahwa hal itu dapat mencegahnya untuk terlibat dalam kegiatan terkait Situs tertentu.</p>
                                        </li>
                                        <li>
                                            <p>Kami dapat mengumpulkan informasi identifikasi non-pribadi tentang Pengguna kapan pun mereka berinteraksi dengan Situs kami. Informasi identifikasi non-pribadi mungkin termasuk nama browser, jenis komputer dan informasi teknis tentang Pengguna yang berarti terhubung ke Situs kami, seperti sistem operasi dan penyedia layanan Internet yang digunakan dan informasi serupa lainnya.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>Cookie Web Browser</p>
                                        </li>
                                    </ul>
                                    <p>Situs kami dapat menggunakan &quot;cookies&quot; untuk meningkatkan pengalaman pengguna. Browser web pengguna menempatkan cookie pada hard drive mereka untuk tujuan pencatatan dan terkadang untuk melacak informasi tentangnya. Pengguna dapat memilih untuk menyetel browser web mereka untuk menolak cookie, atau untuk memberi tahu Anda saat cookie dikirim. Jika mereka melakukannya, perhatikan bahwa beberapa bagian Situs mungkin tidak berfungsi dengan baik.</p>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>Bagaimana Kami Menggunakan Informasi yang Dikumpulkan</p>
                                        </li>
                                    </ul>
                                    <p>CST dapat mengumpulkan dan menggunakan informasi pribadi Pengguna untuk tujuan, sbb:</p>
                                    <p><br /></p>
                                    <ol>
                                        <li>
                                            <p>Untuk memproses pembayaran. Kami dapat menggunakan informasi yang Pengguna berikan tentang diri mereka sendiri saat melakukan pemesanan hanya untuk memberikan layanan sesuai pesanan tersebut. Kami tidak membagikan informasi ini dengan pihak luar kecuali sejauh yang diperlukan untuk menyediakan layanan ini;</p>
                                        </li>
                                        <li>
                                            <p>Untuk menjalankan promosi, kontes, survei atau fitur lainnya pada situs ini;</p>
                                        </li>
                                        <li>
                                            <p>Untuk mengirim informasi kepada Pengguna, mereka setuju untuk menerima tentang topik yang menurut kami menarik bagi mereka;</p>
                                        </li>
                                        <li>
                                            <p>Untuk mengirimkan email berkala;</p>
                                        </li>
                                        <li>
                                            <p>Kami mungkin menggunakan alamat email untuk mengirim informasi dan pembaruan Pengguna yang berkaitan dengan pesanan mereka. Ini juga dapat digunakan untuk menanggapi permintaan keterangan, pertanyaan, dan/atau permintaan lainnya. Jika Pengguna memutuskan untuk ikut serta ke milis kami, mereka akan menerima email yang mungkin berisi berita perusahaan, pembaruan, informasi produk atau layanan terkait, dll. Jika sewaktu-waktu Pengguna ingin berhenti berlangganan menerima email di kemudian hari, kami menyertakan rincian instruksi berhenti berlangganan di bagian bawah setiap email.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>Bagaimana Kami Melindungi Informasi Anda</p>
                                        </li>
                                    </ul>
                                    <p>Kami menerapkan praktik pengumpulan, penyimpanan dan pengolahan data yang tepat dan langkah-langkah keamanan untuk melindungi dari akses yang tidak sah, pengubahan, pengungkapan, atau penghancuran informasi pribadi, nama pengguna (username), kata sandi (password), informasi transaksi dan data yang tersimpan di Situs kami.</p>
                                    <p><br /></p>
                                    <p>Pertukaran data sensitif dan pribadi antara Situs dan Penggunanya terjadi melalui channel komunikasi SSL yang aman (secure) dan dienkripsi dan dilindungi dengan tanda tangan digital.</p>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>Membagi Informasi Anda</p>
                                        </li>
                                    </ul>
                                    <p>Kami tidak menjual, memperdagangkan, atau menyewakan informasi identifikasi pribadi Pengguna kepada orang lain. Kami dapat membagikan informasi demografis gabungan generik yang tidak terkait dengan informasi identifikasi pribadi apa pun mengenai pengunjung dan pengguna dengan mitra bisnis kami, afiliasi terpercaya dan pengiklan untuk tujuan yang diuraikan di atas.</p>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>Pengubahan Pada Kebijakan Privasi</p>
                                        </li>
                                    </ul>
                                    <p>CST memiliki kebijaksanaan untuk memperbarui kebijakan privasi ini setiap saat. Saat kami melakukannya, kami akan merevisi tanggal yang diperbarui di bagian bawah halaman ini. Kami mendorong Pengguna untuk sering memeriksa halaman ini agar setiap perubahan tetap mendapat informasi tentang bagaimana kami membantu melindungi informasi pribadi yang kami kumpulkan. Anda mengetahui dan setuju bahwa Anda bertanggung jawab untuk meninjau kembali kebijakan privasi ini secara berkala dan menyadari adanya modifikasi.</p>
                                    <p><br /></p>
                                    <ul>
                                        <li>
                                            <p>Penerimaan Anda Terhadap Persyaratan ini</p>
                                        </li>
                                    </ul>
                                    <p>Dengan menggunakan Situs ini, Anda menandakan penerimaan Anda atas kebijakan dan persyaratan layanan ini. Jika Anda tidak menyetujui kebijakan ini, mohon jangan gunakan Situs kami.</p>
                                    <ol start="2">
                                        <li>
                                            <p>Pengguna menyatakan setuju untuk menerima email dari Situs CST. E-mail yang dikirim dapat berisi informasi seputar Akun, transaksi, sistem, promosi dan lain sebagainya.</p>
                                        </li>
                                    </ol>
                                    <p><br /></p>
                                    <p>Saya menyatakan bahwa saya telah menerima dan membaca &ldquo;SYARAT DAN KETENTUAN&rdquo; PERJANJIAN PENGGUNA mengerti dan menyetujui isinya.</p> */}

                                    <p><strong>PRIVACY POLICY</strong></p>
                                    <p>&nbsp;</p>
                                    <p>Protection and confidentiality of your personal information is very important to us. Therefore, CST establishes a privacy policy as follows:</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>CST COMMITMENT</strong></li>
                                    </ul>
                                    <p>We use your personal information in accordance with the relevant provisions of the personal data protection rules or regulations. This privacy policy describes the collection, use, storage and protection of your personal information. This applies to applications (mobile apps), all websites, sites and related services from CST regardless of how you access or use them.</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>SCOPE AND AGREEMENT</strong></li>
                                    </ul>
                                    <p>You accept this privacy policy when you register, access, or use our products, services, content, features, technology or functions offered on applications (mobile apps), all related websites, sites and services (collectively, &ldquo;CST Services &rdquo;). We may periodically upload policy changes on this page, the revised version will take effect on the effective date of publication. You are responsible for reviewing this privacy policy frequently.</p>
                                    <p>For purposes of this privacy policy, we use the term &ldquo;personal information&rdquo; to describe information that can be associated with a specific individual and can be used to identify that individual. This privacy policy does not apply to information that is anonymized so that it cannot identify a particular user.</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>COLLECTION OF PERSONAL INFORMATION</strong></li>
                                    </ul>
                                    <ol>
                                        <li>We may collect personal identification information from Users in various ways, aggregate, but not limit, when Users visit our site, register on the site, and connect with other similar activities, services, features or resources that we provide. provide on our Site. Users may be asked to provide a name, and an appropriate email address. Users may visit our site anonymously. We will collect personally identifiable information from Users when they voluntarily submit such information to us. Users can always refuse to provide personally identifiable information, except that doing so may prevent them from engaging in certain Site related activities.</li>
                                        <li>We may collect non-personal identification information about Users whenever they interact with our Site. Non-personally identifiable information may include browser name, computer type and technical information about Users who are connected to our Site, such as the operating system and Internet service provider used and other similar information.</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>COOKIE WEB BROWSER</strong></li>
                                    </ul>
                                    <p>Our site may use "cookies" to improve user experience. Users' web browsers place cookies on their hard drives for record-keeping purposes and sometimes to track information about them. Users may choose to set their web browser to refuse cookies, or to notify you when a cookie is being sent. If they do, note that some parts of the Site may not function properly.</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>HOW TO USE COLLECTIBLE INFORMATION</strong></li>
                                    </ul>
                                    <p>CST can collect and use User personal information for the following purposes:</p>
                                    <p>&nbsp;</p>
                                    <ol>
                                        <li>To process payments. We may use the information that Users provide about themselves when placing an order only to provide services according to the order. We do not share this information with outside parties except to the extent necessary to provide this service;</li>
                                        <li>To run promotions, contests, surveys or other features on this site;</li>
                                        <li>To send information to Users, they agree to receive information about topics we think will be of interest to them;</li>
                                        <li>To send periodic emails;</li>
                                        <li>We may use email addresses to send User information and updates relating to their orders. It may also be used to respond to inquiries, questions, and/or other requests. If Users decide to opt-in to our mailing list, they will receive emails which may contain company news, updates, related product or service information, etc. If at any time the User wishes to unsubscribe from receiving emails at a later date, we include detailed unsubscribe instructions at the bottom of each</li>
                                    </ol>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>HOW WE SECURE YOUR INFORMATION</strong></li>
                                    </ul>
                                    <p>We implement appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of personal information, usernames, passwords, transaction information and data stored on our Site.</p>
                                    <p>&nbsp;</p>
                                    <p>Sensitive and personal data exchange between the Site and its Users occurs via a secure (secure) SSL communication channel and is encrypted and protected with a digital signature.</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>SHARING YOUR INFORMATION</strong></li>
                                    </ul>
                                    <p>We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information that is not linked to any personally identifiable information about visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</p>
                                    <p>&nbsp;</p>
                                    <ul>
                                        <li><strong>CHANGES TO THE PRIVACY POLICY</strong></li>
                                    </ul>
                                    <p>CST has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to check this page frequently to keep any changes informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of any modification</p>
                                    <ul>
                                        <li><strong>YOUR ACCEPTANCE OF THESE TERMS</strong></li>
                                    </ul>
                                    <p>By using this Site, you signify your acceptance of these policies and terms of service. If you do not agree to this policy, please do not use our Site.</p>
                                    <p>&nbsp;</p>

                                </div>
                                <div className="flex items-center justify-between p-4 border-t border-gray-200 rounded-b-md">
                                    <div className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-gray-400">
                                        <input type={"checkbox"} className="checked:bg-blue-500" onClick={checkboxpnpHandle} /> I had read and agreed to <span className="text-blue-400" onClick={opentncmodalHandle}>privacy policy </span>
                                    </div>
                                    <div className="inline-flex">
                                        {checkBoxpnp && bottomPnp ?
                                            <button type="button"
                                                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1" onClick={onsecondSubmit}>
                                                Accept
                                            </button>
                                            :
                                            <button type="button"
                                                className="inline-block px-6 py-2.5 bg-gray-200 text-black font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-400 hover:shadow-lg focus:bg-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
                                                Accept
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null
            }

            <div className="application overflow-x-hidden">
                <Navbar color="white" />

                <div className="grid h-screen place-items-center">
                    <div className="flex flex-col gap-4 items-center">
                        <h2 className="max-sm:mt-10 md:mt-0 text-blue-cst-font">
                            Register
                        </h2>
                        <h6 className="text-blue-cst-font">
                            Join us now!
                        </h6>
                        <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                            <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Username" aria-label="Username" onChange={(e) => setTableusername(e.target.value)} />
                        </div>
                        {errorUsername.substring(0, 9) === '888005015' ?
                            <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {errorusernameMessage}
                            </span> :
                            <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-blue-cst-font-info inline-flex gap-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#3267E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Min 5 character, Max 20 character and without space.
                            </span>}
                        <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                            <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="Full Name" aria-label="FullName" onChange={(e) => setTablefullname(e.target.value)} />
                        </div>
                        {errorFullname.substring(0, 9) === '888005020' ?
                            <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {errorfullnameMessage}
                            </span> :
                            <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-blue-cst-font-info inline-flex gap-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#3267E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Min 5 character and without number.
                            </span>}
                        <div className="max-sm:w-60 sm:w-88 md:w-96 flex items-center border-b border-blue-cst-border py-2">
                            <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="email" placeholder="Email" aria-label="Email" onChange={(e) => setTableemail(e.target.value)} />
                        </div>
                        {errorEmail.substring(0, 9) === '888005035' ?
                            <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {erroremailMessage}
                            </span> :
                            null}
                        <div className="max-sm:w-60 sm:w-88 md:w-96 inline-flex items-center border-b border-blue-cst-border py-2">
                            <select name="tabletopupcurrencycode" className="form-control max-sm:w-60 sm:w-88 md:w-96" onChange={(e) => getCodeCountry(e.target.value)}>
                                <option value="Indonesia">Indonesia</option>
                                {phoneCountry.map((item, i) => (
                                    <option key={i} value={item.tablecountryphonecodecountryname}>{item.tablecountryphonecodecountryname}</option>
                                ))}
                            </select>
                        </div>
                        {errorCountrycode.substring(0, 9) === '888005060' ?
                            <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {errorcountrycodeMessage}
                            </span> :
                            null}
                        <div className="max-sm:w-60 sm:w-88 md:w-96 inline-flex gap-2">
                            <div className="border-b border-blue-cst-border py-2 max-sm:w-24 sm:w-24 md:w-20">
                                <div className="inline-flex">
                                    <div className="inline-flex">
                                        <span>
                                            +
                                        </span>
                                        <input className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-1 leading-tight focus:outline-none" type="number" value={Tablecountrycode} aria-label="PhoneNumber" disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="border-b border-blue-cst-border py-2 max-sm:w-76 sm:w-76 md:w-80">
                                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="number" placeholder="Phone Number" aria-label="PhoneNumber" onChange={(e) => setTablephonenumbershort(e.target.value)} />
                            </div>
                        </div>
                        {errorPhonenumbershort.substring(0, 9) === '888005060' ?
                            <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-red-600 inline-flex gap-2">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {errorphonenumbershortMessage}
                            </span> :
                            null}
                        {/* {checkBox ?
                            <button className="h-12 max-sm:w-60 sm:w-88 md:w-96 text-lg text-indigo-100 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={onSubmit}>
                                Register
                            </button>
                            :
                            <button type="button" className="h-12 max-sm:w-60 sm:w-88 md:w-96 text-lg text-white bg-gray-300 rounded-lg focus:outline-none" disabled>Register</button>
                        } */}
                        <button className="h-12 max-sm:w-60 sm:w-88 md:w-96 text-lg text-indigo-100 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={onfirstSubmit}>
                            Register
                        </button>

                        {/* <div className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-gray-400">
                            <input type={"checkbox"} className="checked:bg-blue-500" onClick={checkboxHandle} /> By registering you agreed with our <span className="text-blue-400 cursor-pointer">term and condition </span>and <span className="text-blue-400 cursor-pointer" onClick={opentncmodalHandle}>privacy policy </span>
                        </div> */}
                        <div className="inline-flex gap-3 items-left">
                            Have an account?<a href="/login" className="text-blue-600">Sign in</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;