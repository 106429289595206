import { useState } from "react";
import { useNavigate } from 'react-router-dom';

import cryptoJS from "crypto-js";
import axios from "axios";

import Header from "../../components/Header";

const TopupContainer = () => {
    let heading = "Transfer";
    let description = "Our competitive fees make it affordable to transfer even small amounts of cryptocurrency."

    const [tableRecipient, setTablerecipient] = useState('');
    const [tableAmount, setTableamount] = useState(0);
    const [tableMessage, setTablemessage] = useState('');

    const [errorRecipient, setErrorrecipient] = useState('');
    const [errorrecipientMessage, setErrorrecipientmessage] = useState('');
    const [errorAmount, setErroramount] = useState('');
    const [erroramountMessage, setErroramountmessage] = useState('');
    const [errorMessage, setErrormessage] = useState('');
    const [errormessageMessage, setErrormessagemessage] = useState('');
    const [Message, setMessage] = useState('');

    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const insertnewHandle = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));
        let tokenTrans = JSON.parse(localStorage.getItem('tokentrans'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;
        StringSignature += tokenTrans;
        StringSignature += tableAmount;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/transfer/v01/insertnew',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            },
            data: {
                amount: tableAmount,
                recepient: tableRecipient,
                message: tableMessage
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                localStorage.setItem('transfer', JSON.stringify(res.data));

                navigate('/transfer/confirmation')
            } else {
                res.data.status.message.forEach(element => {
                    switch (element.code.substring(0, 9)) {
                        case '888035020':
                            setErrorrecipient(element.code);
                            setErrorrecipientmessage(element.errormassage);
                            break;
                        case '888050040':
                            setErroramount(element.code);
                            setErroramountmessage(element.errormassage);
                            break;
                        case '888050045':
                            setErrormessage(element.code);
                            setErrormessagemessage(element.errormassage);
                            break;
                        default:
                            setMessage(element.errormassage);

                            setShowModal(true);
                            break;
                    }
                });

                let inputRecipient = res.data.status.message.filter(key => key.code.substring(0, 9) === '888035020');
                let inputAmount = res.data.status.message.filter(key => key.code.substring(0, 9) === '888050040');
                let inputMessage = res.data.status.message.filter(key => key.code.substring(0, 9) === '888050045');

                if (inputRecipient.length === 0) { setErrorrecipient(''); }
                if (inputAmount.length === 0) { setErroramount(''); }
                if (inputMessage.length === 0) { setErrormessage(''); }
            }
        });
    }

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                    onClick={() => setShowModal(false)}
                                >
                                    <span className="text-gray-400 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                                <div className="flex flex-col p-24 gap-8 items-center">
                                    <img src={process.env.PUBLIC_URL + '/images/components/error/error_cat.png'} alt="error" className=""></img>
                                    <h3 className="uppercase font-bold">{Message}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

            <div className="overflow-y-hidden w-4/5 h-auto box-content p-2 pl-20 pt-20 md:p-8 md:pl-72 md:pt-24 left-16 md:left-64">
                <div className="flex flex-col w-auto gap-8">
                    <div>
                        <Header heading={heading} description={description} />
                    </div>
                    <div className="flex flex-col md:flex-row gap-16">
                        <div>
                            <label htmlFor="recipient" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Recipient</label>
                            <div className="relative mb-6">
                                <div className="flex flex-col gap-1">
                                    <input type="text" id="recipient" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="abcdef12345465743388ffefe1271929" onChange={(e) => setTablerecipient(e.target.value)} />
                                    {/* <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                        <span className="text-blue-cst-primary">CST</span>
                                    </div> */}
                                    {errorRecipient.substring(0, 9) === '888035020' ?
                                        <span className="w-80 md:w-96 text-red-600 inline-flex gap-2">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            {errorrecipientMessage}
                                        </span> :
                                        null}
                                </div>
                            </div>
                            <div className="relative mb-6">
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Amount</label>
                                    <div className="relative items-center justify-center">
                                        <input type="text" id="amount" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="200" value={tableAmount} onChange={(e) => setTableamount(e.target.value)} />
                                        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                            <span className="text-blue-cst-primary">CST</span>
                                        </div>
                                    </div>
                                    {errorAmount.substring(0, 9) === '888050040' ?
                                        <span className="w-80 md:w-96 text-red-600 inline-flex gap-2">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            {erroramountMessage}
                                        </span> :
                                        <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-blue-cst-font-info inline-flex gap-2">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#3267E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            Minimum Top-Up 1 CST
                                        </span>}
                                </div>

                            </div>
                            <div className="mb-6">
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Message</label>
                                    <input type="text" id="message" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Message" required onChange={(e) => setTablemessage(e.target.value)} />
                                </div>
                                {errorMessage.substring(0, 9) === '888050045' ?
                                    <span className="w-80 md:w-96 text-red-600 inline-flex gap-2">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#CB3A31" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        {errormessageMessage}
                                    </span> :
                                    <span className="max-sm:w-60 sm:w-88 md:w-96 text-xs text-blue-cst-font-info inline-flex gap-2">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.8333 13.3333H10V10H9.16667M10 6.66667H10.0083M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z" stroke="#3267E3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Max 32 characters (optional)
                                    </span>}
                            </div>
                            <button className="h-12 w-72 md:w-96 text-lg text-indigo-100 transition-colors duration-150 bg-blue-cst rounded-lg focus:shadow-outline hover:bg-blue-cst-hover" onClick={insertnewHandle} >
                                Transfer
                            </button>
                        </div>
                        <img src={process.env.PUBLIC_URL + '/images/components/transfer/trasnfer.png'} alt="transfer"></img>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopupContainer