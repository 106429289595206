import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import cryptoJS from "crypto-js";
import axios from "axios";

import Navbar from "../../layout/NavbarAuth";
import Sidebar from "../../layout/Sidebar";
import TopupContainer from "../../layout/container/topup/TopupContainer"

const Topup = () => {
    const [Message, setMessage] = useState('');

    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method: 'POST',
            url: process.env.REACT_APP_APIHOST + '/api/deposit/v02/insertnew',
            headers: {
                platform: 'WEBSITE',
                gtoken: 'PASSS',
                userindex: useruuid.tableuserindex,
                tokenlogin: useruuid.tokenlogin,
                signature: signature
            }
        }).then(res => {
            if (res.data.status.status === 1) {
                localStorage.setItem('tokentrans', JSON.stringify(res.data.status.tokentrans));
                localStorage.setItem('temp-deposit', JSON.stringify(res.data.data));

                navigate('/topup');
            } else {
                res.data.status.message.forEach(element => {
                    setShowModal(true);

                    setMessage(element.errormassage);
                });
            }
        })
    }, [navigate])

    const handleClosepopup = () => {
        setShowModal(false);

        navigate('/dashboard');
    }

    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <button
                                    className="p-1 ml-auto mr-4 border-0 text-gray-400 float-right text-3xl leading-none font-bold outline-none focus:outline-none"
                                    onClick={handleClosepopup}
                                >
                                    <span className="text-gray-400 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                        x
                                    </span>
                                </button>
                                <div className="flex flex-col p-24 gap-8 items-center">
                                    <img src={process.env.PUBLIC_URL + '/images/components/error/error_cat.png'} alt="error" className=""></img>
                                    <h3 className="uppercase font-bold">{Message}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
            <div className="application overflow-x-hidden">
                <Navbar color="white" />
                <Sidebar />

                <TopupContainer />
            </div>
        </>
    );
}

export default Topup;